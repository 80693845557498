import { InfiniteData } from '@tanstack/react-query';
import { DeserializedArrayResponse } from 'deserialize-json-api';
import { sumBy } from 'lodash';

export const getNextPageParam = <TData, Meta, TParams>(
  lastPage: DeserializedArrayResponse<TData, Meta>,
  params: TParams,
) => {
  if (lastPage?.links?.nextPage) {
    return {
      ...params,
      page: {
        number: lastPage.links.nextPage,
      },
    };
  }
};

export const getInfiniteData = <T, M>(
  data?: InfiniteData<DeserializedArrayResponse<T, M>>,
) => ({
  data: (data?.pages || []).map((page) => page.data).flat(1),
  meta: {
    total: (data?.pages || [])[0]?.meta?.totalItems || 0,
    totalFetched: sumBy(data?.pages, 'meta.totalItemsFetched'),
  },
});

export const getListData = <T, M>(data?: DeserializedArrayResponse<T, M>) => ({
  data: data?.data || [],
  meta: {
    total: data?.meta?.totalItems || 0,
    totalFetched: data?.meta?.totalItemsFetched || 0,
    languages: data?.meta?.languages ?? [],
  },
});
