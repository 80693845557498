import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  onHandleRoute?: () => void;
  title?: string;
  description?: string;
  subTitle?: string;
  buttonText?: string;
  searchValue?: string;
  icon: React.ReactNode;
}

const EmptyLayout = ({
  onHandleRoute,
  title,
  description,
  subTitle,
  buttonText,
  searchValue,
  icon,
}: Props) => {
  const getEmptyStateText = () => {
    if (searchValue && !!searchValue.length) {
      return {
        emptyTitle: 'search.no_results.title',
        emptyDescr: 'search.no_results.description',
      };
    }

    return {
      emptyTitle: title,
      emptySubtitle: subTitle,
      emptyDescr: description,
      emptyButtonText: buttonText,
      handleRoute: onHandleRoute,
    };
  };

  const {
    emptyTitle,
    emptySubtitle,
    emptyButtonText,
    emptyDescr,
    handleRoute,
  } = getEmptyStateText();

  return (
    <Box pt={{ xs: 6, md: 16 }}>
      {emptyTitle && (
        <Typography variant="h3">
          <FormattedMessage id={emptyTitle} />
        </Typography>
      )}
      <Stack direction="column" alignItems="center" mt={10}>
        <Avatar
          sx={(theme) => ({
            width: 120,
            height: 120,
            backgroundColor: theme.palette.pink[200],
          })}
        >
          {icon}
        </Avatar>
        <Box maxWidth={460} textAlign="center" my={6}>
          {emptySubtitle && (
            <Typography variant="h4" mb={2}>
              <FormattedMessage id={emptySubtitle} />
            </Typography>
          )}
          {emptyDescr && (
            <Typography>
              <FormattedMessage id={emptyDescr} />
            </Typography>
          )}
        </Box>
        {emptyButtonText && handleRoute && (
          <Button onClick={handleRoute} variant="contained">
            <FormattedMessage id={emptyButtonText} />
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default EmptyLayout;
