import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Lesson, LessonEvaluation } from 'types/lesson.types';

const getLessonEvaluation = async (lessonId: Lesson['id']) => {
  const { data } = await apiService.getLessonEvaluation(lessonId);

  return deserialize(data);
};

export const useLessonEvaluation = (
  lessonId: Lesson['id'],
  config?: UseCommonQueryOptions<DeserializedResponse<LessonEvaluation>>,
) => {
  const { data, isLoading } = useQuery(
    QueryKeys.lessons.evaluation(lessonId),
    () => getLessonEvaluation(lessonId),
    config,
  );

  return {
    data: data?.data,
    meta: data?.meta,
    isLoading,
  };
};
