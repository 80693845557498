import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React from 'react';

import { Icon } from '../Icon';

interface Props {
  open: boolean;
  onClose(): void;
  title?: string;
  children: React.ReactNode;
}

const Dialog: React.FC<Props> = ({ open, onClose, title, children }) => {
  return (
    <MuiDialog open={open} onClose={onClose}>
      <Stack px={8} mb={4} justifyContent="space-between">
        {title && (
          <DialogTitle maxWidth={300} mb={4} variant="h4">
            {title}
          </DialogTitle>
        )}
        <IconButton color="primary" onClick={onClose}>
          <Icon name="IcoClose" />
        </IconButton>
      </Stack>

      <DialogContent sx={{ wordBreak: 'break-word', px: 8 }}>
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
