import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification, useTrackEvent } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { intlActions, useAppDispatch } from 'store';
import { UpdateUserParams, User } from 'types/user.types';

interface Variables {
  id: User['id'];
  attributes: UpdateUserParams;
}

const updateUser = async ({ id, attributes }: Variables) => {
  const { data } = await apiService.updateUser(id, attributes);

  return data;
};

export const useUpdateUser = (params?: { admin: boolean }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const trackEvent = useTrackEvent();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (_, { id, attributes }) => {
      trackEvent({ event: 'UPDATE_PROFILE', withRole: true });

      queryClient.invalidateQueries(QueryKeys.user.byId(id));
      queryClient.invalidateQueries(QueryKeys.user.all(), {
        refetchType: 'none',
      });

      if (!params?.admin && attributes.locale) {
        dispatch(intlActions.LOCALE_UPDATE(attributes.locale));
      }

      showNotification({
        message: intl.formatMessage({ id: 'user.update.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return { updateUser: mutate, isLoading };
};
