import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Course, CoursesMeta, GetCoursesParams } from 'types/course.types';

const getCourses = async (params: GetCoursesParams) => {
  const { data } = await apiService.getCourses(params);

  return deserialize<Course, CoursesMeta>(data);
};

export const useCourses = (
  params: GetCoursesParams,
  config?: UseCommonQueryOptions<
    DeserializedArrayResponse<Course, CoursesMeta>
  >,
) => {
  const { data, isLoading, isError, isFetching } = useQuery(
    QueryKeys.courses.byUser(params),
    () => getCourses(params),
    { keepPreviousData: true, ...config },
  );

  return {
    data: data?.data ?? [],
    meta: data?.meta,
    isLoading,
    isError,
    isFetching,
  };
};
