import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import { Teacher } from 'types/teacher.types';
import { GetTimesheetParams, Timesheet } from 'types/timesheet.types';

const getTimesheetsByTeacher = async (
  teacherId: Teacher['id'],
  params: GetTimesheetParams,
) => {
  const { data } = await apiService.getTimesheetsByTeacher(teacherId, params);

  return deserialize<Timesheet>(data);
};

export const useTimesheetsByTeacher = (
  teacherId: Teacher['id'],
  params: GetTimesheetParams,
  config?: UseQueryOptions<DeserializedArrayResponse<Timesheet>>,
) => {
  const { data, isLoading, isError, isFetching } = useQuery(
    QueryKeys.timesheets.byTeacher(teacherId, params),
    () => getTimesheetsByTeacher(teacherId, params),
    { keepPreviousData: true, ...config },
  );

  return {
    ...getListData(data),
    isLoading,
    isError,
    isFetching,
  };
};
