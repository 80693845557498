import { Auth, CognitoUser } from '@aws-amplify/auth';

export const getAccessToken = async () => {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();

    if (user)
      return user.getSignInUserSession()?.getAccessToken().getJwtToken();
  } catch (e) {
    return undefined;
  }
};
