import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { StaleTime, useUser } from 'queries';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import {
  Course,
  CourseMetaData,
  GetCoursesFromUserParams,
} from 'types/course.types';

const getCoursesFromAuthenticatedUser = async (
  params: GetCoursesFromUserParams,
) => {
  const { data } = await apiService.getCoursesFromAuthenticatedUser(params);

  return deserialize<Course, CourseMetaData>(data);
};

export const useCoursesFromAuthenticatedUser = (
  params: GetCoursesFromUserParams,
  config?: UseCommonQueryOptions<
    DeserializedArrayResponse<Course, CourseMetaData>
  >,
) => {
  const { data: user } = useUser();
  const { data, isLoading, isError, isSuccess, status, isFetching } = useQuery(
    QueryKeys.courses.all(params),
    () => getCoursesFromAuthenticatedUser(params),
    {
      enabled: !!user,
      keepPreviousData: true,
      staleTime: StaleTime.FIFTEEN_MIN,
      ...config,
    },
  );

  return {
    ...getListData(data),
    isLoading,
    isSuccess,
    isError,
    status,
    isFetching,
  };
};
