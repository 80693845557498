import { Stack } from '@mui/material';
import { format } from 'date-fns';
import React, { useLayoutEffect, useRef } from 'react';

import { SelectedTimeslotByDay, Timeslot } from 'types/availabilities.types';

import { LoadingState } from 'components/@states';

import { TimeslotHeaderMobile } from '../TimeslotHeader';
import { TimeslotList } from '../TimeslotList';
import { CommonProps } from './types';

interface Props extends CommonProps {
  selectedTimeslots: SelectedTimeslotByDay;
  onSetDay(day: number | Date): void;
  onNextDay(): void;
  onPrevDay(): void;
  currentDay: string | Date;
  isAdmin?: boolean;
}

const Mobile: React.FC<Props> = ({
  type,
  onSelectTimeslot,
  selectedTimeslots,
  onSetDay,
  currentDay,
  onNextDay,
  onPrevDay,
  isLoading,
  disablePast,
  isAdmin,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollRef) {
      scrollRef.current?.scrollTo({
        top: 500, // 500 stands for height of a single timeslot * 9, which is the position of the timeslot of 09:00
        behavior: 'smooth',
      });
    }
  }, []);

  const formattedDay =
    type === 'specific'
      ? format(currentDay as Date, 'yyyy-MM-dd')
      : (currentDay as string);

  const handleSelectTimeslot = (day: string | number, timeslot: Timeslot) => {
    onSelectTimeslot(day, timeslot);
  };

  return (
    <Stack direction="column" height="calc(100% - 220px)">
      <TimeslotHeaderMobile
        type={type}
        currentDay={currentDay}
        onPrevDay={onPrevDay}
        onNextDay={onNextDay}
        onSetDay={onSetDay}
        isAdmin={isAdmin}
      />
      <Stack ref={scrollRef} direction="column" overflow="auto" pb={4} flex={1}>
        {isLoading ? (
          <LoadingState />
        ) : (
          <TimeslotList
            day={formattedDay}
            disablePast={disablePast}
            onSelectTimeslot={(timeslot) =>
              handleSelectTimeslot(formattedDay, timeslot)
            }
            selectedTimeslots={selectedTimeslots}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Mobile;
