import { Stack } from '@mui/material';
import {
  CalendarPicker as MuiCalendarPicker,
  CalendarPickerProps,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { format, isSameDay } from 'date-fns';
import React from 'react';

import { Dot } from 'components/@common';

interface Props extends Omit<CalendarPickerProps<Date>, 'onChange'> {
  highlightedDates?: Date[];
  onChange?(date: Date): void;
}

const renderWeekPickerDay = (
  date: Date,
  highlightedDates: Date[] = [],
  pickersDayProps: PickersDayProps<Date>,
) => {
  const highlighted = !!highlightedDates.find((day) => isSameDay(day, date));

  return (
    <Stack
      key={format(date, 'yyyy-MM-dd')}
      alignItems="center"
      justifyContent="center"
      width={48}
      height={36}
      margin="0 2px"
    >
      <Stack alignItems="center" width={36} height={36} position="relative">
        <PickersDay {...pickersDayProps} sx={{ margin: 0 }} />
        {highlighted && !pickersDayProps.outsideCurrentMonth && (
          <Dot bgcolor="pink.200" position="absolute" bottom={2} left={14} />
        )}
      </Stack>
    </Stack>
  );
};

const CalendarPicker: React.FC<Props> = ({
  date,
  highlightedDates,
  onChange,
  shouldDisableDate,
  ...calendarPickerProps
}) => {
  const handleChange = (date: Date | null) => {
    if (date && onChange) onChange(date);
  };
  return (
    <MuiCalendarPicker
      date={date}
      onChange={handleChange}
      renderDay={(day, _, pickersDayProps) =>
        renderWeekPickerDay(day, highlightedDates, pickersDayProps)
      }
      shouldDisableDate={shouldDisableDate}
      {...calendarPickerProps}
    />
  );
};

export default CalendarPicker;
