import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import {
  addDays,
  addWeeks,
  endOfISOWeek,
  format,
  getISOWeek,
  getYear,
  isSameDay,
  subDays,
} from 'date-fns';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { parseSpecificAvailabilities } from 'utils/availability.utils';

import {
  QueryKeys,
  useAvailabilitiesByWeek,
  useUpdateAvailabilitiesByWeek,
  useUser,
} from 'queries';
import { Timeslot } from 'types/availabilities.types';

import { Routes } from 'pages/routes.constants';

import { BottomBar, EmptyLayout, Icon } from 'components/@common';
import { UnsavedChangesDialog } from 'components/@dialog';
import { LoadingState } from 'components/@states';
import { TimeslotTableMobile } from 'components/@timeslots';

import { useAvailabilities } from '../AvailabilitiesProvider';
import { CommonProps } from './types';
import { getAvailableAmount } from './utils';

const Mobile: React.FC<CommonProps> = ({ teacherId, isAdmin }) => {
  const today = new Date();
  const { data: user } = useUser();
  const [currentDate, setCurrentDate] = useState(today);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleRoute = () => navigate(Routes.DefaultAvailabilities);

  const { data, meta, isLoading, prefetchAvailabilities, isFetching } =
    useAvailabilitiesByWeek(teacherId, {
      week: getISOWeek(currentDate),
      year: getYear(currentDate),
    });

  const { toggleTimeslot, selectedTimeslots, resetAvailabilities } =
    useAvailabilities('specific');

  const {
    updateAvailabilitiesByWeek,
    isLoading: isUpdating,
    isPaused,
  } = useUpdateAvailabilitiesByWeek();

  const handleUpdate = async () => {
    if (data) {
      updateAvailabilitiesByWeek(
        {
          id: teacherId,
          params: {
            days: parseSpecificAvailabilities(selectedTimeslots),
          },
        },
        {
          onSuccess: () => {
            setUnsavedChanges(false);
          },
        },
      );
    }
  };

  const handleSelectTimeslot = (day: string | number, timeslot: Timeslot) => {
    setUnsavedChanges(true);
    toggleTimeslot(day, timeslot.timeStart);
  };

  const handleNextDay = () => {
    const nextDay = addDays(currentDate, 1);
    const endOfWeek = endOfISOWeek(nextDay);
    const prefetchWeek = addWeeks(currentDate, 2);

    setCurrentDate(nextDay);

    if (isSameDay(nextDay, endOfWeek)) {
      prefetchAvailabilities({
        week: getISOWeek(prefetchWeek),
        year: getYear(prefetchWeek),
      });
    }
  };

  const handlePrevDay = () => {
    setCurrentDate((state) => subDays(state, 1));
  };

  const handleConfirmUnsavedChanges = () => {
    setUnsavedChanges(false);
    resetAvailabilities();
    queryClient.invalidateQueries(
      QueryKeys.availabilities.byWeek(user?.getUsername() ?? ''),
    );
    return true;
  };

  const totalSelected = getAvailableAmount(currentDate, selectedTimeslots);

  if (isFetching) return <LoadingState />;

  if (!data && !isFetching)
    return (
      <EmptyLayout
        title="navigation.link.availabilities"
        subTitle="page.availabilities.no_availabilities.title"
        description="page.availabilities.no_availabilities.description"
        buttonText="page.availabilities.no_availabilities.button.text"
        icon={<Icon name="IcoNoAvailability" width={48} height={48} />}
        onHandleRoute={handleRoute}
      />
    );

  return (
    <>
      <Stack direction="column" flex={1} height="100%" mt={6}>
        <Typography variant="h3" mb={4}>
          <FormattedMessage id="navigation.link.availabilities" />
        </Typography>
        <Typography mb={2}>
          <FormattedMessage
            id="specific_availabilities.amount_available.mobile"
            values={{
              available: totalSelected,
              planned: meta?.bookedTimeslots ?? 0,
              free: totalSelected - (meta?.bookedTimeslots ?? 0),
            }}
          />
        </Typography>
        <TimeslotTableMobile
          type="specific"
          disablePast
          isLoading={isLoading}
          selectedTimeslots={
            selectedTimeslots[format(currentDate, 'yyyy-MM-dd')]
          }
          onSelectTimeslot={handleSelectTimeslot}
          onNextDay={handleNextDay}
          onPrevDay={handlePrevDay}
          currentDay={currentDate}
          onSetDay={(day: Date) => setCurrentDate(day)}
          isAdmin={isAdmin}
        />
        <BottomBar>
          <LoadingButton
            variant="contained"
            fullWidth
            loading={isUpdating && !isPaused}
            onClick={handleUpdate}
          >
            <FormattedMessage id="label.save_changes" />
          </LoadingButton>
        </BottomBar>
      </Stack>
      <UnsavedChangesDialog
        when={unsavedChanges}
        onConfirm={handleConfirmUnsavedChanges}
      />
    </>
  );
};

export default Mobile;
