import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral[800],
      fontSize: 12,
    }),
    error: ({ theme }) => ({
      color: theme.palette.status.error[200],
    }),
  },
};
