import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Course, CoursesMeta, GetCourseParams } from 'types/course.types';

const getCourse = async (params: GetCourseParams) => {
  const { data } = await apiService.getCourse(params);

  return deserialize<Course, CoursesMeta>(data);
};

export const useCourse = (
  params: GetCourseParams,
  config?: UseCommonQueryOptions<DeserializedResponse<Course, CoursesMeta>>,
) => {
  const { data, isLoading, isError, error } = useQuery(
    QueryKeys.courses.byId(params.id),
    () => getCourse(params),
    config,
  );

  return { data: data?.data, meta: data?.meta, isLoading, isError, error };
};
