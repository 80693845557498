import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Course, CourseEvaluation } from 'types/course.types';

const getCourseEvaluation = async (courseId: Course['id']) => {
  const { data } = await apiService.getCourseEvaluation(courseId);

  return deserialize(data);
};

export const useCourseEvaluation = (
  courseId: Course['id'],
  config?: UseCommonQueryOptions<DeserializedResponse<CourseEvaluation>>,
) => {
  const { data, isLoading } = useQuery(
    QueryKeys.courses.evaluation(courseId),
    () => getCourseEvaluation(courseId),
    config,
  );

  return {
    data: data?.data,
    isLoading,
  };
};
