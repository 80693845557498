import { AppBar, Box, Container, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { RouteMatch, useNavigate } from 'react-router-dom';

import { getPrimaryRole, isTeacher } from 'utils/user.utils';

import { useCurrentPath } from 'hooks';
import { useLogout, useTeacher, useUser, useUserById } from 'queries';

import { Routes } from 'pages/routes.constants';

import { Icon, Link } from 'components/@common';
import { ProfileButton } from 'components/@profile';

import { MenusLookup, NavigationColors } from './constants';
import MobileMenu from './MobileMenu';

const getNavigationColor = (matches: RouteMatch[]) => {
  switch (true) {
    case matches.some((match) => match.route.path === Routes.CourseDetail):
      return NavigationColors.courseDetail;
    case matches.some((match) => match.route.path === Routes.CoursePlanned):
      return NavigationColors.coursePlanned;
    default:
      return NavigationColors.default;
  }
};

const Navigation = () => {
  const { data: user } = useUser();
  const userId = user?.getUsername() ?? '';

  const { data: teacher } = useTeacher(userId, {
    enabled: !!userId && isTeacher(user),
  });

  const { data: userById } = useUserById(userId, {
    enabled: !!userId && !isTeacher(user),
  });

  const { logout } = useLogout();
  const navigate = useNavigate();
  const matches = useCurrentPath();
  const navigationLinks = () => {
    const role = getPrimaryRole(user);

    if (role) return MenusLookup[role];

    return [];
  };

  const profile = isTeacher(user) ? teacher : userById;

  return (
    <AppBar position="relative" sx={getNavigationColor(matches)}>
      <Container>
        <Stack alignItems="center" justifyContent="space-between">
          <Stack alignItems="center" gap={{ xs: 4, md: 10 }}>
            <MobileMenu links={navigationLinks()} onLogout={logout} />

            <Box width={{ xs: 57, md: 86 }} height={{ xs: 32, md: 48 }}>
              <Icon
                name="LogoBlack"
                width="100%"
                height="100%"
                onClick={() => navigate(Routes.Root)}
                sx={{ cursor: 'pointer' }}
              />
            </Box>

            <Stack
              alignItems="center"
              gap={6}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              {navigationLinks().map(({ label, to }) => (
                <Link navigation key={label} to={to}>
                  <FormattedMessage id={`navigation.link.${label}`} />
                </Link>
              ))}
            </Stack>
          </Stack>
          {profile && (
            <Box display={{ xs: 'none', md: 'block' }}>
              <ProfileButton user={profile} />
            </Box>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default Navigation;
