import {
  InputBase,
  InputLabel,
  inputLabelClasses,
  styled,
} from '@mui/material';

export const Label = styled(InputLabel)(({ theme }) => ({
  transform: 'translate(14px, 0px) scale(1)',
  transition: theme.transitions.create('transform'),

  [`&.${inputLabelClasses.shrink}`]: {
    transform: 'translate(14px, 5px) scale(0.75)',
  },
}));

export const Input = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.neutral[100],
    border: `1px solid ${theme.palette.neutral[400]}`,
    fontSize: 16,
    padding: '24px 12px 8px',
    '&:focus': {
      borderColor: theme.palette.neutral[800],
      backgroundColor: theme.palette.neutral[100],
    },
  },
}));
