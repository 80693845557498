import 'services';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { isDev } from 'utils/env.utils';

import { DatepickerProvider, NotificationProvider } from 'services';
import history from 'services/history';
import IntlSelectorProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import storeConfig from 'store';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary, RouteBoundary } from 'components/@boundaries';
import { LoadingState } from 'components/@states';

const App: React.FC = () => (
  <RootBoundary>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ReduxProvider store={storeConfig.store}>
          <PersistGate
            loading={<LoadingState />}
            persistor={storeConfig.persistor}
            onBeforeLift={storeConfig.initApp}
          >
            <IntlSelectorProvider>
              <HelmetProvider>
                <RouteBoundary>
                  <HistoryRouter history={history}>
                    <DatepickerProvider>
                      <NotificationProvider>
                        <Pages />
                      </NotificationProvider>
                    </DatepickerProvider>
                  </HistoryRouter>
                </RouteBoundary>
              </HelmetProvider>
            </IntlSelectorProvider>
          </PersistGate>
        </ReduxProvider>
      </ThemeProvider>
      {isDev() && (
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      )}
    </QueryClientProvider>
  </RootBoundary>
);

export default App;
