import { Components } from '@mui/material';

import NeueHaasGroteskWoff from 'assets/fonts/Neue-Haas-Grotesk.woff';
import NeueHaasGroteskWoff2 from 'assets/fonts/Neue-Haas-Grotesk.woff2';
import NeueHaasGroteskWoffMedium from 'assets/fonts/Neue-Haas-Grotesk-Medium.woff';
import NeueHaasGroteskWoff2Medium from 'assets/fonts/Neue-Haas-Grotesk-Medium.woff2';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    html {
      font-size: 18px;
      margin-left: calc(100vw - 100%);
      margin-right: 0;
    }
    @font-face {
      font-family: 'Neue Haas Grotesk';
      font-style: normal;
      font-weight: 400;
      src: local('Neue Haas Grotesk'), url(${NeueHaasGroteskWoff}) format('woff'), url(${NeueHaasGroteskWoff2}) format('woff2');
    }
    @font-face {
      font-family: 'Neue Haas Grotesk';
      font-style: normal;
      font-weight: 500;
      src: local('Neue Haas Grotesk Medium'), url(${NeueHaasGroteskWoffMedium}) format('woff'), url(${NeueHaasGroteskWoff2Medium}) format('woff2');
    }
  `,
};
