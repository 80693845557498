import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Icon, IconNames } from 'components/@common';

interface Props {
  icon: IconNames;
  title: React.ReactNode;
  description?: React.ReactNode;
  buttonText?: string;
  onClick?(): void;
  searchValue?: string;
}

const EmptyState: React.FC<Props> = ({
  title,
  description,
  icon,
  onClick,
  buttonText,
  searchValue,
}) => {
  const intl = useIntl();
  const getEmptyStateText = () => {
    if (searchValue && !!searchValue.length) {
      return {
        emptyTitle: intl.formatMessage({ id: 'search.no_results.title' }),
        emptyDescr: intl.formatMessage(
          { id: 'search.no_results.description' },
          {
            searchValue,
          },
        ),
      };
    }

    return {
      emptyTitle: title,
      emptyDescr: description,
      emptyButtonText: buttonText,
      handleClick: onClick,
    };
  };

  const { emptyDescr, emptyTitle, emptyButtonText, handleClick } =
    getEmptyStateText();

  return (
    <Box py={{ xs: 6, md: 16 }}>
      <Stack direction="column" alignItems="center" mt={10}>
        <Avatar
          sx={(theme) => ({
            width: 120,
            height: 120,
            backgroundColor: theme.palette.pink[200],
          })}
        >
          <Icon name={icon} fontSize={48} />
        </Avatar>
        <Box maxWidth={460} textAlign="center" my={6}>
          <Typography variant="h4" mb={2}>
            {emptyTitle}
          </Typography>
          {emptyDescr && <Typography>{emptyDescr}</Typography>}
        </Box>
        {handleClick && emptyButtonText && (
          <Button onClick={handleClick} variant="contained">
            <FormattedMessage id={emptyButtonText} />
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default EmptyState;
