import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { getInitialsFromUser } from 'utils/common.utils';

import { useLogout } from 'queries';
import { Teacher } from 'types/teacher.types';
import { User } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import { Icon } from 'components/@common';

interface Props {
  user: Teacher | User;
}

const ProfileButton: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { logout } = useLogout();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  const handleLogout = async () => {
    await logout();
    handleClose();
  };

  const getAvatar = () => {
    if ('profilePicture' in user) {
      return {
        src: `https://${user.profilePicture}`,
      };
    }

    return {
      children: getInitialsFromUser(user.givenName, user.familyName),
    };
  };

  return (
    <div>
      <Button
        id="profile-button"
        size="small"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<Avatar alt="Profile picture" {...getAvatar()} />}
      >
        <FormattedMessage id="navigation.my_profile" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id="profile-menu"
        anchorOrigin={{ vertical: 70, horizontal: -125 }}
        PaperProps={{
          sx: {
            minWidth: 280,
          },
        }}
      >
        <MenuItem>
          <Button
            size="small"
            fullWidth
            disableRipple
            onClick={() => navigate(Routes.Profile)}
            startIcon={
              <Avatar variant="filled">
                <Icon name="IcoUser" />
              </Avatar>
            }
          >
            <FormattedMessage id="profile.title" />
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            size="small"
            disableRipple
            onClick={handleLogout}
            startIcon={
              <Avatar variant="filled">
                <Icon name="IcoLogout" />
              </Avatar>
            }
          >
            <FormattedMessage id="profile.menu.logout" />
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileButton;
