import React from 'react';

import { useIso } from 'hooks';

import { Autocomplete } from '../Autocomplete';

type Option = {
  label: string;
  value: string;
};

interface Props {
  value: Option;
  onChange(value: Option): void;
  label: string;
}

const LanguageSelector: React.FC<Props> = ({ value, onChange, label }) => {
  const { languages } = useIso();

  return (
    <Autocomplete
      value={value}
      options={languages}
      onChange={onChange}
      label={label}
    />
  );
};

export default LanguageSelector;
