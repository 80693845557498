import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification, useTrackEvent } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { UpdateSpecificAvailabilitiesParams } from 'types/availabilities.types';
import { Teacher } from 'types/teacher.types';

interface Variables {
  id: Teacher['id'];
  params: UpdateSpecificAvailabilitiesParams;
}

const updateAvailabilitiesByWeek = async ({ id, params }: Variables) => {
  const data = await apiService.updateAvailabilitiesByWeek(id, params);

  return data;
};

export const useUpdateAvailabilitiesByWeek = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const trackEvent = useTrackEvent();
  const { showNotification } = useNotification();

  const { mutate, isLoading, isPaused } = useMutation(
    updateAvailabilitiesByWeek,
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(QueryKeys.availabilities.byWeek(id), {
          refetchType: 'none',
          exact: false,
        });
        trackEvent({
          event: 'UPDATE_SPECIFIC_AVAILABILITIES',
          withRole: true,
        });
        showNotification({
          message: intl.formatMessage({
            id: 'specific_availabilities.notification.update.success',
          }),

          variant: 'success',
        });
      },
      onError: () => {
        showNotification({
          message: intl.formatMessage({
            id: 'notifications.error.something_went_wrong',
          }),

          variant: 'error',
        });
      },
    },
  );

  return {
    updateAvailabilitiesByWeek: mutate,
    isLoading,
    isPaused,
  };
};
