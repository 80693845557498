import { CognitoUser } from '@aws-amplify/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  user?: CognitoUser;
}

const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_USER: (state, { payload }: PayloadAction<CognitoUser>) => {
      state.user = payload;
    },
    RESET_USER: () => initialState,
  },
});

export const { actions: userActions } = userSlice;

export const userReducer = userSlice.reducer;
