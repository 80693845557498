import { Avatar, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { Icon } from '../Icon';

const HelpBox = () => {
  const intl = useIntl();

  return (
    <Stack gap={4} p={8} bgcolor="neutral.200" direction="column">
      <Typography variant="h5">
        <FormattedMessage id="help.box.title" />
      </Typography>
      <Stack alignItems="center" gap={2}>
        <Button
          size="small"
          href={`tel:${intl.formatMessage({ id: 'help.box.phone_number' })}`}
          startIcon={
            <Avatar variant="filled">
              <Icon fontSize={13} name="IcoPhone" />
            </Avatar>
          }
        >
          <FormattedMessage id="help.box.phone_number" />
        </Button>
      </Stack>
      <Stack alignItems="center" gap={2}>
        <Button
          href={`mailto:${intl.formatMessage({ id: 'help.box.email' })}`}
          size="small"
          startIcon={
            <Avatar variant="filled">
              <Icon fontSize={13} name="IcoMail" />
            </Avatar>
          }
        >
          <FormattedMessage id="help.box.email" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default HelpBox;
