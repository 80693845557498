import { Auth } from '@aws-amplify/auth';
import { AuthErrorMessage } from '@aws-amplify/auth/lib-esm/types';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

type Variables = {
  username: string;
  token: string;
  password: string;
};

const resetPassword = async ({ username, token, password }: Variables) => {
  const data = await Auth.forgotPasswordSubmit(username, token, password);

  return data;
};

export const useResetPassword = () => {
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess, error } = useMutation<
    string,
    AuthErrorMessage,
    Variables,
    unknown
  >(resetPassword, {
    onSuccess: () => {
      navigate(Routes.Login);
    },
  });

  return { resetPassword: mutate, isLoading, isSuccess, error };
};
