import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[800],
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '14px',
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.neutral[800],
    }),
  },
};
