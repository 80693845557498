import { Box, BoxProps } from '@mui/material';

interface Props extends Omit<BoxProps, 'width' | 'height'> {
  size?: number;
}

const Dot: React.FC<Props> = ({ size = 8, borderRadius = '50%', ...props }) => {
  return (
    <Box width={size} height={size} borderRadius={borderRadius} {...props} />
  );
};

export default Dot;
