import { Box } from '@mui/material';

interface Props {
  values: number[];
  colors?: string[];
}

const indexColor = ['pink.300', 'pink.200', 'neutral.300'];

const ProgressBar = ({ values, colors = indexColor }: Props) => {
  const totalItems = values.reduce((acc, curr) => {
    return acc + curr;
  }, 0);

  return (
    <Box display="flex" width="100%">
      {values.map((value, index) => (
        <Box
          key={index}
          bgcolor={colors[index]}
          height={6}
          width={`${(value / totalItems) * 100}%`}
        />
      ))}
    </Box>
  );
};

export default ProgressBar;
