import { Typography } from '@mui/material';
import React from 'react';

import { Dialog } from 'components/@common';

interface Props {
  open: boolean;
  title: string;
  description: string;
  onClose(): void;
}

const InfoDialog: React.FC<Props> = ({ open, title, description, onClose }) => {
  return (
    <Dialog title={title} open={open} onClose={onClose}>
      <Typography whiteSpace="pre-wrap">{description}</Typography>
    </Dialog>
  );
};

export default InfoDialog;
