import { Box, Container, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { UnderlinedText } from 'components/@common';

interface Props {
  title?: string;
  description?: string;
  button?: React.ReactNode;
}

const ErrorLayout = ({ title, description, button }: Props) => {
  return (
    <Container
      sx={{ pt: { xs: 14, md: 16 }, display: 'flex', justifyContent: 'center' }}
    >
      <Stack direction="column" alignItems="center" maxWidth={{ md: 466 }}>
        {title && (
          <Box display="inline-block" mb={10}>
            <UnderlinedText variant="h1">OEPS</UnderlinedText>
          </Box>
        )}
        {title && (
          <Typography textAlign="center" variant="h4" mb={2}>
            <FormattedMessage id={title} />
          </Typography>
        )}

        {description && (
          <Typography textAlign="center" mb={10}>
            <FormattedMessage id={description} />
          </Typography>
        )}
        {button}
      </Stack>
    </Container>
  );
};

export default ErrorLayout;
