import { SystemStyleObject } from '@mui/system';

import { Roles } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

export const MenusLookup = {
  [Roles.TEACHER]: [
    {
      label: 'courses',
      to: Routes.Courses,
    },
    {
      label: 'availabilities',
      to: Routes.Availabilities,
    },
    {
      label: 'performance',
      to: Routes.Timesheets,
    },
  ],
  [Roles.ADMIN]: [
    {
      label: 'users',
      to: Routes.Users,
    },
    {
      label: 'courses',
      to: Routes.Courses,
    },
    {
      label: 'performance',
      to: Routes.Timesheets,
    },
  ],
  [Roles.STUDENT]: [{ label: 'courses', to: Routes.Courses }],
  [Roles.BESTELLER]: [
    {
      label: 'courses',
      to: Routes.Courses,
    },
    {
      label: 'orders',
      to: Routes.Orders,
    },
  ],
};

export const NavigationColors: Record<string, SystemStyleObject> = {
  courseDetail: {
    backgroundColor: 'pink.200',
    borderBottom: 1,
    borderColor: 'neutral.100',
  },
  default: {
    backgroundColor: 'neutral.100',
    borderBottom: 1,
    borderColor: 'neutral.400',
  },
  coursePlanned: {
    backgroundColor: '#C8E0EC',
    borderBottom: 'none',
  },
};
