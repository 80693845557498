import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { PlanLessonParams } from 'types/lesson.types';

interface Variables {
  courseId: string;
  params: PlanLessonParams;
}

const planLesson = async ({ courseId, params }: Variables) =>
  apiService.planLesson(courseId, params);

export const usePlanLesson = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(planLesson, {
    onSuccess: (_, { courseId }) => {
      queryClient.invalidateQueries(QueryKeys.courses.byId(courseId), {
        refetchType: 'none',
      });
      queryClient.invalidateQueries(QueryKeys.courses.all(), {
        refetchType: 'none',
      });
      queryClient.invalidateQueries(QueryKeys.lessons.all(courseId), {
        refetchType: 'none',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),
        variant: 'error',
      });
    },
  });

  return { planLesson: mutate, isLoading };
};
