import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Lesson } from 'types/lesson.types';

interface Variables {
  lesson: Lesson;
  reason?: string;
}

const deleteLesson = async ({ lesson, reason }: Variables) =>
  apiService.deleteLesson(lesson.id, reason);

export const useDeleteLesson = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(deleteLesson, {
    onSuccess: (_, { lesson }) => {
      queryClient.invalidateQueries(QueryKeys.courses.byId(lesson.courseId));
      queryClient.invalidateQueries(QueryKeys.courses.all(), {
        refetchType: 'none',
      });
      queryClient.invalidateQueries(QueryKeys.lessons.all(lesson.courseId));

      showNotification({
        message: intl.formatMessage({
          id: 'lesson.notification.delete.success',
        }),

        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),
        variant: 'error',
      });
    },
  });

  return { deleteLesson: mutate, isLoading };
};
