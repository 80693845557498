import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Teacher } from 'types/teacher.types';

interface Variables {
  id: Teacher['id'];
}

const disableTeacher = async ({ id }: Variables) =>
  apiService.disableTeacher(id);

export const useDisableTeacher = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(disableTeacher, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(QueryKeys.user.me());
      queryClient.invalidateQueries(QueryKeys.teacher.byId(id));

      showNotification({
        message: intl.formatMessage({ id: 'teacher.disable.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return { disableTeacher: mutate, isLoading };
};
