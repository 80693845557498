/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { RouteObject } from 'react-router-dom';

import { Layout } from 'components/@layout';

import availabilities from './availabilities';
import courses from './courses';
import forgotPassword from './forgot-password';
import home from './home';
import login from './login';
import newPassword from './new-password';
import notFound from './not-found';
import onboarding from './onboarding';
import orders from './orders';
import profile from './profile';
import register from './register';
import resetPassword from './reset-password';
import teacherDetail from './teachers';
import timesheets from './timesheets';
import users from './users';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      home,
      onboarding,
      availabilities,
      courses,
      orders,
      users,
      timesheets,
      profile,
      teacherDetail,
    ],
  },
  login,
  register,
  forgotPassword,
  resetPassword,
  newPassword,
  notFound,
];
