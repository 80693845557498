import { Button, Grid } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { days } from 'components/@availabilities/constants';

interface Props {
  currentDay: string;
  onClick(day: number): void;
}

const DayPicker: React.FC<Props> = ({ currentDay, onClick }) => {
  return (
    <Grid container rowGap={4}>
      {days.map((day, index) => (
        <Grid key={day} item display="flex" justifyContent="center" xs={6}>
          <Button
            fullWidth={day === currentDay}
            size="small"
            variant={day === currentDay ? 'contained' : 'text'}
            onClick={() => onClick(index)}
          >
            <FormattedMessage id={`label.day.${day}`} />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default DayPicker;
