import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { User } from 'types/user.types';

const getUserById = async (userId: User['id']) => {
  const { data } = await apiService.getUser(userId);

  return deserialize<User>(data);
};

export const useUserById = (
  userId: User['id'],
  config?: UseQueryOptions<DeserializedResponse<User>>,
) => {
  const { data, isLoading, isFetching } = useQuery(
    QueryKeys.user.byId(userId),
    () => getUserById(userId),
    { ...config, staleTime: StaleTime.FOREVER },
  );

  return {
    data: data?.data,
    isLoading,
    isFetching,
  };
};
