import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Icon } from 'components/@common';
import { DatepickerButton, DaypickerButton } from 'components/@datepicker';

interface Props {
  type: 'specific' | 'default';
  currentDay: string | Date;
  onNextDay(): void;
  onPrevDay(): void;
  onSetDay(day: Date | number): void;
  isAdmin?: boolean;
}

const Mobile: React.FC<Props> = ({
  type,
  currentDay,
  onNextDay,
  onPrevDay,
  onSetDay,
  isAdmin,
}) => {
  const navigate = useNavigate();
  const handleSetDay = (date: Date | null) => {
    if (onSetDay && date) onSetDay(date);
  };

  return (
    <Stack gap={4} pb={4} alignItems="center" display={{ lg: 'none' }}>
      {type === 'specific' ? (
        <DatepickerButton
          type="day"
          date={currentDay as Date}
          onNext={onNextDay}
          onPrevious={onPrevDay}
          onChange={handleSetDay}
        />
      ) : (
        <DaypickerButton
          day={String(currentDay)}
          onPrevious={onPrevDay}
          onNext={onNextDay}
          onChange={onSetDay}
          fullWidth
        />
      )}
      {type === 'specific' && !isAdmin && (
        <IconButton
          onClick={() => navigate(Routes.DefaultAvailabilities)}
          color="secondary"
        >
          <Icon name="IcoSettings" />
        </IconButton>
      )}
    </Stack>
  );
};

export default Mobile;
