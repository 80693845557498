import { IconButton } from '@mui/material';
import { SnackbarMessage, useSnackbar, VariantType } from 'notistack';

import { Icon } from 'components/@common';

type Notification = {
  message: SnackbarMessage;
  variant: VariantType;
};

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = ({ variant, message }: Notification) =>
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon fill="white" name="IcoClose" />
        </IconButton>
      ),
    });

  return {
    showNotification,
  };
};
