import { Box, Stack } from '@mui/material';
import {
  Children,
  createContext,
  FC,
  PropsWithChildren,
  useState,
} from 'react';

import { BackButton } from 'components/@common';

import { StepIndicator } from '../StepIndicator';

interface MultistepContextProps {
  activeStep: number;
  goTo(step: number): void;
  proceed(): void;
  back(): void;
}

interface Props {
  currentStep?: number;
  disabledSteps?: number[];
  stepLabels: string[];
  onBackFirstStep?(): void;
}

export const MultistepContext = createContext<MultistepContextProps>({
  activeStep: 0,
  goTo: () => {},
  proceed: () => {},
  back: () => {},
});

const MultiStep: FC<PropsWithChildren<Props>> = ({
  currentStep,
  disabledSteps = [],
  stepLabels,
  onBackFirstStep,
  children,
}) => {
  const [activeStep, setActiveStep] = useState(currentStep ?? 0);

  const goTo = (step: number) => setActiveStep(step);

  const proceed = () => setActiveStep((step) => step + 1);

  const back = () => setActiveStep((step) => step - 1);

  const initialValue: MultistepContextProps = {
    activeStep,
    goTo,
    proceed,
    back,
  };

  const steps = Children.toArray(children).filter(
    (_, i) => disabledSteps.indexOf(i) === -1,
  );

  const labels = stepLabels.filter((_, i) => disabledSteps.indexOf(i) === -1);

  const hasBackOnFirstStep = !activeStep && onBackFirstStep;

  const handleBack = () => (hasBackOnFirstStep ? onBackFirstStep() : back());

  return (
    <MultistepContext.Provider value={initialValue}>
      <Stack
        alignItems={{ xs: 'center', lg: 'flex-start' }}
        direction={{ xs: 'row', lg: 'column' }}
        justifyContent={{
          xs: !!activeStep || hasBackOnFirstStep ? 'space-between' : 'flex-end',
        }}
        mb={{ xs: 4, lg: 10 }}
      >
        {(!!activeStep || hasBackOnFirstStep) && (
          <Box ml={1} mb={{ lg: 4 }}>
            <BackButton text="label.back" onClick={handleBack} />
          </Box>
        )}
        <Box
          width={{ lg: '100%' }}
          mt={{ lg: !!activeStep || hasBackOnFirstStep ? 0 : 12 }}
        >
          <StepIndicator activeStep={activeStep} steps={labels} />
        </Box>
      </Stack>
      {children && steps[activeStep]}
    </MultistepContext.Provider>
  );
};

export default MultiStep;
