import { AxiosInstance } from 'axios';

import { getAccessToken } from 'services/amplify/tokens';

const updateMethods = ['post', 'put', 'patch'];

export const createHeaderRequestInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async (request) => {
    request.headers = request.headers ?? {};

    const accessToken = await getAccessToken();

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (request.method && updateMethods.includes(request.method)) {
      request.headers['Content-Type'] = 'application/vnd.api+json';
    }

    return request;
  });
};
