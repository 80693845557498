import { Components, Theme } from '@mui/material';
import { pickersDayClasses } from '@mui/x-date-pickers';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    root: {
      '&.datepicker-dialog': {
        [`.${pickersDayClasses.root}`]: {
          margin: 0,
          width: 45,
          height: 45,
        },
      },
    },
    paper: ({ theme }) => ({
      borderRadius: 0,
      padding: theme.spacing(8, 0),
      width: 600,

      [theme.breakpoints.down('lg')]: {
        maxWidth: '100%',
        maxHeight: window.innerHeight,
        height: window.innerHeight,
        width: '100%',
        margin: 0,
        padding: theme.spacing(4, 0),
      },
    }),
  },
};
