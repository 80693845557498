import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getPrimaryRole, isTeacher } from 'utils/user.utils';

import { useUser } from 'queries';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

import { LoadingState } from 'components/@states';

import { EmptyLayout } from '../EmptyLayout';
import { Icon } from '../Icon';

const RouteProtected: FC<React.PropsWithChildren<RouteProps>> = ({
  children,
  roles,
}) => {
  const navigate = useNavigate();
  const { data: user, isFetching: isFetchingUser } = useUser({
    onError: () => {
      navigate(Routes.Login, { replace: true });
    },
  });
  const currentRole = getPrimaryRole(user);

  useEffect(() => {
    if (
      !isFetchingUser &&
      (!user?.attributes['custom:has_onboarded'] ||
        user?.attributes['custom:has_onboarded']) === 'false' &&
      isTeacher(user)
    ) {
      navigate(Routes.Onboarding, { replace: true });
    }
  }, [isFetchingUser, navigate, user]);

  if (isFetchingUser) return <LoadingState />;

  if (!roles?.find((role) => role === currentRole) && roles)
    return (
      <EmptyLayout
        subTitle="page.not_found.title"
        description="page.not_found.description"
        buttonText="page.general.error.button.back"
        icon={<Icon name="IcoNoLessons" width={48} height={48} />}
        onHandleRoute={() => navigate(Routes.Root)}
      />
    );

  return <>{children}</>;
};

export default RouteProtected;
