import { Auth } from '@aws-amplify/auth';
import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useNotification } from 'hooks';

import { Routes } from 'pages/routes.constants';

type Variables = {
  email: string;
  redirect?: boolean;
};

const forgotPassword = async ({ email }: Variables) => {
  const data = await Auth.forgotPassword(email);

  return data;
};

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { showNotification } = useNotification();
  const { mutate, isLoading, isSuccess, isPaused } = useMutation(
    forgotPassword,
    {
      onSettled: (_d, _e, { redirect = true }) => {
        if (redirect) {
          navigate(Routes.Login);
        }
        showNotification({
          message: intl.formatMessage({
            id: 'forgot_password.notification.success',
          }),
          variant: 'success',
        });
      },
    },
  );
  return {
    requestPasswordReset: mutate,
    isLoading,
    isSuccess,
    isPaused,
  };
};
