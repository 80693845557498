import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import { Course, CoursesMeta, GetAllCoursesParams } from 'types/course.types';

const getAllCourses = async (params: GetAllCoursesParams) => {
  const { data } = await apiService.getAllCourses(params);

  return deserialize<Course, CoursesMeta>(data);
};

export const useAllCourses = (
  params: GetAllCoursesParams,
  config?: UseCommonQueryOptions<
    DeserializedArrayResponse<Course, CoursesMeta>
  >,
) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    QueryKeys.courses.byUser(params),
    () => getAllCourses(params),
    { keepPreviousData: true, ...config },
  );

  return { ...getListData(data), isLoading, isFetching, isError };
};
