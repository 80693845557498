import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Teacher } from 'types/teacher.types';
import { Timesheet } from 'types/timesheet.types';

interface Variables {
  teacherId: Teacher['id'];
  timesheetId: Timesheet['id'];
}

const regenerateTimesheet = async ({ teacherId, timesheetId }: Variables) =>
  apiService.regenerateTimesheet(teacherId, timesheetId);

export const useRegenerateTimesheet = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(regenerateTimesheet, {
    onSuccess: (_, { teacherId, timesheetId }) => {
      queryClient.invalidateQueries(
        QueryKeys.timesheets.byId(teacherId, timesheetId),
      );
      queryClient.invalidateQueries(
        QueryKeys.timesheets.lessons(teacherId, timesheetId),
      );
      queryClient.invalidateQueries(QueryKeys.timesheets.all(), {
        refetchType: 'none',
      });
      queryClient.invalidateQueries(QueryKeys.timesheets.byTeacher(teacherId), {
        refetchType: 'none',
      });

      showNotification({
        message: intl.formatMessage({
          id: 'timesheet.regenerate_timesheet.success',
        }),

        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return { regenerateTimesheet: mutate, isLoading };
};
