import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Roles } from 'types/user.types';

import { RouteProtected } from 'components/@common/Route';

export type Route = Omit<RouteObject, 'element'> & {
  authenticated?: boolean;
  roles?: Roles[];
};

export const createRoute = (
  Component: React.LazyExoticComponent<React.FC>,
  config?: Route,
) => {
  const routeObject: RouteObject = {
    ...config,
    element: <Component />,
  };

  if (config?.authenticated) {
    routeObject.element = (
      <RouteProtected roles={config.roles}>
        <Component />
      </RouteProtected>
    );
  }

  return routeObject;
};
