import { useMutation } from '@tanstack/react-query';

import { apiService } from 'services';
import { PresignedUrlParams } from 'types/internal.types';

const requestPresignedUrl = async (variables: PresignedUrlParams) => {
  const { data } = await apiService.requestPresignedUrl(variables);

  return data;
};

export const useRequestPresignedUrl = () => {
  const { mutateAsync, isLoading } = useMutation(requestPresignedUrl);

  return {
    requestPresignedUrl: mutateAsync,
    isLoading,
  };
};
