import { InputBase, InputBaseProps } from '@mui/material';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<InputBaseProps, 'name'>;

const FormInputBase: React.FC<Props> = ({ rules, name, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { ref, ...field } }) => (
        <InputBase inputRef={ref} {...field} {...rest} />
      )}
    />
  );
};

export default FormInputBase;
