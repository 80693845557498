import { Components } from '@mui/material';

export const MuiDialogContent: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      padding: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};
