import { capitalize, Stack } from '@mui/material';
import { parseISO } from 'date-fns';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { isTimeslotDisabled } from 'utils/timeslot.utils';

import {
  AvailabilityStatus,
  SelectedTimeslots,
} from 'types/availabilities.types';

import { DayItem } from 'components/@timeslots/DayItem';

import { timeslots } from '../constants';

interface Props {
  type: 'specific' | 'default';
  days: string[];
  availabilities: SelectedTimeslots;
  onClear?(index: number): void;
  onSetDefault?(day: string, index: number): void;
  onRemoveSelected?(day: string): void;
}

const Desktop: FC<Props> = ({
  days,
  type,
  availabilities,
  onClear,
  onSetDefault,
  onRemoveSelected,
}) => {
  const intl = useIntl();
  const selectedTimeslots = useCallback(
    (index: number) => {
      let currentDay = String(index);
      if (type === 'specific') {
        currentDay = days[index];
      }
      const arr = availabilities[currentDay];

      if (arr) {
        const availableTimeslots = Object.entries(arr).filter(
          ([timeslot, status]) =>
            status !== AvailabilityStatus.UNAVAILABLE &&
            status !== AvailabilityStatus.BOOKED &&
            !isTimeslotDisabled(currentDay, timeslot),
        ).length;

        const bookedTimeslots = Object.values(arr).filter(
          (status) => status === AvailabilityStatus.BOOKED,
        ).length;

        return {
          available: availableTimeslots,
          booked: type === 'specific' ? bookedTimeslots : undefined,
        };
      }

      return undefined;
    },
    [availabilities, days, type],
  );

  const formatDay = (day: string) =>
    type === 'specific'
      ? capitalize(
          intl
            .formatDate(parseISO(day), {
              weekday: 'short',
              day: 'numeric',
              month: 'short',
            })
            .replace('.', ''), // intl adds a . to months (okt.), FUMA doesn't want it
        )
      : day;

  const disableAction = (index: number) => {
    const currentDay = days[index];
    const disabledStates = timeslots.map(({ timeStart }) =>
      isTimeslotDisabled(currentDay, timeStart),
    );

    return !disabledStates.some((disabled) => !disabled);
  };

  return (
    <Stack
      position="sticky"
      top="0"
      pb={2}
      pt={2}
      borderBottom={1}
      borderColor="neutral.400"
      bgcolor="neutral.100"
      zIndex={999}
    >
      {days.map((day, index) => (
        <DayItem
          type={type}
          amountSelected={selectedTimeslots(index)}
          key={`day-${index}`}
          day={formatDay(day)}
          onSetDefault={() => onSetDefault?.(day, index)}
          onRemoveSelected={() => onRemoveSelected?.(day)}
          onClear={() => onClear?.(index)}
          disableAction={disableAction(index)}
        />
      ))}
    </Stack>
  );
};

export default Desktop;
