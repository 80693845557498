import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';
import { matchSorter } from 'match-sorter';
import React from 'react';

export type Option = {
  label: string;
  value: string;
};

export interface AutocompleteProps {
  value: Option;
  options: Option[];
  onChange(value: Option): void;
  label: string;
  required?: boolean;
  getOptionLabel?(option: Option): string;
  noOptionsText?: string;
  isOptionEqualToValue?(option: Option, value: Option): void;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
}

const Autocomplete = React.forwardRef<unknown, AutocompleteProps>(
  (
    {
      value,
      options,
      onChange,
      label,
      required,
      getOptionLabel,
      noOptionsText,
      helperText,
      error,
      disabled,
    },
    ref,
  ) => {
    const filterOptions = (
      options: { label: string; value: string }[],
      { inputValue }: { inputValue: string },
    ) => matchSorter(options, inputValue, { keys: ['label', 'value'] });

    const handleGetOptionLabel = (option: Option) => {
      if (!getOptionLabel) {
        return option.label;
      }

      return getOptionLabel(option);
    };

    return (
      <MuiAutocomplete<Option>
        value={value}
        options={options}
        ref={ref}
        disabled={disabled}
        filterOptions={filterOptions}
        getOptionLabel={handleGetOptionLabel}
        onChange={(_, data) => {
          if (data) {
            onChange(data);
          }
        }}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            helperText={helperText}
            label={label}
            required={required}
            error={error}
          />
        )}
      />
    );
  },
);

export default Autocomplete;
