import { IconButton, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { Icon } from '../Icon';

interface Props {
  page: number;
  onPageChange(page: number): void;
  total: number;
}

const PAGE_SIZE = 10;

const Pagination = ({ page, onPageChange, total }: Props) => {
  const intl = useIntl();
  const formatPageIndication = () => {
    const from = page === 1 ? 1 : page * PAGE_SIZE + 1 - PAGE_SIZE;
    let to = page * PAGE_SIZE;

    if (to >= total) {
      to = total;
    }

    return intl.formatMessage(
      { id: 'pagination.indicator.total' },
      { totalFetched: `${from} - ${to}`, total },
    );
  };

  const onNextPage = () => {
    if (page * PAGE_SIZE < total) {
      onPageChange(page + 1);
    }
  };

  const onPrevPage = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const isPrevDisabled = page === 1;
  const isNextDisabled = page * PAGE_SIZE > total;

  return (
    <Stack py={3} alignItems="center" justifyContent="space-between">
      <IconButton
        sx={{ display: { lg: 'none' } }}
        disabled={isPrevDisabled}
        onClick={onPrevPage}
      >
        <Icon name="IcoChevronLeft" />
      </IconButton>
      <Typography variant="body2">{formatPageIndication()}</Typography>
      <Stack>
        <IconButton
          sx={{ display: { xs: 'none', lg: 'flex' } }}
          disabled={isPrevDisabled}
          onClick={onPrevPage}
        >
          <Icon name="IcoChevronLeft" />
        </IconButton>
        <IconButton disabled={isNextDisabled} onClick={onNextPage}>
          <Icon name="IcoChevronRight" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default Pagination;
