import storage from 'redux-persist/lib/storage';

import config from 'config';

import { generateStore } from 'services/redux';
import { initApp } from 'services/system';

import combinedReducers from './reducers';

const store = generateStore(combinedReducers, {
  initApp,
  enableDevTools: Boolean(config.ENABLE_DEV_TOOLS),
  persistConfig: {
    key: 'root',
    keyPrefix: '',
    storage,
    version: 1,
    debug: Boolean(config.ENABLE_DEV_TOOLS),
    whitelist: ['intl', 'match-lessons', 'lessons'],
    // add subsets to persist nested data
    transforms: [],
  },
});

export default store;
export type AppDispatch = typeof store.store.dispatch;
export type RootState = ReturnType<typeof store.store.getState>;

export * from './hooks';
export * from './intl';
export * from './lessons';
export * from './match-lessons';
export * from './user';
