import React, { createContext, PropsWithChildren, useReducer } from 'react';
import { AnyAction } from 'redux';

import { AvailabilitiesState } from 'types/availabilities.types';

import {
  defaultAvailabilitiesReducer,
  initialState,
  specificAvailabilitiesReducer,
} from './reducers';

interface Context {
  defaultAvailabilities: AvailabilitiesState;
  specificAvailabilities: AvailabilitiesState;
  dispatchDefaultState: React.Dispatch<AnyAction>;
  dispatchSpecificState: React.Dispatch<AnyAction>;
}

export const AvailabilitiesContext = createContext<Context>({
  defaultAvailabilities: initialState,
  specificAvailabilities: initialState,
  dispatchDefaultState: () => {},
  dispatchSpecificState: () => {},
});

const AvailabilitiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [defaultAvailabilities, dispatchDefaultState] = useReducer(
    defaultAvailabilitiesReducer,
    initialState,
  );
  const [specificAvailabilities, dispatchSpecificState] = useReducer(
    specificAvailabilitiesReducer,
    initialState,
  );

  return (
    <AvailabilitiesContext.Provider
      value={{
        defaultAvailabilities,
        specificAvailabilities,
        dispatchDefaultState,
        dispatchSpecificState,
      }}
    >
      {children}
    </AvailabilitiesContext.Provider>
  );
};

export default AvailabilitiesProvider;
