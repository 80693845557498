import { ClickAwayListener, Paper, Popper } from '@mui/material';
import { addMonths, endOfWeek, startOfWeek } from 'date-fns';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';

import { ArrowButton, Icon } from 'components/@common';

import { CalendarPicker } from '../CalendarPicker';

interface Props {
  type: 'day' | 'week';
  date: Date;
  onChange(date: Date): void;
  onPrevious(): void;
  onNext(): void;
  disabled?: boolean;
  fullWidth?: boolean;
}

const DatepickerButton: React.FC<Props> = ({
  type,
  date,
  onChange,
  onNext,
  onPrevious,
  disabled,
  fullWidth,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const start = startOfWeek(date);
  const end = endOfWeek(date);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <ArrowButton
          fullWidth={fullWidth}
          onClick={handleClick}
          onNext={onNext}
          onPrevious={onPrevious}
          disabled={disabled}
          endIcon={<Icon name="IcoCalendar" />}
        >
          {type === 'day' ? (
            capitalize(
              intl.formatDate(date, {
                weekday: 'short',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              }),
            )
          ) : (
            <>
              <FormattedDate value={start} day="numeric" /> -{' '}
              {intl
                .formatDate(end, {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                .replace('.', '')}
            </>
          )}
        </ArrowButton>
        <Popper open={open} anchorEl={anchorEl}>
          <Paper sx={{ width: { xs: '100vw', lg: 368 } }} square elevation={5}>
            <CalendarPicker
              date={date}
              onChange={onChange}
              views={['day', 'month']}
              maxDate={addMonths(new Date(), 3)}
            />
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default DatepickerButton;
