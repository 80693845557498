import { useContext } from 'react';

import { parseTimeslotStatuses } from 'utils/availability.utils';

import {
  AvailabilitiesState,
  DefaultAvailabilities,
  SelectedTimeslots,
  SpecificAvailabilityTimeslot,
} from 'types/availabilities.types';

import { AvailabilitiesContext } from './AvailabilitiesProvider';
import {
  defaultAvailabilitiesActions,
  specificAvailabilitiesActions,
} from './reducers';

export const useAvailabilities = (type: 'specific' | 'default') => {
  const {
    dispatchDefaultState,
    dispatchSpecificState,
    defaultAvailabilities,
    specificAvailabilities,
  } = useContext(AvailabilitiesContext);

  const availabilities =
    type === 'specific' ? specificAvailabilities : defaultAvailabilities;

  const dispatch =
    type === 'specific' ? dispatchSpecificState : dispatchDefaultState;

  const actions =
    type === 'specific'
      ? specificAvailabilitiesActions
      : defaultAvailabilitiesActions;

  const clearTimeslots = (day: string) =>
    dispatch(actions.CLEAR_TIMESLOTS(day));

  const resetToDefault = (
    day: string,
    timeslots: SpecificAvailabilityTimeslot[],
    data?: DefaultAvailabilities,
  ) => {
    if (data) {
      const initial = parseTimeslotStatuses(timeslots)[day];

      dispatchSpecificState(
        specificAvailabilitiesActions.RESET_TO_DEFAULT_AVAILABILITIES({
          day,
          initial,
        }),
      );
    }
  };

  const setUnsavedChanges = (data: boolean) =>
    dispatch(actions.SET_UNSAVED_CHANGES(data));

  const setTimeslots = (data: SelectedTimeslots) =>
    dispatch(actions.SET_TIMESLOTS(data));

  const setAvailabilities = (data: Partial<AvailabilitiesState>) =>
    dispatch(actions.SET_AVAILABILITIES(data));

  const toggleTimeslot = (day: string | number, timeslot: string) =>
    dispatch(actions.UPDATE_TIMESLOT({ day, timeslot }));

  const resetAvailabilities = () => dispatch(actions.RESET_AVAILABILITIES());

  const resetSpecificAvailabilities = () =>
    dispatch(specificAvailabilitiesActions.RESET_AVAILABILITIES());

  return {
    ...availabilities,
    clearTimeslots,
    resetToDefault,
    setTimeslots,
    setAvailabilities,
    toggleTimeslot,
    resetAvailabilities,
    setUnsavedChanges,
    resetSpecificAvailabilities,
  };
};
