import { Auth } from '@aws-amplify/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { QueryKeys } from 'queries/QueryKeys';

import { Routes } from 'pages/routes.constants';

const logout = () => Auth.signOut();

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(logout, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.user.me());
      queryClient.clear();
      navigate(Routes.Login);
    },
  });

  return { logout: mutateAsync, isLoading };
};
