import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  API_BASE_AUTH: string;
  COOKIE_DOMAIN: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  WEBSHOP_ROUTE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
  AMPLIFY: {
    REGION: string;
    USER_POOL_ID: string;
    CLIENT_ID: string;
  };
  POSTHOG: {
    ID: string;
    API_HOST: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_BASE_AUTH: process.env.REACT_APP_API_BASE_AUTH || '',

  // analytics
  POSTHOG: {
    ID: process.env.REACT_APP_POSTHOG_TRACKING_ID || '',
    API_HOST: process.env.REACT_APP_POSTHOG_API_HOST || '',
  },

  // error tracking
  SENTRY: {
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // amplify
  AMPLIFY: {
    REGION: process.env.REACT_APP_AMPLIFY_REGION || '',
    USER_POOL_ID: process.env.REACT_APP_AMPLIFY_USER_POOL_ID || '',
    CLIENT_ID: process.env.REACT_APP_AMPLIFY_CLIENT_ID || '',
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'nl',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',

  WEBSHOP_ROUTE: process.env.REACT_APP_WEBSHOP_ROUTE ?? '',
};

export default config;
