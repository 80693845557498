import { typographyClasses } from '@mui/material';

export const MuiCalendarPicker = {
  styleOverrides: {
    root: {
      width: '100%',

      [`& .${typographyClasses.root}`]: {
        fontSize: 12,
        width: 48,
        height: 40,
        margin: '0 2px',
      },

      '& .PrivatePickersSlideTransition-root': {
        'div[role=cell]': {
          width: 48,
          height: 36,
          textAlign: 'center',
          margin: '0 2px',
        },
      },
    },
  },
};
