import axios, { AxiosRequestConfig } from 'axios';
import humps from 'humps';
import qs from 'qs';

import interceptors from './interceptors';

const DEFAULT_CONFIG: AxiosRequestConfig = {
  headers: { Accept: 'application/vnd.api+json' },
  transformRequest: [
    (data) => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          // Prevent converting presigned url data, so it can be passed with the keys unchanged to AWS
          if (!('signed_url' in data)) {
            data = humps.camelizeKeys(data);
          }
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data);
      }
    },
  ],
  paramsSerializer: (params) => {
    return qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'comma' });
  },
};

const createManager = (requestConfig?: AxiosRequestConfig) => {
  const manager = axios.create({ ...DEFAULT_CONFIG, ...requestConfig });

  manager.interceptors.request.use(
    async (axiosConfig) => axiosConfig,
    (error) => Promise.reject(error),
  );

  interceptors.forEach((interceptor) => interceptor(manager));

  return manager;
};

export { createManager };
