import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import {
  GetAvailabilitiesByDayParams,
  GetAvailabilitiesResponse,
} from 'types/availabilities.types';

const getDayAvailabilitiesBySkill = async (
  skill: string,
  params: GetAvailabilitiesByDayParams,
) => {
  const { data } = await apiService.getDayAvailabilitiesBySkill(skill, params);

  return data.data;
};

export const useDayAvailabilitiesBySkill = (
  skill: string,
  params: GetAvailabilitiesByDayParams,
  config: UseCommonQueryOptions<GetAvailabilitiesResponse> = {},
) => {
  const { data, isLoading, status, isFetching } = useQuery(
    QueryKeys.availabilities.bySkill.day(skill, params),
    () => getDayAvailabilitiesBySkill(skill, params),
    { ...config, staleTime: 0 },
  );

  return {
    data: data || ([] as GetAvailabilitiesResponse),
    isLoading,
    isFetching,
    status,
  };
};
