import { getNames } from 'i18n-iso-countries';
import React from 'react';

import { getLocale, useAppSelector } from 'store';

import { Autocomplete } from '../Autocomplete';

type Option = {
  label: string;
  value: string;
};

interface Props {
  value: Option;
  onChange(value: Option): void;
  label: string;
}

const CountrySelector: React.FC<Props> = ({ value, onChange, label }) => {
  const platformLocale = useAppSelector(getLocale);

  const countries = Object.entries(getNames(platformLocale)).map(
    ([code, country]) => ({
      label: country,
      value: code,
    }),
  );

  return (
    <Autocomplete
      value={value}
      options={countries}
      onChange={onChange}
      label={label}
    />
  );
};

export default CountrySelector;
