export enum Resources {
  TEACHERS = 'teachers',
  AVAILABILITIES = 'availabilities',
  DEFAULT_AVAILABILITIES = 'default_availabilities',
  USERS = 'users',
  LESSONS = 'lessons',
  COURSES = 'courses',
  TIMESHEETS = 'timesheets',
  EVALUATIONS = 'evaluations',
}

export type PageParams = {
  page?: {
    number: number;
  };
};

export type BaseParams<TFilter> = PageParams & {
  filter?: TFilter & {
    search?: string;
    language?: string;
  };
  sort?: string;
  onlyTeacherLanguages?: number;
};
