import React, { ComponentProps } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import { Datepicker } from 'components/@datepicker';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<ComponentProps<typeof Datepicker>, 'startDate' | 'onChange'>;

const FormDatePicker: React.FC<Props> = ({ fullWidth, name, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Datepicker
          fullWidth={fullWidth}
          startDate={value}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  );
};

export default FormDatePicker;
