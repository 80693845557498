import { createTheme } from '@mui/material/styles';

import { baseTheme } from './base';
import * as components from './overrides';
import { typography } from './typography';

/**
 *  Theme
 */
const theme = createTheme(baseTheme, { components, typography });

export default theme;
