import {
  Avatar,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React from 'react';

import { useMedia } from 'hooks';

import { Icon } from 'components/@common';

interface Props {
  activeStep: number;
  steps: string[];
}

interface StepCircleProps extends Omit<StepIconProps, 'icon'> {
  index: number;
}

const StepCircle = ({ active, completed, index }: StepCircleProps) => {
  return (
    <Avatar
      sx={(theme) => ({
        width: 24,
        height: 24,
        color: theme.palette.neutral[active ? 800 : 100],
        background:
          active || completed
            ? theme.palette.yellow[200]
            : theme.palette.neutral[500],
      })}
    >
      {completed ? (
        <Icon name="IcoCheck" fontSize={16} />
      ) : (
        <Typography variant="caption">{index}</Typography>
      )}
    </Avatar>
  );
};

const StepIndicator: React.FC<Props> = ({ activeStep, steps }) => {
  const { isDesktop } = useMedia();
  return !isDesktop ? (
    <Stack alignItems="flex-end">
      <Typography variant="body2">{`${steps[activeStep]} ${activeStep + 1}/${
        steps.length
      }`}</Typography>
    </Stack>
  ) : (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconComponent={(props) => (
              <StepCircle index={index + 1} {...props} />
            )}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
