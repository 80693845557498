import { combineReducers } from 'redux';

import { intlReducer } from './intl';
import { lessonsReducer } from './lessons';
import { matchLessonsReducer } from './match-lessons';
import { userReducer } from './user';

const reducers = {
  intl: intlReducer,
  user: userReducer,
  matchLessons: matchLessonsReducer,
  lessons: lessonsReducer,
};

export const rootReducer = combineReducers(reducers);

export type RootReducer = typeof rootReducer;
export type Store = ReturnType<RootReducer>;

export default rootReducer;
