import { Theme } from '@mui/material';

export const MuiYearPicker = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      [`& .PrivatePickersYear-root`]: {
        '& .PrivatePickersYear-yearButton': {
          lineHeight: 'unset',
        },
        '& .PrivatePickersYear-yearButton.Mui-selected': {
          backgroundColor: theme.palette.neutral[800],
        },
      },
    }),
  },
};
