import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification, useTrackEvent } from 'hooks';
import { useUser } from 'queries';
import { Keys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { UpdateDefaultAvailabilitiesParams } from 'types/availabilities.types';
import { Teacher } from 'types/teacher.types';

interface Variables {
  id: Teacher['id'];
  params: UpdateDefaultAvailabilitiesParams;
}

const updateDefaultAvailabilities = ({ id, params }: Variables) =>
  apiService.updateDefaultAvailabilities(id, params);

export const useUpdateDefaultAvailabilities = () => {
  const queryClient = useQueryClient();
  const trackEvent = useTrackEvent();
  const intl = useIntl();
  const { showNotification } = useNotification();
  const { data: user } = useUser();
  const teacherId = user?.getUsername() || '';
  const { mutate, isLoading, isPaused } = useMutation(
    updateDefaultAvailabilities,
    {
      onSuccess: () => {
        trackEvent({ event: 'UPDATE_DEFAULT_AVAILABILITIES', withRole: true });

        queryClient.invalidateQueries({
          queryKey: [Keys.Availabilities],
          refetchType: 'none',
        });
      },
      onError: () => {
        showNotification({
          message: intl.formatMessage({
            id: 'notifications.error.something_went_wrong',
          }),
          variant: 'error',
        });
      },
    },
  );

  const handleUpdateDefaultAvailabilities = (
    params: Variables['params'],
    options?: MutateOptions<unknown, unknown, Variables, unknown>,
  ) => mutate({ id: teacherId, params }, options);

  return {
    updateDefaultAvailabilities: handleUpdateDefaultAvailabilities,
    isLoading,
    isPaused,
  };
};
