import { Course } from 'types/course.types';
import { Lesson } from 'types/lesson.types';
import { Teacher } from 'types/teacher.types';
import { Timesheet } from 'types/timesheet.types';
import { User } from 'types/user.types';

export enum Keys {
  User = 'user',
  Users = 'users',
  Teacher = 'teacher',
  Teachers = 'teachers',
  Availabilities = 'availabilities',
  Courses = 'courses',
  Orders = 'Orders',
  Skill = 'Skill',
  Lessons = 'lessons',
  Evaluation = 'evaluation',
  Certificate = 'certificate',
  Timesheets = 'timesheets',
  Pdf = 'pdf',
}

type QueryKey = readonly unknown[];

export const QueryKeys = {
  user: {
    byId: (userId: User['id']): QueryKey => [Keys.User, userId],
    me: (): QueryKey => [Keys.User],
    all: (...rest: unknown[]): QueryKey => [Keys.Users, ...rest],
  },
  teacher: {
    all: (...rest: unknown[]): QueryKey => [Keys.Teachers, ...rest],
    byId: (id: Teacher['id']): QueryKey => [Keys.Teacher, id],
    evaluation: (id: Teacher['id'], courseId: Course['id']): QueryKey => [
      Keys.Teacher,
      id,
      courseId,
    ],
  },
  timesheets: {
    all: (...rest: unknown[]): QueryKey => [Keys.Timesheets, ...rest],
    byTeacher: (teacherId: Teacher['id'], ...rest: unknown[]): QueryKey => [
      Keys.Timesheets,
      teacherId,
      ...rest,
    ],
    byId: (
      teacherId: Teacher['id'],
      timesheetId: Timesheet['id'],
    ): QueryKey => [Keys.Timesheets, teacherId, timesheetId],
    lessons: (
      teacherId: Teacher['id'],
      timesheetId: Timesheet['id'],
      ...rest: unknown[]
    ): QueryKey => [
      Keys.Timesheets,
      Keys.Lessons,
      teacherId,
      timesheetId,
      ...rest,
    ],
    pdf: (teacherId: Teacher['id'], timesheetId: Timesheet['id']): QueryKey => [
      Keys.Timesheets,
      Keys.Pdf,
      teacherId,
      timesheetId,
    ],
  },
  availabilities: {
    default: (id: Teacher['id']): QueryKey => [Keys.Availabilities, id],
    byWeek: (id: Teacher['id'], ...rest: unknown[]): QueryKey => [
      Keys.Availabilities,
      id,
      ...rest,
    ],
    bySkill: {
      month: (skill: string, ...rest: unknown[]): QueryKey => [
        Keys.Availabilities,
        'month',
        skill,
        ...rest,
      ],
      day: (skill: string, ...rest: unknown[]): QueryKey => [
        Keys.Availabilities,
        'day',
        skill,
        ...rest,
      ],
    },
    byTeacher: {
      month: (teacherId: string, ...rest: unknown[]): QueryKey => [
        Keys.Availabilities,
        'month',
        teacherId,
        ...rest,
      ],
      day: (teacherId: string, ...rest: unknown[]): QueryKey => [
        Keys.Availabilities,
        'day',
        teacherId,
        ...rest,
      ],
    },
  },
  courses: {
    byId: (id: string): QueryKey => [Keys.Courses, id],
    byUser: (...rest: unknown[]): QueryKey => [Keys.Courses, ...rest],
    all: (...rest: unknown[]): QueryKey => [Keys.Courses, ...rest],
    evaluation: (courseId: Course['id']): QueryKey => [
      Keys.Courses,
      Keys.Evaluation,
      courseId,
    ],
    certificate: (courseId: Course['id']): QueryKey => [
      Keys.Courses,
      Keys.Certificate,
      courseId,
    ],
  },
  orders: {
    all: (...rest: unknown[]): QueryKey => [Keys.Orders, ...rest],
  },
  lessons: {
    all: (courseId: string, ...rest: unknown[]): QueryKey => [
      Keys.Lessons,
      courseId,
      ...rest,
    ],
    evaluation: (lessonId: Lesson['id']): QueryKey => [
      Keys.Lessons,
      Keys.Evaluation,
      lessonId,
    ],
  },
};
