import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ?? 1;

  const handlePageChange = (page: number) => {
    searchParams.set('page', String(page));
    setSearchParams(searchParams);
  };

  const handleResetPage = () => {
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return {
    currentPage: Number(currentPage),
    handlePageChange,
    handleResetPage,
  };
};
