import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import {
  GetAvailabilitiesByDayParams,
  GetAvailabilitiesResponse,
} from 'types/availabilities.types';

const getTeacherAvailabilitiesByDay = async (
  teacherId: string,
  params: GetAvailabilitiesByDayParams,
) => {
  const { data } = await apiService.getTeacherAvailabilitiesByDay(
    teacherId,
    params,
  );

  return data.data;
};

export const useTeacherAvailabilitiesByDay = (
  teacherId: string,
  params: GetAvailabilitiesByDayParams,
  config: UseCommonQueryOptions<GetAvailabilitiesResponse> = {},
) => {
  const { data, isLoading, status, isFetching } = useQuery(
    QueryKeys.availabilities.byTeacher.day(teacherId, params),
    () => getTeacherAvailabilitiesByDay(teacherId, params),
    { ...config, staleTime: 0 },
  );

  return {
    data: data || ([] as GetAvailabilitiesResponse),
    isLoading,
    isFetching,
    status,
  };
};
