import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Course, CourseCertificate } from 'types/course.types';

const getCourseCertificate = async (courseId: Course['id']) => {
  const { data } = await apiService.getCourseCertificate(courseId);

  return deserialize(data);
};

export const useCourseCertificate = (
  courseId: Course['id'],
  config?: UseCommonQueryOptions<DeserializedResponse<CourseCertificate>>,
) => {
  const { data, isLoading } = useQuery(
    QueryKeys.courses.certificate(courseId),
    () => getCourseCertificate(courseId),
    config,
  );

  return {
    data: data?.data,
    isLoading,
  };
};
