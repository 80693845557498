import { getName, getNames } from '@cospired/i18n-iso-languages';
import { getNames as getCountries } from 'i18n-iso-countries';
import { useIntl } from 'react-intl';

import { platformLanguages } from 'constants/languages';
import { getLocale, useAppSelector } from 'store';

export const useIso = (locale?: string) => {
  const { formatMessage } = useIntl();
  const platformLocale = useAppSelector(getLocale);
  const currentLocale = locale ?? platformLocale;
  const languages = {
    ...getNames(locale ?? currentLocale),
    pb: formatMessage({ id: 'languages.pb' }),
  };

  const allLanguages = Object.entries(languages).map(([code, language]) => ({
    label: language,
    value: code,
  }));

  const countries = Object.entries(getCountries(currentLocale)).map(
    ([code, country]) => ({
      label: country,
      value: code,
    }),
  );

  const locales = platformLanguages.map((language) => ({
    label: getName(language, currentLocale) ?? '',
    value: language,
  }));

  return {
    languages: allLanguages,
    countries,
    locales,
  };
};
