import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { TeacherAttributes } from 'types/teacher.types';

const createTeacher = (variables: Partial<TeacherAttributes>) =>
  apiService.createTeacher(variables);

export const useCreateTeacher = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  const { mutate, isLoading } = useMutation(createTeacher, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.teacher.all());

      showNotification({
        message: intl.formatMessage({ id: 'teacher.create.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),
        variant: 'error',
      });
    },
  });

  return {
    createTeacher: mutate,
    isLoading,
  };
};
