import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { isNil, omitBy } from 'lodash';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { isOrderer } from 'utils/user.utils';

import { BROWSER_TIMEZONE, TIMEZONE_OPTIONS } from 'constants/timezones';
import { useIso, useTrackEvent } from 'hooks';
import { useUpdateUser, useUser, useUserById } from 'queries';
import { UpdateUserParams } from 'types/user.types';

import { BottomBar } from 'components/@common';
import { Form, FormAutocomplete, FormTextField } from 'components/@form';
import { OrdererFields } from 'components/@profile/ProfileSettings';
import { LoadingState } from 'components/@states';

import { ChangePasswordDialog } from '../ChangePasswordDialog';

const defaultValues: UpdateUserParams = {
  givenName: '',
  familyName: '',
  email: '',
  locale: '',
  language: '',
  billing: {
    country: '',
    number: '',
    street: '',
    zipcode: '',
    vatNumber: '',
    city: '',
    phoneNumber: '',
  },
  company: '',
  timezone: BROWSER_TIMEZONE,
};

const UserProfileForm = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { data: cognitoUser, isLoading } = useUser();
  const userId = cognitoUser?.getUsername() ?? '';
  const trackEvent = useTrackEvent();
  const { data: user } = useUserById(userId, {
    enabled: !!cognitoUser,
  });

  const { languages, locales } = useIso(user?.locale);

  const { updateUser, isLoading: isUpdating } = useUpdateUser();

  const handleSubmit = (data: UpdateUserParams) => {
    const { billing, timezone, ...rest } = data;

    let dataWithoutEmptyValues: UpdateUserParams = omitBy(rest, isNil);
    const billingWithoutEmptyValues = omitBy(billing, isNil);

    // remove null values and undefined values
    if (!!Object.keys(billingWithoutEmptyValues).length) {
      dataWithoutEmptyValues = {
        ...dataWithoutEmptyValues,
        billing: billingWithoutEmptyValues,
      };
    }

    updateUser(
      {
        id: userId,
        attributes: dataWithoutEmptyValues,
      },
      {
        onSuccess: () => {
          trackEvent({ event: 'UPDATE_PROFILE', withRole: true });
        },
      },
    );
  };

  if (isLoading || !user) return <LoadingState />;

  const { givenName, familyName, email, locale, billing, company, language } =
    user;

  const filteredBilling = omitBy(billing, isNil);

  const values = omitBy(
    {
      givenName,
      familyName,
      email,
      locale,
      company,
      language,
      billing: !!Object.keys(filteredBilling).length
        ? filteredBilling
        : undefined,
    },
    isNil,
  );

  return (
    <>
      <Container>
        <Box mt={16} mb={30} width={{ lg: '50%' }}>
          <Typography mb={10} variant="h3">
            <FormattedMessage id="profile.title" />
          </Typography>
          <Form<UpdateUserParams>
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            values={values}
            unsavedChanges
          >
            <Box mb={10}>
              <Stack gap={4} mb={4} direction="column">
                <Typography variant="h5">
                  <FormattedMessage id="profile.info.title" />
                </Typography>
                <FormTextField
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.given_name' })}
                  name="givenName"
                />
                <FormTextField
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.family_name' })}
                  name="familyName"
                />
                <FormTextField
                  required
                  disabled
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.email' })}
                  name="email"
                />
                <FormAutocomplete
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.mother_tongue' })}
                  name="language"
                  options={languages}
                />
                <Box>
                  <Button onClick={() => setOpen(true)} variant="outlined">
                    <FormattedMessage id="label.change_password" />
                  </Button>
                </Box>
              </Stack>
            </Box>
            {isOrderer(cognitoUser) && <OrdererFields />}
            <Stack gap={4} my={10} direction="column">
              <Typography variant="h5">
                <FormattedMessage id="profile.settings.title" />
              </Typography>
              <FormAutocomplete
                required
                disabled
                name="timezone"
                noOptionsText={intl.formatMessage({
                  id: 'timezone.select.empty',
                })}
                label={intl.formatMessage({ id: 'label.timezone' })}
                options={TIMEZONE_OPTIONS}
              />
              <FormAutocomplete
                required
                rules={{
                  required: {
                    value: true,
                    message: 'field.error.required',
                  },
                }}
                label={intl.formatMessage({ id: 'label.main_language' })}
                name="locale"
                options={locales}
              />
            </Stack>
            <BottomBar>
              <LoadingButton
                loading={isUpdating}
                type="submit"
                variant="contained"
              >
                <FormattedMessage id="label.save_changes" />
              </LoadingButton>
            </BottomBar>
          </Form>
        </Box>
      </Container>
      <ChangePasswordDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default UserProfileForm;
