import {
  Components,
  stepIconClasses,
  stepLabelClasses,
  Theme,
} from '@mui/material';

export const MuiStepLabel: Components<Theme>['MuiStepLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral[800],
      [`&.${stepLabelClasses.active}, &.${stepLabelClasses.completed}`]: {
        color: theme.palette.yellow[200],
      },
    }),
    label: ({ theme }) => ({
      color: theme.palette.neutral[600],
      fontSize: 14,
      [`&.${stepLabelClasses.active}, &.${stepLabelClasses.completed}`]: {
        fontWeight: 400,
        color: theme.palette.neutral[800],
      },
    }),
  },
};

export const MuiStepIcon: Components<Theme>['MuiStepIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral[500],
      width: 24,
      height: 24,
      [`&.${stepIconClasses.active}, &.${stepIconClasses.completed}`]: {
        color: theme.palette.yellow[200],

        [`& .${stepIconClasses.text}`]: {
          fill: theme.palette.neutral[800],
        },
      },
    }),
    text: {
      fontSize: 12,
    },
  },
};

export const MuiStepConnector: Components<Theme>['MuiStepConnector'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.grey[400],
      margin: theme.spacing(0, 2),
    }),
  },
};

export const MuiStep: Components<Theme>['MuiStep'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
