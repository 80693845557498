import { Components, Theme } from '@mui/material';

export const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  defaultProps: {
    thickness: 4,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral[800],
    }),
  },
};
