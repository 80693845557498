export enum Routes {
  Root = '/',
  NotFound = '*',
  Onboarding = '/onboarding',

  // generic routes
  Login = '/login',
  Register = '/register',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/forgot-password/:token',
  NewPassword = '/new-password',

  // Availabilities
  Availabilities = '/availabilities',
  DefaultAvailabilities = 'default', // Child route of /availabilities which points to /availabilities/default

  // Orders
  Orders = '/orders',

  // Courses
  Courses = '/courses',
  CourseDetail = ':courseId',
  PlanCourse = ':courseId/plan',
  StudentCourses = '/courses/student',
  CoursePlanned = ':courseId/planned',
  EditLesson = ':courseId/lesson/:lessonId/edit',
  EditLessonOverview = ':courseId/lesson/:lessonId/overview',
  EvaluateLesson = ':courseId/lesson/:lessonId/evaluate',
  EvaluateCourse = ':courseId/evaluate',
  ChangeTeacher = ':courseId/change-teacher',

  // Users
  Users = '/users',
  UserDetail = ':userId',
  AddUser = 'add',

  // Profile
  Profile = '/profile',

  // Teachers
  TeacherDetail = '/teachers/:teacherId',

  // Timesheets
  Timesheets = '/timesheets',
  TimesheetDetail = ':teacherId/:timesheetId',
}
