import { checkboxClasses, Components, Theme } from '@mui/material';

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
    disableFocusRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.neutral[400],
      height: 56,
      [`&.${checkboxClasses.checked}`]: {
        color: theme.palette.neutral[800],
        borderColor: theme.palette.neutral[800],
      },
      '&:hover': {
        backgroundColor: 'none',
      },
      '&& svg': {
        width: 20,
        height: 20,
      },
    }),
  },
};
