import { useMedia } from 'hooks';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { CommonProps } from './types';

const SpecificAvailabilities: React.FC<CommonProps> = ({
  teacherId,
  isAdmin,
}) => {
  const { isDesktop } = useMedia();

  return !isDesktop ? (
    <Mobile teacherId={teacherId} isAdmin={isAdmin} />
  ) : (
    <Desktop teacherId={teacherId} isAdmin={isAdmin} />
  );
};

export default SpecificAvailabilities;
