import { Auth, CognitoUser } from '@aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { getPrimaryRole } from 'utils/user.utils';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';

const fetchUser = async () => {
  const data: CognitoUser = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  return data;
};

export const useUser = (
  config: UseCommonQueryOptions<CognitoUser, string> = {},
) => {
  const { data, status, isLoading, error, isFetching } = useQuery(
    QueryKeys.user.me(),
    fetchUser,
    {
      ...config,
      retry: (_, err) => err !== 'The user is not authenticated',
      staleTime: config?.staleTime ?? StaleTime.FOREVER,
      onSuccess: (data) => {
        posthog.identify(data.getUsername(), {
          user_role: getPrimaryRole(data),
        });
      },
    },
  );

  return {
    data,
    userId: data?.getUsername() ?? '',
    status,
    isLoading,
    error,
    isFetching,
  };
};
