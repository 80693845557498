import { LoadingButton } from '@mui/lab';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfISOWeek,
  format,
  getISOWeek,
  getYear,
  isAfter,
  startOfISOWeek,
  subWeeks,
} from 'date-fns';
import { groupBy } from 'lodash';
import { startTransition, useCallback, useState } from 'react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  parseSpecificAvailabilities,
  parseTimeslotStatuses,
} from 'utils/availability.utils';
import { isTimeslotDisabled } from 'utils/timeslot.utils';

import { useTrackEvent } from 'hooks';
import {
  QueryKeys,
  useAvailabilitiesByWeek,
  useDefaultAvailabilities,
  useUpdateAvailabilitiesByWeek,
} from 'queries';
import { AvailabilityStatus, Timeslot } from 'types/availabilities.types';

import { Routes } from 'pages/routes.constants';

import { BottomBar, Icon } from 'components/@common';
import { DatepickerButton } from 'components/@datepicker';
import { UnsavedChangesDialog } from 'components/@dialog';
import { Emptystate, LoadingState } from 'components/@states';
import { TimeslotTableDesktop } from 'components/@timeslots';

import { useAvailabilities } from '../AvailabilitiesProvider';
import { CommonProps } from './types';
import { getAvailableAmount } from './utils';

const Desktop: React.FC<CommonProps> = ({ teacherId, isAdmin }) => {
  const today = new Date();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const trackEvent = useTrackEvent();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(today);
  const startOfWeek = startOfISOWeek(currentDate);
  const endOfWeek = endOfISOWeek(currentDate);
  const year = getYear(currentDate);
  const week = getISOWeek(currentDate);
  const fetching = useIsFetching();
  const isFetchingCurrentWeek = useIsFetching(
    QueryKeys.availabilities.byWeek(teacherId, { week, year }),
  );

  const {
    data: defaultAvailabilities,
    isFetching: isFetchingDefault,
    isLoading: isLoadingDefault,
  } = useDefaultAvailabilities(teacherId, {
    retry: (_, error) => (error as AxiosError).response?.status !== 404,
  });

  const {
    data,
    meta,
    prefetchAvailabilities,
    isFetching: isFetchingSpecific,
  } = useAvailabilitiesByWeek(
    teacherId,
    { week, year },
    { enabled: !!defaultAvailabilities },
  );

  const {
    updateAvailabilitiesByWeek,
    isLoading: isUpdating,
    isPaused,
  } = useUpdateAvailabilitiesByWeek();

  const {
    selectedTimeslots,
    setTimeslots,
    toggleTimeslot,
    clearTimeslots,
    resetAvailabilities,
    resetToDefault,
  } = useAvailabilities('specific');

  const parseCurrentWeek = useCallback(
    () =>
      `${format(startOfWeek, 'd MMM')} - ${format(
        endOfWeek,
        'd MMM',
      )} ${year}`.toLowerCase(),
    [endOfWeek, startOfWeek, year],
  );

  const groupedSpecificTimeslots = groupBy(data?.timeslots || [], 'date');

  const groupedDefaultTimeslots = groupBy(
    defaultAvailabilities?.defaultAvailabilityTimeslots,
    'index',
  );

  const handleRoute = () => navigate(Routes.DefaultAvailabilities);

  const handleUpdate = async () => {
    if (data) {
      updateAvailabilitiesByWeek(
        {
          id: teacherId,
          params: {
            days: parseSpecificAvailabilities(selectedTimeslots),
          },
        },
        {
          onSuccess: () => {
            setUnsavedChanges(false);
          },
        },
      );
    }
  };

  const handleNext = async () => {
    const maxDate = addMonths(new Date(), 3);

    const nextWeek = addWeeks(currentDate, 1);
    const prefetchWeek = addWeeks(currentDate, 2);
    if (!isAfter(nextWeek, maxDate)) {
      setCurrentDate(nextWeek);

      const nextWeekData = await prefetchAvailabilities({
        week: getISOWeek(prefetchWeek),
        year: Number(year),
      });

      startTransition(() => {
        if (nextWeekData) {
          setTimeslots(parseTimeslotStatuses(nextWeekData.data.timeslots));
        }
      });
    }
  };

  const handlePrevious = async () => {
    const prevWeek = subWeeks(currentDate, 1);
    const prefetchWeek = subWeeks(currentDate, 2);

    setCurrentDate(prevWeek);

    const prevWeekData = await prefetchAvailabilities({
      week: getISOWeek(prefetchWeek),
      year: getYear(prefetchWeek),
    });

    startTransition(() => {
      if (prevWeekData) {
        setTimeslots(parseTimeslotStatuses(prevWeekData.data.timeslots));
      }
    });
  };

  const handleSelectTimeslot = (day: string, timeslot: Timeslot) => {
    setUnsavedChanges(true);
    toggleTimeslot(day, timeslot.timeStart);
  };

  const handleClearTimeslots = (day: string) => {
    setUnsavedChanges(true);
    clearTimeslots(day);
  };

  const handleSetDefault = (day: string, index: number) => {
    const defaultSelected = groupedSpecificTimeslots[day].map((timeslot) => {
      const { timeStart, status } = timeslot;
      const defaultTimeslotStatus = groupedDefaultTimeslots[index].find(
        (slot) => slot.timeStart === timeStart,
      )?.status;

      if (
        status !== AvailabilityStatus.BOOKED &&
        defaultTimeslotStatus &&
        isTimeslotDisabled(day, timeStart)
      ) {
        return { ...timeslot, status: defaultTimeslotStatus };
      }

      return timeslot;
    });

    resetToDefault(day, defaultSelected, defaultAvailabilities);
  };

  const days = Array.from(Array(7)).map((_, index) => {
    if (!index) return format(startOfWeek, 'yyyy-MM-dd');

    const nextDay = addDays(startOfWeek, index);

    return format(nextDay, 'yyyy-MM-dd');
  });

  const totalSelected = getAvailableAmount(currentDate, selectedTimeslots);

  const handleConfirmUnsavedChanges = () => {
    setUnsavedChanges(false);
    resetAvailabilities();
    queryClient.invalidateQueries(QueryKeys.availabilities.byWeek(teacherId));
    return true;
  };

  const handleSettingsClick = () => {
    trackEvent({ event: 'AVAILABILITIES_SETTINGS' });
    navigate(Routes.DefaultAvailabilities);
  };

  if (isFetchingSpecific || isLoadingDefault)
    return (
      <LoadingState
        text={intl.formatMessage({
          id: 'specific_availabilities.loading.text',
        })}
      />
    );

  if (!data && !isFetchingSpecific && isAdmin)
    return (
      <Emptystate
        icon="IcoNoAvailability"
        title={intl.formatMessage({
          id: 'availabilities.specific.admin.empty.title',
        })}
        description={intl.formatMessage({
          id: 'availabilities.specific.admin.empty.description',
        })}
      />
    );

  if (!data && !isFetchingSpecific)
    return (
      <Box pt={{ xs: 6, md: 16 }}>
        <Typography variant="h3">
          <FormattedMessage id="navigation.link.availabilities" />
        </Typography>
        <Emptystate
          icon="IcoNoAvailability"
          title={intl.formatMessage({
            id: 'page.availabilities.no_availabilities.title',
          })}
          description={intl.formatMessage({
            id: 'page.availabilities.no_availabilities.description',
          })}
          buttonText="page.availabilities.no_availabilities.button.text"
          onClick={handleRoute}
        />
      </Box>
    );

  return (
    <>
      <Stack direction="column" flex={1} height="100%" mt={16}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          mb={{ xs: 4, lg: 10 }}
        >
          <Box>
            <Typography variant="h3" display={{ lg: 'none' }}>
              <FormattedMessage id="navigation.link.availabilities" />
            </Typography>
            <Stack
              mb={2}
              gap={4}
              display={{ xs: 'none', lg: 'flex' }}
              alignItems="center"
            >
              <Typography variant="h3">{parseCurrentWeek()}</Typography>
              <Chip
                label={intl.formatMessage(
                  { id: 'label.week.current_week' },
                  {
                    currentWeek: getISOWeek(currentDate),
                  },
                )}
              />
            </Stack>
            <Typography mb={2}>
              <FormattedMessage
                id="availabilities.summary"
                values={{
                  available: totalSelected,
                  planned: meta?.bookedTimeslots ?? 0,
                  free: totalSelected - (meta?.bookedTimeslots ?? 0),
                }}
              />
            </Typography>
            <Typography
              fontStyle="italic"
              display={{ xs: 'none', lg: 'block' }}
            >
              <FormattedMessage
                id="page.availabilities.specific.planned_classes"
                values={{
                  amount: data?.maxBookedSlots,
                }}
              />
            </Typography>
          </Box>
          <Stack
            gap={4}
            alignItems="center"
            display={{ xs: 'none', lg: 'flex' }}
          >
            <DatepickerButton
              type="week"
              disabled={!!fetching}
              date={currentDate}
              onChange={setCurrentDate}
              onPrevious={handlePrevious}
              onNext={handleNext}
            />
            {!isAdmin && (
              <IconButton onClick={handleSettingsClick} color="secondary">
                <Icon name="IcoSettings" />
              </IconButton>
            )}
          </Stack>
        </Stack>
        <TimeslotTableDesktop
          type="specific"
          isLoading={isFetchingDefault || !!isFetchingCurrentWeek}
          disablePast
          days={days}
          selectedTimeslots={selectedTimeslots}
          onSelectTimeslot={handleSelectTimeslot}
          onRemoveSelected={handleClearTimeslots}
          onSetDefault={handleSetDefault}
        />
        <BottomBar>
          <LoadingButton
            variant="contained"
            loading={isUpdating && !isPaused}
            onClick={handleUpdate}
          >
            <FormattedMessage id="label.save_changes" />
          </LoadingButton>
        </BottomBar>
      </Stack>
      <UnsavedChangesDialog
        when={unsavedChanges}
        onConfirm={handleConfirmUnsavedChanges}
      />
    </>
  );
};

export default React.memo(Desktop);
