import { Container, Stack } from '@mui/material';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const BottomBar: React.FC<Props> = ({ children }) => {
  return (
    <Stack
      sx={(theme) => ({
        position: 'fixed',
        background: theme.palette.neutral[100],
        boxShadow: theme.shadows[10],
        padding: theme.spacing(4, 0),
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      })}
    >
      <Container>
        <Stack gap={4} direction={{ xs: 'column-reverse', lg: 'row' }}>
          {children}
        </Stack>
      </Container>
    </Stack>
  );
};

export default BottomBar;
