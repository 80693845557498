import { useEffect, useState } from 'react';

import { useRequestPresignedUrl, useUploadFile } from 'queries';

export const useProfilePicture = (initialPicture?: string | File | null) => {
  const [profilePicture, setProfilePicture] = useState<string | File>();

  useEffect(() => {
    if (initialPicture) {
      setProfilePicture(initialPicture);
    }
  }, [initialPicture]);

  const { requestPresignedUrl, isLoading: isRequesting } =
    useRequestPresignedUrl();
  const { uploadFile, isLoading: isUploading } = useUploadFile();
  const uploadProfilePicture = async () => {
    if (typeof profilePicture === 'string') return profilePicture;

    if (!profilePicture) return '';

    try {
      if (profilePicture && typeof profilePicture !== 'string') {
        const data = await requestPresignedUrl({
          context: 'PROFILE_PICTURE',
        });

        if (data) {
          await uploadFile({ ...data.signed_url, file: profilePicture });
          return `${data.path}?v=${Date.now()}`;
        }
      }
    } catch {
      return '';
    }
  };

  return {
    uploadProfilePicture,
    setProfilePicture,
    profilePicture,
    isLoading: isRequesting || isUploading,
  };
};
