import { alpha, checkboxClasses, Components, Theme } from '@mui/material';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    color: 'primary',
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderColor: theme.palette.neutral[800],
      padding: 12,
      [`&.${checkboxClasses.checked}`]: {
        color: theme.palette.neutral[800],
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.neutral[800], 0.04),
      },
      '&& svg': {
        width: 18,
        height: 18,
      },

      [`&.${checkboxClasses.disabled}`]: {
        opacity: 0.4,
      },
    }),
  },
};
