import { Components, containerClasses, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 80,
      justifyContent: 'center',
      backgroundColor: 'transparent',
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        borderBottom: `1px solid ${theme.palette.neutral[400]}`,
        height: 56,
      },

      [`& .${containerClasses.root}`]: {
        padding: 0,
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(0, 5),
        },
      },
    }),
  },
};
