import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification, useTrackEvent } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { intlActions, useAppDispatch } from 'store';
import { Teacher, UpdateTeacherParams } from 'types/teacher.types';

interface Variables {
  id: Teacher['id'];
  attributes: UpdateTeacherParams;
}

const updateTeacher = async ({ id, attributes }: Variables) => {
  const { data } = await apiService.updateTeacher(id, attributes);

  return data;
};

export const useUpdateTeacher = (params?: { admin: boolean }) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const trackEvent = useTrackEvent();
  const { showNotification } = useNotification();
  const { mutateAsync, isLoading } = useMutation(updateTeacher, {
    onSuccess: (_, { id, attributes }) => {
      trackEvent({ event: 'UPDATE_PROFILE', withRole: true });
      queryClient.invalidateQueries(QueryKeys.teacher.byId(id));
      queryClient.invalidateQueries(QueryKeys.teacher.all());

      if (attributes.locale && !params?.admin) {
        dispatch(intlActions.LOCALE_UPDATE(attributes.locale));
      }

      showNotification({
        message: intl.formatMessage({ id: 'teacher.update.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return { updateTeacher: mutateAsync, isLoading };
};
