import React from 'react';

export default {
  LogoMark: React.lazy(() => import('./LogoMark')),
  IcoArrowDiagonalDown: React.lazy(() => import('./IcoArrowDiagonalDown')),
  IcoArrowDiagonalUp: React.lazy(() => import('./IcoArrowDiagonalUp')),
  IcoArrowLeft: React.lazy(() => import('./IcoArrowLeft')),
  IcoArrowRight: React.lazy(() => import('./IcoArrowRight')),
  IcoArrow: React.lazy(() => import('./IcoArrow')),
  IcoCalendarCheck: React.lazy(() => import('./IcoCalendarCheck')),
  IcoCalendar: React.lazy(() => import('./IcoCalendar')),
  IcoCart: React.lazy(() => import('./IcoCart')),
  IcoCheck: React.lazy(() => import('./IcoCheck')),
  IcoChevronDown: React.lazy(() => import('./IcoChevronDown')),
  IcoChevronLeft: React.lazy(() => import('./IcoChevronLeft')),
  IcoChevronRight: React.lazy(() => import('./IcoChevronRight')),
  IcoCircleCheckActive: React.lazy(() => import('./IcoCircleCheckActive')),
  IcoCircleCheckDefault: React.lazy(() => import('./IcoCircleCheckDefault')),
  IcoClose: React.lazy(() => import('./IcoClose')),
  IcoComment: React.lazy(() => import('./IcoComment')),
  IcoCopy: React.lazy(() => import('./IcoCopy')),
  IcoDots: React.lazy(() => import('./IcoDots')),
  IcoDownload: React.lazy(() => import('./IcoDownload')),
  IcoEvaluation: React.lazy(() => import('./IcoEvaluation')),
  IcoExternal: React.lazy(() => import('./IcoExternal')),
  IcoEye: React.lazy(() => import('./IcoEye')),
  IcoFlagOutlined: React.lazy(() => import('./IcoFlagOutlined')),
  IcoFlag: React.lazy(() => import('./IcoFlag')),
  IcoGlobe: React.lazy(() => import('./IcoGlobe')),
  IcoHourglassFilled: React.lazy(() => import('./IcoHourglassFilled')),
  IcoHourglass: React.lazy(() => import('./IcoHourglass')),
  IcoInfo: React.lazy(() => import('./IcoInfo')),
  IcoLogout: React.lazy(() => import('./IcoLogout')),
  IcoMail: React.lazy(() => import('./IcoMail')),
  IcoMenu: React.lazy(() => import('./IcoMenu')),
  IcoNoAvailability: React.lazy(() => import('./IcoNoAvailability')),
  IcoNoLessons: React.lazy(() => import('./IcoNoLessons')),
  IcoNoPlannedLessons: React.lazy(() => import('./IcoNoPlannedLessons')),
  IcoNoProfilePicture: React.lazy(() => import('./IcoNoProfilePicture')),
  IcoNotFound: React.lazy(() => import('./IcoNotFound')),
  IcoPencil: React.lazy(() => import('./IcoPencil')),
  IcoPhone: React.lazy(() => import('./IcoPhone')),
  IcoPlus: React.lazy(() => import('./IcoPlus')),
  IcoSearch: React.lazy(() => import('./IcoSearch')),
  IcoSettings: React.lazy(() => import('./IcoSettings')),
  IcoTrash: React.lazy(() => import('./IcoTrash')),
  IcoUser: React.lazy(() => import('./IcoUser')),
  ImgScribbleCircle01: React.lazy(() => import('./ImgScribbleCircle01')),
  ImgScribbleCircle02: React.lazy(() => import('./ImgScribbleCircle02')),
  ImgScribbleDoubleLine: React.lazy(() => import('./ImgScribbleDoubleLine')),
  ImgScribbleLine: React.lazy(() => import('./ImgScribbleLine')),
  ImgScribbleWave: React.lazy(() => import('./ImgScribbleWave')),
  LogoBlack: React.lazy(() => import('./LogoBlack')),
  LogoWhite: React.lazy(() => import('./LogoWhite')),
};
