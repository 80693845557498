import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import {
  GetAvailabilitiesByMonthParams,
  GetAvailabilitiesByMonthResponse,
} from 'types/availabilities.types';

const getMonthAvailabilitiesBySkill = async (
  skill: string,
  params: GetAvailabilitiesByMonthParams,
) => {
  const { data } = await apiService.getMonthAvailabilitiesBySkill(
    skill,
    params,
  );

  return data;
};

export const useMonthAvailabilitiesBySkill = (
  skill: string,
  params: GetAvailabilitiesByMonthParams,
  config: UseCommonQueryOptions<GetAvailabilitiesByMonthResponse> = {},
) => {
  const { data, isLoading, status, isFetching } = useQuery(
    QueryKeys.availabilities.bySkill.month(skill, params),
    () => getMonthAvailabilitiesBySkill(skill, params),
    { ...config, staleTime: 0 },
  );

  return {
    data: data || ([] as GetAvailabilitiesByMonthResponse),
    isLoading,
    isFetching,
    status,
  };
};
