import { Components, Theme } from '@mui/material';

export const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(!!ownerState.onClick && {
        cursor: 'pointer',
      }),
    }),
    hover: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[200],
    }),
  },
};
