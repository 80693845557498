import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Lesson } from 'types/lesson.types';
import { Teacher } from 'types/teacher.types';

export type EnhancedLesson = Lesson & {
  teacher: Teacher;
};

export interface LessonsState {
  data: Record<Lesson['id'], EnhancedLesson>;
}

const initialState: LessonsState = {
  data: {},
};

const lessonsSlice = createSlice({
  name: 'match-lessons',
  initialState,
  reducers: {
    ADD_LESSON: (state, { payload }: PayloadAction<EnhancedLesson>) => {
      state.data = {
        ...state.data,
        [payload.id]: payload,
      };
    },
    UPDATE_LESSON: (
      state,
      {
        payload,
      }: PayloadAction<{
        lessonId: Lesson['id'];
        data: Partial<EnhancedLesson>;
      }>,
    ) => {
      state.data[payload.lessonId] = {
        ...state.data[payload.lessonId],
        ...payload.data,
      };
    },
    REMOVE_LESSON: (state, { payload }: PayloadAction<Lesson['id']>) => {
      delete state.data[payload];
    },
  },
});

export const { actions: lessonsActions } = lessonsSlice;

export const lessonsReducer = lessonsSlice.reducer;
