import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Course } from 'types/course.types';
import { Teacher, TeacherEvaluation } from 'types/teacher.types';

const getTeacherEvaluation = async (
  id: Teacher['id'],
  courseId: Course['id'],
) => {
  const { data } = await apiService.getTeacherEvaluation(id, courseId);

  return deserialize<TeacherEvaluation>(data);
};

export const useTeacherEvaluation = (
  id: Teacher['id'],
  courseId: Course['id'],
  config?: UseCommonQueryOptions<DeserializedResponse<TeacherEvaluation>>,
) => {
  const { data, isLoading, isError } = useQuery(
    QueryKeys.teacher.evaluation(id, courseId),
    () => getTeacherEvaluation(id, courseId),
    {
      ...config,
      staleTime: StaleTime.FIFTEEN_MIN,
    },
  );

  return { data: data?.data, isLoading, isError };
};
