import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMedia, useTrackEvent } from 'hooks';
import { QueryKeys, useUser } from 'queries';
import { AvailabilityStatus, Timeslot } from 'types/availabilities.types';

import { useAvailabilities } from 'components/@availabilities/AvailabilitiesProvider';
import { days } from 'components/@availabilities/constants';
import { BottomBar, Icon, InfoDialog } from 'components/@common';
import { UnsavedChangesDialog } from 'components/@dialog';
import { useMultistep } from 'components/@steps';
import {
  TimeslotTableDesktop,
  TimeslotTableMobile,
} from 'components/@timeslots';

const DefaultAvailabilities: FC = () => {
  const intl = useIntl();

  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const trackEvent = useTrackEvent();
  const { isDesktop } = useMedia();
  const { proceed } = useMultistep();

  const {
    toggleTimeslot,
    clearTimeslots,
    selectedTimeslots,
    resetAvailabilities,
    unsavedChanges,
    setUnsavedChanges,
  } = useAvailabilities('default');

  const handleSelectTimeslot = (day: string | number, timeslot: Timeslot) => {
    setUnsavedChanges(true);
    toggleTimeslot(day, timeslot.timeStart);
  };

  const handleClearTimeslots = (day: number) => {
    setUnsavedChanges(true);
    clearTimeslots(String(day));
  };

  const handleUpdate = () => proceed();

  const handleConfirmUnsavedChanges = () => {
    setUnsavedChanges(false);
    resetAvailabilities();
    queryClient.invalidateQueries(
      QueryKeys.availabilities.default(user?.getUsername() ?? ''),
      { refetchType: 'none' },
    );
    return true;
  };

  const handleNextDay = () => {
    if (index === days.length - 1) {
      setIndex(0);
    } else {
      setIndex((state) => state + 1);
    }
  };

  const handlePrevDay = () => {
    if (index === 0) {
      setIndex(days.length - 1);
    } else {
      setIndex((state) => state - 1);
    }
  };

  const totalSelected = Object.values(selectedTimeslots).reduce(
    (acc, curr) =>
      acc +
      Object.values(curr).filter((t) => t === AvailabilityStatus.AVAILABLE)
        .length,
    0,
  );

  const totalSelectedByDay = !!selectedTimeslots[index]
    ? Object.values(selectedTimeslots[index]).reduce(
        (acc, curr) => acc + (curr === AvailabilityStatus.AVAILABLE ? 1 : 0),
        0,
      )
    : 0;

  const handleOpenInfoDialog = () => {
    trackEvent({ event: 'AVAILABILITIES_INFO' });
    setDialogOpen(true);
  };

  return (
    <Box
      overflow={{ xs: 'hidden', lg: 'initial' }}
      height={{ xs: 'calc(100vh - 80px)', lg: 'auto' }}
    >
      <Stack direction="column" flex={1} height="100%">
        <Box mb={{ xs: 4, lg: 10 }}>
          <Stack justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h3">
              <FormattedMessage id="page.availabilities.default.step.timeslots.title" />
            </Typography>
            {isDesktop ? (
              <Button
                sx={{ display: { xs: 'none', lg: 'flex' } }}
                onClick={handleOpenInfoDialog}
                endIcon={
                  <Avatar variant="outlined">
                    <Icon name="IcoCalendar" />
                  </Avatar>
                }
              >
                <FormattedMessage id="page.availabilities.default.step.timeslots.info" />
              </Button>
            ) : (
              <IconButton color="secondary" onClick={handleOpenInfoDialog}>
                <Icon name="IcoCalendar" />
              </IconButton>
            )}
          </Stack>

          <Typography variant="body2">
            {!isDesktop ? (
              <FormattedMessage
                id="availabilities.amount_available.mobile"
                values={{ amount: totalSelectedByDay, total: totalSelected }}
              />
            ) : (
              <FormattedMessage
                id="availabilities.amount_available.desktop"
                values={{ amount: totalSelected }}
              />
            )}
          </Typography>
        </Box>
        {!isDesktop ? (
          <TimeslotTableMobile
            type="default"
            selectedTimeslots={selectedTimeslots[index]}
            onSelectTimeslot={(day, timeslot) =>
              handleSelectTimeslot(days.indexOf(String(day)), timeslot)
            }
            onNextDay={handleNextDay}
            onPrevDay={handlePrevDay}
            currentDay={days[index]}
            onSetDay={(day: number) => setIndex(day)}
          />
        ) : (
          <TimeslotTableDesktop
            days={days.map((day) =>
              intl.formatMessage({ id: `label.day.${day}` }),
            )}
            type="default"
            selectedTimeslots={selectedTimeslots}
            onSelectTimeslot={handleSelectTimeslot}
            onClear={handleClearTimeslots}
          />
        )}

        <BottomBar>
          <Button variant="contained" onClick={handleUpdate}>
            <FormattedMessage id="label.proceed" />
          </Button>
        </BottomBar>
      </Stack>
      <InfoDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={intl.formatMessage({ id: 'dialog.availabilities.info.title' })}
        description={intl.formatMessage({
          id: 'dialog.availabilities.info.item_one.description',
        })}
      />
      <UnsavedChangesDialog
        onConfirm={handleConfirmUnsavedChanges}
        when={unsavedChanges}
      />
    </Box>
  );
};

export default React.memo(DefaultAvailabilities);
