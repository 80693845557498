import { Components, tabClasses, Theme } from '@mui/material';

export const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: 'none',
      color: theme.palette.neutral[600],
      fontWeight: 400,
      [`&.${tabClasses.selected}`]: {
        color: theme.palette.neutral[800],
      },
    }),
  },
};
