import { JsonApiArrayResponse, JsonApiResponse } from 'deserialize-json-api';

import { getTimezoneHeader } from 'utils/headers.utils';

import {
  Course,
  CourseCertificate,
  CourseEvaluation,
  CourseMetaData,
  CoursesMeta,
  CourseUpdateParams,
  GetAllCoursesParams,
  GetCourseParams,
  GetCoursesFromUserParams,
  GetCoursesParams,
} from 'types/course.types';
import { Resources } from 'types/jsonapi.types';

import RestApiService from './';

export default {
  getCourse(this: RestApiService, { id, timezone }: GetCourseParams) {
    return this.api.get<JsonApiResponse<Course>>(`/courses/${id}`, {
      headers: getTimezoneHeader(timezone),
    });
  },
  getCourses(this: RestApiService, params: GetCoursesParams) {
    return this.api.get<JsonApiArrayResponse<Course, CoursesMeta>>(
      '/user/courses',
      {
        params,
      },
    );
  },
  updateCourse(
    this: RestApiService,
    courseId: string,
    params?: CourseUpdateParams,
  ) {
    return this.api.patch(`/courses/${courseId}`, {
      data: { type: Resources.COURSES, attributes: params },
    });
  },
  getCoursesFromAuthenticatedUser(
    this: RestApiService,
    params: GetCoursesFromUserParams,
  ) {
    return this.api.get<JsonApiArrayResponse<Course, CourseMetaData>>(
      `/user/courses`,
      {
        params,
      },
    );
  },
  getAllCourses(this: RestApiService, params: GetAllCoursesParams) {
    return this.api.get<JsonApiArrayResponse<Course, CourseMetaData>>(
      '/courses',
      {
        params,
      },
    );
  },
  getCourseCertificate(this: RestApiService, courseId: Course['id']) {
    return this.api.get<JsonApiResponse<CourseCertificate>>(
      `/courses/${courseId}/certificate`,
    );
  },
  getCourseEvaluation(this: RestApiService, courseId: Course['id']) {
    return this.api.get<JsonApiResponse<CourseEvaluation>>(
      `/courses/${courseId}/evaluation`,
    );
  },
  updateCourseEvaluation(
    this: RestApiService,
    courseId: Course['id'],
    attributes: CourseEvaluation,
  ) {
    return this.api.put(`/courses/${courseId}/evaluation`, {
      data: { type: Resources.EVALUATIONS, attributes },
    });
  },
};
