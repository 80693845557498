import { useInfiniteQuery } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { isTeacher } from 'utils/user.utils';

import { useTeacher, useUser } from 'queries';
import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonInfiniteQueryOptions } from 'queries/types';
import { getInfiniteData, getNextPageParam } from 'queries/utils';
import { apiService } from 'services';
import { GetLessonsParams, Lesson } from 'types/lesson.types';

const getLessons = async (courseId: string, params: GetLessonsParams) => {
  const { data } = await apiService.getLessons(courseId, params);
  return deserialize<Lesson>(data);
};

export const useLessons = (
  courseId: string,
  params: GetLessonsParams,
  config?: UseCommonInfiniteQueryOptions<DeserializedArrayResponse<Lesson>>,
) => {
  const { data: user } = useUser();
  const userId = user?.getUsername() ?? '';

  const { data: teacher } = useTeacher(userId, {
    enabled: !!user && isTeacher(user),
  });

  const timezone = isTeacher(user)
    ? teacher?.timezone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;

  const paramsWithTz = { ...params, timezone };

  const { data, isLoading, hasNextPage, fetchNextPage, isFetching } =
    useInfiniteQuery(
      QueryKeys.lessons.all(courseId, paramsWithTz),
      ({ pageParam = paramsWithTz }) => getLessons(courseId, pageParam),
      {
        ...config,
        staleTime: StaleTime.FIFTEEN_MIN,
        getNextPageParam: (lastPage) =>
          getNextPageParam(lastPage, paramsWithTz),
      },
    );

  return {
    ...getInfiniteData(data),
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  };
};
