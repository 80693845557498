import { Box, styled } from '@mui/material';

export const LogoWrap = styled(Box)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.neutral[800],
    width: '100%',
    height: '100%',
  },
  width: 57,
  height: 32,

  [theme.breakpoints.up('md')]: {
    width: 86,
    height: 48,
    '& svg': {
      fill: theme.palette.neutral[100],
    },
  },
}));
