import { styled } from '@mui/material';

export const ImageWrap = styled('div')({
  width: 48,
  height: 48,
  borderRadius: '50%',
  overflow: 'hidden',
});

export const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});
