import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AuthErrorMessage } from '@aws-amplify/auth/lib-esm/types';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

type Variables = {
  email: string;
  tempPassword: string;
  newPassword: string;
};

const setNewPassword = async ({
  email,
  tempPassword,
  newPassword,
}: Variables) => {
  const data: CognitoUser = await Auth.signIn(email, tempPassword);

  const user: CognitoUser = await Auth.completeNewPassword(data, newPassword);

  return user;
};

export const useSetNewPassword = () => {
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess, error } = useMutation<
    CognitoUser,
    AuthErrorMessage,
    Variables,
    unknown
  >(setNewPassword, {
    onSuccess: () => {
      navigate(Routes.Login);
    },
  });

  return { setNewPassword: mutate, isLoading, isSuccess, error };
};
