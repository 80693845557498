import { JsonApiArrayResponse, JsonApiResponse } from 'deserialize-json-api';

import { PageParams } from 'types/jsonapi.types';
import { Teacher } from 'types/teacher.types';
import {
  GetAllTimesheetsParams,
  GetTimesheetParams,
  Timesheet,
  TimesheetLesson,
  TimesheetPdf,
} from 'types/timesheet.types';

import RestApiService from '.';

export default {
  getTimesheetsByTeacher(
    this: RestApiService,
    teacherId: Teacher['id'],
    params: GetTimesheetParams,
  ) {
    return this.api.get<JsonApiArrayResponse<Timesheet>>(
      `/teachers/${teacherId}/timesheets`,
      {
        params,
      },
    );
  },
  getAllTimesheets(this: RestApiService, params: GetAllTimesheetsParams) {
    return this.api.get<JsonApiArrayResponse<Timesheet>>(
      `/teachers/timesheets`,
      {
        params,
      },
    );
  },
  getTimesheet(
    this: RestApiService,
    teacherId: Teacher['id'],
    timesheetId: Timesheet['id'],
  ) {
    return this.api.get<JsonApiResponse<Timesheet>>(
      `/teachers/${teacherId}/timesheets/${timesheetId}`,
    );
  },
  getTimesheetLessons(
    this: RestApiService,
    teacherId: Teacher['id'],
    timesheetId: Timesheet['id'],
    params?: PageParams,
  ) {
    return this.api.get<JsonApiArrayResponse<TimesheetLesson>>(
      `/teachers/${teacherId}/timesheets/${timesheetId}/lessons`,
      { params },
    );
  },
  approveTimesheet(
    this: RestApiService,
    teacherId: Teacher['id'],
    timesheetId: Timesheet['id'],
  ) {
    return this.api.post(
      `/teachers/${teacherId}/timesheets/${timesheetId}/approve`,
    );
  },
  regenerateTimesheet(
    this: RestApiService,
    teacherId: Teacher['id'],
    timesheetId: Timesheet['id'],
  ) {
    return this.api.post(
      `/teachers/${teacherId}/timesheets/${timesheetId}/regenerate`,
    );
  },
  getTimesheetPdf(
    this: RestApiService,
    teacherId: Teacher['id'],
    timesheetId: Timesheet['id'],
  ) {
    return this.api.get<JsonApiResponse<TimesheetPdf>>(
      `/teachers/${teacherId}/timesheets/${timesheetId}/pdf`,
    );
  },
};
