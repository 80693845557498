import { Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[300],
      fontSize: 14,
    }),
    avatar: ({ theme }) => ({
      width: 24,
      height: 24,
      fontSize: 12,
      background: theme.palette.neutral[400],
      color: theme.palette.neutral[100],
    }),
  },
};
