import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Icon } from 'components/@common';

const NoAvailabilitiesScreen: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate(Routes.DefaultAvailabilities);

  return (
    <Box pt={{ xs: 6, md: 16 }}>
      <Typography variant="h3">
        <FormattedMessage id="navigation.link.availabilities" />
      </Typography>
      <Stack direction="column" alignItems="center" mt={10}>
        <Avatar
          sx={(theme) => ({
            width: 120,
            height: 120,
            backgroundColor: theme.palette.pink[200],
          })}
        >
          <Icon name="IcoNoAvailability" width={48} height={48} />
        </Avatar>
        <Box maxWidth={450} textAlign="center" my={6}>
          <Typography variant="h4" mb={2}>
            <FormattedMessage id="page.availabilities.no_availabilities.title" />
          </Typography>
          <Typography>
            <FormattedMessage id="page.availabilities.no_availabilities.description" />
          </Typography>
        </Box>
        <Button onClick={handleClick} variant="contained">
          <FormattedMessage id="page.availabilities.no_availabilities.button.text" />
        </Button>
      </Stack>
    </Box>
  );
};

export default NoAvailabilitiesScreen;
