import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification, useTrackEvent } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Course } from 'types/course.types';
import { Teacher, TeacherEvaluation } from 'types/teacher.types';

interface Variables {
  id: Teacher['id'];
  courseId: Course['id'];
  attributes: TeacherEvaluation;
}

const evaluateTeacher = ({ id, courseId, attributes }: Variables) =>
  apiService.evaluateTeacher(id, courseId, attributes);

export const useEvaluateTeacher = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const trackEvent = useTrackEvent();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(evaluateTeacher, {
    onSuccess: (_, { id, courseId }) => {
      trackEvent({ event: 'EVALUATIONS_COURSE', withRole: true });

      queryClient.invalidateQueries(QueryKeys.teacher.evaluation(id, courseId));
      queryClient.invalidateQueries(QueryKeys.courses.all(), {
        refetchType: 'none',
      });

      showNotification({
        message: intl.formatMessage({ id: 'evaluate.submit.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return { evaluateTeacher: mutate, isLoading };
};
