import { formatInTimeZone } from 'date-fns-tz';

export const timezones = [
  {
    text: 'International Date Line West',
    abbr: 'DST',
    utc: 'Etc/GMT+12',
  },
  {
    text: 'Coordinated Universal Time-11',
    abbr: 'U',
    utc: 'Etc/GMT+11',
  },
  { text: 'Hawaii', abbr: 'HST', utc: 'Etc/GMT+10' },
  { text: 'Alaska', abbr: 'AKDT', utc: 'America/Anchorage' },
  {
    text: 'Baja California',
    abbr: 'PDT',
    utc: 'America/Santa_Isabel',
  },
  {
    text: 'Pacific Daylight Time (US & Canada)',
    abbr: 'PDT',
    utc: 'America/Los_Angeles',
  },
  {
    text: 'Pacific Standard Time (US & Canada)',
    abbr: 'PST',
    utc: 'America/Los_Angeles',
  },
  { text: 'Arizona', abbr: 'UMST', utc: 'America/Creston' },
  {
    text: 'Chihuahua, La Paz, Mazatlan',
    abbr: 'MDT',
    utc: 'America/Chihuahua',
  },
  {
    text: 'Mountain Time (US & Canada)',
    abbr: 'MDT',
    utc: 'America/Boise',
  },
  { text: 'Central America', abbr: 'CAST', utc: 'America/Belize' },
  {
    text: 'Central Time (US & Canada)',
    abbr: 'CDT',
    utc: 'America/Chicago',
  },
  {
    text: 'Guadalajara, Mexico City, Monterrey',
    abbr: 'CDT',
    utc: 'America/Bahia_Banderas',
  },
  { text: 'Saskatchewan', abbr: 'CCST', utc: 'America/Regina' },
  {
    text: 'Bogota, Lima, Quito',
    abbr: 'SPST',
    utc: 'America/Bogota',
  },
  {
    text: 'Eastern Time (US & Canada)',
    abbr: 'EST',
    utc: 'America/Detroit',
  },
  {
    text: 'Eastern Daylight Time (US & Canada)',
    abbr: 'EDT',
    utc: 'America/Detroit',
  },
  {
    text: 'Indiana (East)',
    abbr: 'UEDT',
    utc: 'America/Indiana/Marengo',
  },
  { text: 'Caracas', abbr: 'VST', utc: 'America/Caracas' },
  { text: 'Asuncion', abbr: 'PYT', utc: 'America/Asuncion' },
  {
    text: 'Atlantic Time (Canada)',
    abbr: 'ADT',
    utc: 'America/Glace_Bay',
  },
  { text: 'Cuiaba', abbr: 'CBST', utc: 'America/Campo_Grande' },
  {
    text: 'Georgetown, La Paz, Manaus, San Juan',
    abbr: 'SWST',
    utc: 'America/Anguilla',
  },
  { text: 'Santiago', abbr: 'PSST', utc: 'America/Santiago' },
  { text: 'Newfoundland', abbr: 'NDT', utc: 'America/St_Johns' },
  { text: 'Brasilia', abbr: 'ESAST', utc: 'America/Sao_Paulo' },
  {
    text: 'Buenos Aires',
    abbr: 'AST',
    utc: 'America/Argentina/La_Rioja',
  },
  {
    text: 'Cayenne, Fortaleza',
    abbr: 'SEST',
    utc: 'America/Araguaina',
  },
  { text: 'Greenland', abbr: 'GDT', utc: 'America/Godthab' },
  { text: 'Montevideo', abbr: 'MST', utc: 'America/Montevideo' },
  { text: 'Salvador', abbr: 'BST', utc: 'America/Bahia' },
  {
    text: 'Coordinated Universal Time-02',
    abbr: 'U',
    utc: 'America/Noronha',
  },
  { text: 'Azores', abbr: 'ADT', utc: 'America/Scoresbysund' },
  {
    text: 'Cape Verde Is.',
    abbr: 'CVST',
    utc: 'Atlantic/Cape_Verde',
  },
  { text: 'Casablanca', abbr: 'MDT', utc: 'Africa/Casablanca' },
  {
    text: 'Coordinated Universal Time',
    abbr: 'UTC',
    utc: 'America/Danmarkshavn',
  },
  { text: 'Edinburgh, London', abbr: 'GMT', utc: 'Europe/Isle_of_Man' },
  { text: 'Dublin, Lisbon', abbr: 'GDT', utc: 'Atlantic/Canary' },
  { text: 'Monrovia, Reykjavik', abbr: 'GST', utc: 'Africa/Abidjan' },
  {
    text: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    abbr: 'WEDT',
    utc: 'Arctic/Longyearbyen',
  },
  {
    text: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    abbr: 'CEDT',
    utc: 'Europe/Belgrade',
  },
  {
    text: 'Brussels, Copenhagen, Madrid, Paris',
    abbr: 'RDT',
    utc: 'Europe/Brussels',
  },
  {
    text: 'Sarajevo, Skopje, Warsaw, Zagreb',
    abbr: 'CEDT',
    utc: 'Europe/Sarajevo',
  },
  {
    text: 'West Central Africa',
    abbr: 'WCAST',
    utc: 'Africa/Algiers',
  },
  { text: 'Windhoek', abbr: 'NST', utc: 'Africa/Windhoek' },
  { text: 'Athens, Bucharest', abbr: 'GDT', utc: 'Asia/Nicosia' },
  { text: 'Beirut', abbr: 'MEDT', utc: 'Asia/Beirut' },
  { text: 'Cairo', abbr: 'EST', utc: 'Africa/Cairo' },
  { text: 'Damascus', abbr: 'SDT', utc: 'Asia/Damascus' },
  { text: 'E. Europe', abbr: 'EEDT', utc: 'Asia/Nicosia' },
  {
    text: 'Harare, Pretoria',
    abbr: 'SAST',
    utc: 'Africa/Blantyre',
  },
  {
    text: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    abbr: 'FDT',
    utc: 'Europe/Helsinki',
  },
  { text: 'Istanbul', abbr: 'TDT', utc: 'Europe/Istanbul' },
  { text: 'Jerusalem', abbr: 'JDT', utc: 'Asia/Jerusalem' },
  { text: 'Tripoli', abbr: 'LST', utc: 'Africa/Tripoli' },
  { text: 'Amman', abbr: 'JST', utc: 'Asia/Amman' },
  { text: 'Baghdad', abbr: 'AST', utc: 'Asia/Baghdad' },
  { text: 'Kaliningrad', abbr: 'KST', utc: 'Europe/Kaliningrad' },
  { text: 'Kuwait, Riyadh', abbr: 'AST', utc: 'Asia/Aden' },
  { text: 'Nairobi', abbr: 'EAST', utc: 'Africa/Addis_Ababa' },
  {
    text: 'Moscow, St. Petersburg, Volgograd, Minsk',
    abbr: 'MSK',
    utc: 'Europe/Kirov',
  },
  {
    text: 'Samara, Ulyanovsk, Saratov',
    abbr: 'SAMT',
    utc: 'Europe/Astrakhan',
  },
  { text: 'Tehran', abbr: 'IDT', utc: 'Asia/Tehran' },
  { text: 'Abu Dhabi, Muscat', abbr: 'AST', utc: 'Asia/Dubai' },
  { text: 'Baku', abbr: 'ADT', utc: 'Asia/Baku' },
  { text: 'Port Louis', abbr: 'MST', utc: 'Indian/Mahe' },
  { text: 'Tbilisi', abbr: 'GET', utc: 'Asia/Tbilisi' },
  { text: 'Yerevan', abbr: 'CST', utc: 'Asia/Yerevan' },
  { text: 'Kabul', abbr: 'AST', utc: 'Asia/Kabul' },
  {
    text: 'Ashgabat, Tashkent',
    abbr: 'WAST',
    utc: 'Antarctica/Mawson',
  },
  {
    text: 'Yekaterinburg',
    abbr: 'YEKT',
    utc: 'Asia/Yekaterinburg',
  },
  { text: 'Islamabad, Karachi', abbr: 'PKT', utc: 'Asia/Karachi' },
  {
    text: 'Chennai, Kolkata, Mumbai, New Delhi',
    abbr: 'IST',
    utc: 'Asia/Kolkata',
  },
  {
    text: 'Sri Jayawardenepura',
    abbr: 'SLST',
    utc: 'Asia/Colombo',
  },
  { text: 'Kathmandu', abbr: 'NST', utc: 'Asia/Kathmandu' },
  {
    text: 'Nur-Sultan (Astana)',
    abbr: 'CAST',
    utc: 'Antarctica/Vostok',
  },
  { text: 'Dhaka', abbr: 'BST', utc: 'Asia/Dhaka' },
  { text: 'Yangon (Rangoon)', abbr: 'MST', utc: 'Asia/Rangoon' },
  {
    text: 'Bangkok, Hanoi, Jakarta',
    abbr: 'SAST',
    utc: 'Antarctica/Davis',
  },
  { text: 'Novosibirsk', abbr: 'NCAST', utc: 'Asia/Novokuznetsk' },
  {
    text: 'Beijing, Chongqing, Hong Kong, Urumqi',
    abbr: 'CST',
    utc: 'Asia/Hong_Kong',
  },
  { text: 'Krasnoyarsk', abbr: 'NAST', utc: 'Asia/Krasnoyarsk' },
  {
    text: 'Kuala Lumpur, Singapore',
    abbr: 'MPST',
    utc: 'Asia/Brunei',
  },
  { text: 'Perth', abbr: 'WAST', utc: 'Antarctica/Casey' },
  { text: 'Taipei', abbr: 'TST', utc: 'Asia/Taipei' },
  { text: 'Ulaanbaatar', abbr: 'UST', utc: 'Asia/Choibalsan' },
  { text: 'Irkutsk', abbr: 'NAEST', utc: 'Asia/Irkutsk' },
  { text: 'Osaka, Sapporo, Tokyo', abbr: 'JST', utc: 'Asia/Dili' },
  { text: 'Seoul', abbr: 'KST', utc: 'Asia/Pyongyang' },
  { text: 'Adelaide', abbr: 'CAST', utc: 'Australia/Adelaide' },
  { text: 'Darwin', abbr: 'ACST', utc: 'Australia/Darwin' },
  { text: 'Brisbane', abbr: 'EAST', utc: 'Australia/Brisbane' },
  {
    text: 'Canberra, Melbourne, Sydney',
    abbr: 'AEST',
    utc: 'Australia/Melbourne',
  },
  {
    text: 'Guam, Port Moresby',
    abbr: 'WPST',
    utc: 'Antarctica/DumontDUrville',
  },
  { text: 'Hobart', abbr: 'TST', utc: 'Australia/Currie' },
  { text: 'Yakutsk', abbr: 'YST', utc: 'Asia/Chita' },
  {
    text: 'Solomon Is., New Caledonia',
    abbr: 'CPST',
    utc: 'Antarctica/Macquarie',
  },
  { text: 'Vladivostok', abbr: 'VST', utc: 'Asia/Sakhalin' },
  {
    text: 'Auckland, Wellington',
    abbr: 'NZST',
    utc: 'Antarctica/McMurdo',
  },
  {
    text: 'Coordinated Universal Time+12',
    abbr: 'U',
    utc: 'Etc/GMT-12',
  },
  { text: 'Fiji', abbr: 'FST', utc: 'Pacific/Fiji' },
  { text: 'Magadan', abbr: 'MST', utc: 'Asia/Anadyr' },
  {
    text: 'Petropavlovsk-Kamchatsky - Old',
    abbr: 'KDT',
    utc: 'Asia/Kamchatka',
  },
  { text: "Nuku'alofa", abbr: 'TST', utc: 'Etc/GMT-13' },
  { text: 'Samoa', abbr: 'SST', utc: 'Pacific/Apia' },
];

export const DEFAULT_TIMEZONE =
  timezones.find((tz) => tz.abbr === 'RDT')?.utc || '';

export const BROWSER_TIMEZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const TIMEZONE_OPTIONS = timezones.map((t) => {
  const offset = formatInTimeZone(new Date(), t.utc, 'xxxxx');

  return {
    label: `UTC${offset} - ${t.text}`,
    value: t.utc,
  };
});
