import { QueryCache, QueryClient } from '@tanstack/react-query';

import { StaleTime } from 'queries';

const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      staleTime: StaleTime.FIFTEEN_MIN,
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});
