import { Box, ClickAwayListener, Paper, Popper } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ArrowButton } from 'components/@common';

import { DayPicker } from '../DayPicker';

interface Props {
  day: string;
  onChange(day: number): void;
  onPrevious(): void;
  onNext(): void;
  disabled?: boolean;
  fullWidth?: boolean;
}

const DaypickerButton: React.FC<Props> = ({
  day,
  onChange,
  onNext,
  onPrevious,
  disabled,
  fullWidth,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box width="100%">
        <ArrowButton
          disabled={disabled}
          onClick={handleClick}
          onNext={onNext}
          onPrevious={onPrevious}
          fullWidth={fullWidth}
        >
          <FormattedMessage id={`label.day.${day}`} />
        </ArrowButton>
        <Popper open={open} anchorEl={anchorEl}>
          <Paper
            sx={{ width: { xs: '100vw', lg: 368 }, paddingX: 3, paddingY: 6 }}
            square
            elevation={5}
          >
            <DayPicker currentDay={day} onClick={onChange} />
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default DaypickerButton;
