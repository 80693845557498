import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import { PageParams } from 'types/jsonapi.types';
import { Teacher } from 'types/teacher.types';
import { Timesheet, TimesheetLesson } from 'types/timesheet.types';

const getTimesheetLessons = async (
  teacherId: Teacher['id'],
  timesheetId: Timesheet['id'],
  params?: PageParams,
) => {
  const { data } = await apiService.getTimesheetLessons(
    teacherId,
    timesheetId,
    params,
  );

  return deserialize<TimesheetLesson>(data);
};

export const useTimesheetLessons = (
  teacherId: Teacher['id'],
  timesheetId: Timesheet['id'],
  params?: PageParams,
  config?: UseQueryOptions<DeserializedArrayResponse<TimesheetLesson>>,
) => {
  const { data, isLoading, isError, isFetching } = useQuery(
    QueryKeys.timesheets.lessons(teacherId, timesheetId, params),
    () => getTimesheetLessons(teacherId, timesheetId, params),
    config,
  );

  return {
    ...getListData(data),
    isLoading,
    isFetching,
    isError,
  };
};
