import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Teacher } from 'types/teacher.types';
import { Timesheet, TimesheetMeta } from 'types/timesheet.types';

const getTimesheet = async (
  teacherId: Teacher['id'],
  timesheetId: Timesheet['id'],
) => {
  const { data } = await apiService.getTimesheet(teacherId, timesheetId);

  return deserialize<Timesheet, TimesheetMeta>(data);
};

export const useTimesheet = (
  teacherId: Teacher['id'],
  timesheetId: Timesheet['id'],
  config?: UseQueryOptions<DeserializedResponse<Timesheet, TimesheetMeta>>,
) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    QueryKeys.timesheets.byId(teacherId, timesheetId),
    () => getTimesheet(teacherId, timesheetId),
    config,
  );

  return {
    data: data?.data,
    meta: data?.meta,
    isLoading,
    isFetching,
    isError,
  };
};
