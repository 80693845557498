import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { CreateUserParams } from 'types/user.types';

const createAdmin = async (variables: CreateUserParams) =>
  apiService.createAdmin(variables);

export const useCreateAdmin = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(createAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.user.all());

      showNotification({
        message: intl.formatMessage({ id: 'admin.create.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return {
    createAdmin: mutate,
    isLoading,
  };
};
