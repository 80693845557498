import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';
import { useRef } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '../Icon';

interface Props extends StandardTextFieldProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
}
const SearchBar = ({ searchValue, onSearchChange, ...rest }: Props) => {
  const intl = useIntl();
  const textInput = useRef<HTMLInputElement>(null);

  const handleFocusInput = () => {
    textInput?.current?.focus();
  };

  return (
    <TextField
      variant="filled"
      label={intl.formatMessage({ id: 'page.orders.search.label' })}
      {...rest}
      value={searchValue}
      inputRef={textInput}
      onChange={(e) => onSearchChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon
              name="IcoSearch"
              onClick={handleFocusInput}
              sx={{ cursor: 'pointer' }}
              width={24}
              height={24}
            />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};
export default SearchBar;
