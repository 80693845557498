import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumericFormat } from 'react-number-format';

import { parseToUpdateBody } from 'utils/availability.utils';

import { TIMEZONE_OPTIONS } from 'constants/timezones';
import { useTeacher, useUpdateDefaultAvailabilities, useUser } from 'queries';

import { useAvailabilities } from 'components/@availabilities/AvailabilitiesProvider';
import { UnsavedChangesDialog } from 'components/@dialog';
import { LoadingState } from 'components/@states';
import { useMultistep } from 'components/@steps';

const Context = () => {
  const intl = useIntl();
  const { proceed } = useMultistep();
  const [error, setError] = useState<string>();
  const { data: user } = useUser();
  const userId = user?.getUsername() ?? '';

  const { data: teacher, isLoading } = useTeacher(userId, {
    enabled: !!user,
  });

  const {
    setAvailabilities,
    selectedTimeslots,
    maxBookedSlots,
    unsavedChanges,
    setUnsavedChanges,
  } = useAvailabilities('default');
  const { updateDefaultAvailabilities, isLoading: isUpdating } =
    useUpdateDefaultAvailabilities();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailabilities({
      maxBookedSlots: Number(event.target.value),
      unsavedChanges: true,
    });
  };

  const handleConfirmUnsavedChanges = () => {
    setUnsavedChanges(false);

    return true;
  };

  const handleSubmit = () => {
    if (maxBookedSlots < 6) {
      setError('course.plan.step.context.max_booked_slots.min.error');
      return;
    }

    if (!maxBookedSlots) {
      setError('field.error.required');
      return;
    }

    const params = parseToUpdateBody(selectedTimeslots, {
      maxBookedSlots: Number(maxBookedSlots),
    });

    setAvailabilities({ timezone: teacher?.timezone, unsavedChanges: false });

    updateDefaultAvailabilities(params, {
      onSuccess: () => {
        proceed();
      },
      onError: () => {
        setUnsavedChanges(true);
      },
    });
  };

  if (isLoading) return <LoadingState />;

  return (
    <>
      <Typography variant="h3" mb={2}>
        <FormattedMessage id="page.availabilities.default.step.context.title" />
      </Typography>
      <Typography>
        <FormattedMessage id="page.availabilities.default.step.context.description" />
      </Typography>
      <Typography color="textSecondary" mb={10}>
        {
          TIMEZONE_OPTIONS.find(
            (timezone) => timezone.value === teacher?.timezone,
          )?.label
        }
      </Typography>
      <Box width={{ lg: '50%' }} pb={10}>
        <Box mb={10}>
          <Typography mb={4}>
            <FormattedMessage id="page.availabilities.default.step.context.class_hours.label" />
          </Typography>
          <NumericFormat
            value={maxBookedSlots}
            allowNegative={false}
            decimalScale={0}
            label={intl.formatMessage({ id: 'label.amount_hours' })}
            onChange={handleChange}
            error={!!error}
            helperText={!!error ? intl.formatMessage({ id: error }) : undefined}
            customInput={TextField}
          />
        </Box>
        <LoadingButton
          onClick={handleSubmit}
          sx={{ width: { xs: '100%', md: 'auto' } }}
          loading={isUpdating}
          variant="contained"
        >
          <FormattedMessage id="label.proceed" />
        </LoadingButton>
      </Box>
      <UnsavedChangesDialog
        when={unsavedChanges}
        onConfirm={handleConfirmUnsavedChanges}
      />
    </>
  );
};

export default Context;
