import { JsonApiArrayResponse } from 'deserialize-json-api';

import { GetOrdersParams, Order, OrderMetaData } from 'types/order.types';

import RestApiService from './';

export default {
  getOrders(this: RestApiService, params: GetOrdersParams) {
    return this.api.get<JsonApiArrayResponse<Order, OrderMetaData>>(`/orders`, {
      params,
    });
  },
};
