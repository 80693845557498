import { CircularProgress, Stack, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  height?: string | number;
  text?: string;
}

const LoadingState: FC<Props> = ({ height = 'calc(100vh - 80px)', text }) => (
  <Stack
    direction="column"
    alignItems="center"
    justifyContent="center"
    flexGrow="1"
    height={height}
  >
    <CircularProgress />
    {text && <Typography mt={4}>{text}</Typography>}
  </Stack>
);

export default LoadingState;
