import { CognitoUser } from 'types/amplify.types';

export const getInitialsFromUser = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0).toLocaleUpperCase()}${lastName
    .charAt(0)
    .toLocaleUpperCase()}`;
};

export const getInitialsFromFullName = (name: string) => {
  const nameArr = name.split(' ');

  return `${nameArr[0].charAt(0)}${nameArr[1].charAt(0)}`;
};

export const getFullName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};

export const getProfileUrl = (user: CognitoUser) => {
  return `https://${user.attributes['custom:profile_picture']}`;
};
