import {
  AvailabilityStatus,
  DefaultAvailabilities,
  DefaultAvailabilityTimeslot,
  SelectedTimeslots,
  SpecificAvailabilities,
  SpecificAvailabilityTimeslot,
  UpdateDefaultAvailabilitiesParams,
} from 'types/availabilities.types';

export const isSpecificTimeslot = (
  arg: DefaultAvailabilityTimeslot | SpecificAvailabilityTimeslot,
): arg is SpecificAvailabilityTimeslot => 'date' in arg;

export const isSpecificAvailabilities = (
  arg: DefaultAvailabilities | SpecificAvailabilities,
): arg is SpecificAvailabilities => 'timeslots' in arg;

const isAvailable = (status: AvailabilityStatus) =>
  status === AvailabilityStatus.AVAILABLE;

export const parseSpecificAvailabilities = (
  availabilities: SelectedTimeslots,
) => {
  const days = Object.entries(availabilities).map(([date, timeslots]) => ({
    date,
    timeslots: Object.entries(timeslots).map(([timeStart, status]) => ({
      timeStart,
      status,
    })),
  }));

  return days.filter(({ timeslots }) => !!timeslots.length);
};

export const parseDefaultAvailabilities = (
  availabilities: SelectedTimeslots,
) => {
  const days = Object.entries(availabilities).map(([index, timeslots]) => {
    return {
      index: Number(index),
      timeslots: Object.keys(timeslots)
        .filter((timeslot) => isAvailable(timeslots[timeslot]))
        .map((timeslot) => timeslot),
    };
  });

  return days.filter(({ timeslots }) => !!timeslots.length);
};

export const parseTimeslotStatuses = (
  timeslots: (DefaultAvailabilityTimeslot | SpecificAvailabilityTimeslot)[],
) =>
  timeslots.reduce((acc, cur) => {
    const index = isSpecificTimeslot(cur) ? cur.date : cur.index;
    const timeslot = cur.timeStart.split(/([+-])/)[0];
    acc = {
      ...acc,
      [index]: {
        ...acc[index],
        [timeslot]: cur.status,
      },
    };
    return acc;
  }, {} as SelectedTimeslots);

export const parseToUpdateBody = (
  availabilities: SelectedTimeslots,
  data?: Partial<UpdateDefaultAvailabilitiesParams>,
) => ({
  days: parseDefaultAvailabilities(availabilities),
  maxBookedSlots: data?.maxBookedSlots ?? 0,
  timezone: data?.timezone,
  ...data,
});
