import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Course } from 'types/course.types';
import { LessonTimeslot } from 'types/internal.types';
import { LessonRecurrence } from 'types/lesson.types';
import { Teacher } from 'types/teacher.types';

export interface MatchLessonsPayload {
  teacher: Teacher;
  lessons: LessonTimeslot[];
  conflicts: LessonTimeslot[];
  timeStart: string;
  timeEnd: string;
  amount: number;
  endAfter?: number;
  endDate?: string;
  recurrence?: LessonRecurrence;
}

export interface MatchLessonsState {
  data: Record<Course['id'], MatchLessonsPayload>;
}

const initialState: MatchLessonsState = {
  data: {},
};

const matchLessonsSlice = createSlice({
  name: 'match-lessons',
  initialState,
  reducers: {
    SET_MATCH: (
      state,
      {
        payload,
      }: PayloadAction<{ courseId: Course['id']; data: MatchLessonsPayload }>,
    ) => {
      state.data[payload.courseId] = payload.data;
    },
    RESET_MATCH: (
      state,
      { payload }: PayloadAction<{ courseId: Course['id'] }>,
    ) => {
      delete state.data[payload.courseId];
    },
  },
});

export const { actions: matchLessonsActions } = matchLessonsSlice;

export const matchLessonsReducer = matchLessonsSlice.reducer;
