import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Lesson, UpdateLessonParams } from 'types/lesson.types';

interface Variables {
  lesson: Lesson;
  params: UpdateLessonParams;
}

const updateLesson = async ({ lesson, params }: Variables) =>
  apiService.updateLesson(lesson.id, params);

export const useUpdateLesson = () => {
  const intl = useIntl();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(updateLesson, {
    onSuccess: (_, { lesson, params }) => {
      queryClient.invalidateQueries(QueryKeys.lessons.all(lesson.courseId));

      queryClient.invalidateQueries(QueryKeys.courses.all(), {
        refetchType: 'none',
      });

      if (!params.studentDidNotShowUp) {
        showNotification({
          message: intl.formatMessage({
            id: 'lesson.notification.update.success',
          }),

          variant: 'success',
        });
      }
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),
        variant: 'error',
      });
    },
  });

  return { updateLesson: mutate, isLoading };
};
