import { TextFieldProps } from '@mui/material';
import { CalendarPickerView, DatePicker } from '@mui/x-date-pickers';
import { isValid } from 'date-fns';
import React from 'react';

import { Icon } from 'components/@common';

import DatepickerInput from '../DatepickerInput';

interface Props {
  fullWidth?: boolean;
  startDate: Date | null;
  minDate?: Date;
  maxDate?: Date;
  open?: boolean;
  onClose?(): void;
  onChange(date: Date | null): void;
  renderInput?(params: TextFieldProps): React.ReactElement;
  error?: string;
  inputFormat?: string;
  views?: CalendarPickerView[];
}

const Datepicker: React.FC<Props> = ({
  startDate,
  minDate,
  maxDate,
  open,
  onClose,
  onChange,
  fullWidth,
  renderInput,
  error,
  inputFormat = 'dd/MM/yyyy',
  views,
}) => {
  const handleChange = (date: Date | null) => {
    if (date && isValid(date)) {
      onChange(date);
    }
  };

  return (
    <DatePicker
      value={startDate}
      onChange={handleChange}
      open={open}
      onClose={onClose}
      minDate={minDate}
      maxDate={maxDate}
      inputFormat={inputFormat}
      views={views}
      DialogProps={{
        className: 'datepicker-dialog',
      }}
      PaperProps={{
        sx: {
          width: 368,
          // stupid datepicker not providing classes on inner containers
          '& > div > div': {
            width: '100%',
          },
        },
      }}
      OpenPickerButtonProps={{
        sx: (theme) => ({
          backgroundColor: 'transparent',
          '&& svg': {
            fill: theme.palette.neutral[800],
            width: 24,
            height: 24,
          },
        }),
      }}
      components={{
        OpenPickerIcon: () => <Icon name="IcoCalendar" />,
      }}
      /**
       *   Doesn't really show anything,
       *   but the default open dialog aria text
       *   in combination with the adapterlocale,
       *   crashes the app. This fixes that issue
       */
      getOpenDialogAriaText={() => ''}
      renderInput={
        !renderInput
          ? (params) => (
              <DatepickerInput
                fullWidth={fullWidth}
                {...params}
                error={error}
              />
            )
          : renderInput
      }
    />
  );
};

export default Datepicker;
