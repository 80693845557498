import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { BROWSER_TIMEZONE } from 'constants/timezones';
import { StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Teacher } from 'types/teacher.types';

const getTeacher = async (id: Teacher['id']) => {
  const { data } = await apiService.getTeacher(id);

  return deserialize<Teacher>(data);
};

export const useTeacher = (
  id: Teacher['id'],
  config?: UseCommonQueryOptions<DeserializedResponse<Teacher>>,
) => {
  const { data, isLoading, isError, isFetching } = useQuery(
    QueryKeys.teacher.byId(id),
    () => getTeacher(id),
    {
      ...config,
      staleTime: StaleTime.FIFTEEN_MIN,
    },
  );

  return {
    data: data?.data,
    timezone: data?.data.timezone ?? BROWSER_TIMEZONE,
    isLoading,
    isError,
    isFetching,
  };
};
