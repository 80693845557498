import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { User } from 'types/user.types';

interface Variables {
  id: User['id'];
}

const deleteUser = async ({ id }: Variables) => apiService.deleteUser(id);

export const useDeleteUser = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(deleteUser, {
    onSuccess: (_, { id }) => {
      queryClient.removeQueries(QueryKeys.user.byId(id));
      queryClient.invalidateQueries(QueryKeys.user.all());

      showNotification({
        message: intl.formatMessage({ id: 'user.delete.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({ id: 'user.delete.error' }),
        variant: 'error',
      });
    },
  });

  return { deleteUser: mutate, isLoading };
};
