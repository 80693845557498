import { Link as MuiLink, LinkProps } from '@mui/material';
import React from 'react';
import {
  Link as RouterLink,
  Path,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';

interface Props extends LinkProps {
  to: string | Partial<Path>;
  navigation?: boolean;
}

const Link: React.FC<React.PropsWithChildren<Props>> = ({
  to,
  children,
  navigation,
  ...rest
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <MuiLink
      underline={navigation && match ? 'always' : 'none'}
      component={RouterLink}
      to={to}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
