import { Components, Theme } from '@mui/material';

export const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(3, 0),
    }),
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(6, 0),
    }),
    head: {
      fontWeight: 500,
    },
  },
};
