import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useForgotPassword, useUser } from 'queries';

import { Dialog } from 'components/@common';

interface Props {
  open: boolean;
  onClose(): void;
}

const ChangePasswordDialog: React.FC<Props> = ({ open, onClose }) => {
  const intl = useIntl();
  const { data } = useUser();

  const { requestPasswordReset, isLoading } = useForgotPassword();

  const handleResetPassword = () => {
    if (data) {
      requestPasswordReset(
        { email: data.attributes.email, redirect: false },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={intl.formatMessage({ id: 'dialog.change_password.title' })}
    >
      <Typography>
        <FormattedMessage id="dialog.change_password.description" />
      </Typography>
      <Stack justifyContent="flex-end" gap={4} mt={6}>
        <Button disabled={isLoading} variant="outlined" onClick={onClose}>
          <FormattedMessage id="label.cancel" />
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleResetPassword}
        >
          <FormattedMessage id="label.change_password" />
        </LoadingButton>
      </Stack>
    </Dialog>
  );
};

export default ChangePasswordDialog;
