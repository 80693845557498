import { JsonApiArrayResponse, JsonApiResponse } from 'deserialize-json-api';

import { Course } from 'types/course.types';
import { Resources } from 'types/jsonapi.types';
import {
  GetTeachersParams,
  Teacher,
  TeacherAttributes,
  TeacherEvaluation,
  UpdateTeacherParams,
} from 'types/teacher.types';

import RestApiService from './';

export default {
  getTeacher(this: RestApiService, id: Teacher['id']) {
    return this.api.get<JsonApiResponse<Teacher>>(`/teachers/${id}`);
  },
  getTeachers(this: RestApiService, params: GetTeachersParams) {
    return this.api.get<JsonApiArrayResponse<Teacher>>(`/teachers`, { params });
  },
  updateTeacher(
    this: RestApiService,
    id: Teacher['id'],
    attributes: UpdateTeacherParams,
  ) {
    return this.api.patch(`/teachers/${id}`, {
      data: { type: Resources.TEACHERS, attributes },
    });
  },
  disableTeacher(this: RestApiService, id: Teacher['id']) {
    return this.api.delete(`/teachers/${id}`);
  },
  getTeacherEvaluation(
    this: RestApiService,
    id: Teacher['id'],
    courseId: Course['id'],
  ) {
    return this.api.get<JsonApiResponse<TeacherEvaluation>>(
      `/teachers/${id}/evaluation`,
      {
        params: {
          courseId,
        },
      },
    );
  },
  evaluateTeacher(
    this: RestApiService,
    id: Teacher['id'],
    course: Course['id'],
    attributes: TeacherEvaluation,
  ) {
    return this.api.post(
      `/teachers/${id}/evaluation`,
      {
        data: { type: Resources.EVALUATIONS, attributes },
      },
      {
        params: {
          course,
        },
      },
    );
  },
  createTeacher(this: RestApiService, attributes: Partial<TeacherAttributes>) {
    return this.api.post('/teachers', {
      data: { type: Resources.TEACHERS, attributes },
    });
  },
};
