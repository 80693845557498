import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { CourseUpdateParams } from 'types/course.types';

interface Variables {
  courseId: string;
  params?: CourseUpdateParams;
}

const updateCourse = async ({ courseId, params }: Variables) =>
  apiService.updateCourse(courseId, params);

export const useUpdateCourse = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(updateCourse, {
    onSuccess: (_, { courseId }) => {
      queryClient.invalidateQueries(QueryKeys.courses.byId(courseId));
      queryClient.invalidateQueries(QueryKeys.courses.all(), {
        refetchType: 'none',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),
        variant: 'error',
      });
    },
  });

  return { updateCourse: mutate, isLoading };
};
