import { useQuery, useQueryClient } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { parseTimeslotStatuses } from 'utils/availability.utils';

import { QueryKeys, StaleTime } from 'queries';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import {
  GetAvailabilitiesByWeekParams,
  SpecificAvailabilities,
  SpecificAvailabilitiesMeta,
} from 'types/availabilities.types';
import { Teacher } from 'types/teacher.types';

import { useAvailabilities } from 'components/@availabilities';

const getAvailabilitiesByWeek = async (
  id: Teacher['id'],
  params: GetAvailabilitiesByWeekParams,
) => {
  const { data } = await apiService.getAvailabilitiesByWeek(id, params);

  return deserialize<SpecificAvailabilities, SpecificAvailabilitiesMeta>(data);
};

export const useAvailabilitiesByWeek = (
  teacherId: Teacher['id'],
  params: GetAvailabilitiesByWeekParams,
  config?: UseCommonQueryOptions<
    DeserializedResponse<SpecificAvailabilities, SpecificAvailabilitiesMeta>
  >,
) => {
  const queryClient = useQueryClient();
  const { setTimeslots } = useAvailabilities('specific');

  const { data, isLoading, status, isFetching } = useQuery(
    QueryKeys.availabilities.byWeek(teacherId, params),
    () => getAvailabilitiesByWeek(teacherId, params),
    {
      staleTime: StaleTime.FIFTEEN_MIN,
      retry: (_, error) => error.response?.status !== 404,
      keepPreviousData: true,
      onSuccess: async ({ data }) => {
        setTimeslots(parseTimeslotStatuses(data.timeslots));

        const nextWeekParams: GetAvailabilitiesByWeekParams = {
          week: params.week + 1,
          year: params.year,
        };

        const lastWeekParams: GetAvailabilitiesByWeekParams = {
          week: params.week - 1,
          year: params.year,
        };

        await queryClient.prefetchQuery({
          queryFn: () => getAvailabilitiesByWeek(teacherId, nextWeekParams),
          queryKey: QueryKeys.availabilities.byWeek(teacherId, nextWeekParams),
        });

        await queryClient.prefetchQuery({
          queryFn: () => getAvailabilitiesByWeek(teacherId, lastWeekParams),
          queryKey: QueryKeys.availabilities.byWeek(teacherId, lastWeekParams),
        });

        const nextWeekData = queryClient.getQueryData<
          DeserializedResponse<
            SpecificAvailabilities,
            SpecificAvailabilitiesMeta
          >
        >(QueryKeys.availabilities.byWeek(teacherId, nextWeekParams));
        const lastWeekData = queryClient.getQueryData<
          DeserializedResponse<
            SpecificAvailabilities,
            SpecificAvailabilitiesMeta
          >
        >(QueryKeys.availabilities.byWeek(teacherId, lastWeekParams));

        setTimeslots(
          parseTimeslotStatuses([
            ...data.timeslots,
            ...(nextWeekData?.data.timeslots || []),
            ...(lastWeekData?.data.timeslots || []),
          ]),
        );
      },
      ...config,
    },
  );

  const prefetchAvailabilities = async (
    params: GetAvailabilitiesByWeekParams,
  ) => {
    let nextData = queryClient.getQueryData<
      DeserializedResponse<SpecificAvailabilities, SpecificAvailabilitiesMeta>
    >(QueryKeys.availabilities.byWeek(teacherId, params));

    if (!nextData) {
      await queryClient.prefetchQuery({
        queryKey: QueryKeys.availabilities.byWeek(teacherId, params),
        queryFn: () => getAvailabilitiesByWeek(teacherId, params),
      });

      nextData = queryClient.getQueryData<
        DeserializedResponse<SpecificAvailabilities, SpecificAvailabilitiesMeta>
      >(QueryKeys.availabilities.byWeek(teacherId, params));
    }

    return nextData;
  };

  return {
    data: data?.data,
    meta: data?.meta,
    prefetchAvailabilities,
    isLoading,
    isFetching,
    status,
  };
};
