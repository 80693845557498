import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

import { NavigationAuthenticated } from 'components/@navigation';

const Layout: FC = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <NavigationAuthenticated />
      <Outlet />
    </>
  );
};

export default Layout;
