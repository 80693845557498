import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { addDays, addMonths, format, isPast, isToday, parse } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { parseDefaultAvailabilities } from 'utils/availability.utils';

import { useNotification } from 'hooks';
import { useUpdateDefaultAvailabilities } from 'queries';

import { Routes } from 'pages/routes.constants';

import { useAvailabilities } from 'components/@availabilities/AvailabilitiesProvider';
import { Datepicker } from 'components/@datepicker';
import { UnsavedChangesDialog } from 'components/@dialog';

const SelectStartDate = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>();
  const { showNotification } = useNotification();
  const { updateDefaultAvailabilities, isLoading, isPaused } =
    useUpdateDefaultAvailabilities();

  const {
    selectedTimeslots,
    startDate,
    maxBookedSlots,
    timezone,
    resetAvailabilities,
    setAvailabilities,
    unsavedChanges,
    setUnsavedChanges,
    resetSpecificAvailabilities,
  } = useAvailabilities('default');

  const handleDateChange = (date: Date) => {
    setAvailabilities({
      startDate: format(date, 'yyyy-MM-dd'),
      unsavedChanges: true,
    });
  };

  const handleConfirmUnsavedChanges = () => {
    resetAvailabilities();

    return true;
  };

  const handleSave = () => {
    const parsedStartDate = parse(startDate, 'yyyy-MM-dd', new Date());
    const isBefore = !isToday(parsedStartDate) && isPast(parsedStartDate);
    setUnsavedChanges(false);

    if ((startDate && isBefore) || !startDate) {
      setError(intl.formatMessage({ id: 'error.invalid_date' }));
      return;
    }
    updateDefaultAvailabilities(
      {
        days: parseDefaultAvailabilities(selectedTimeslots),
        startDate: startDate ? startDate : undefined,
        maxBookedSlots,
        timezone,
      },
      {
        onSuccess: () => {
          resetSpecificAvailabilities();
          navigate(Routes.Availabilities);
          showNotification({
            message: intl.formatMessage({
              id: 'default_availabilities.notification.update.success',
            }),
            variant: 'success',
          });
        },
        onError: () => {
          setUnsavedChanges(true);
        },
      },
    );
  };

  return (
    <>
      <Box width={{ md: '50%' }} pb={10}>
        <Typography variant="h3" mb={6}>
          <FormattedMessage id="page.availabilities.default.step.start_date.title" />
        </Typography>
        {/* <Typography mb={4}>
          <FormattedMessage id="page.availabilities.default.step.start_date.datepicker.label" />
        </Typography> */}
        <Datepicker
          startDate={parse(startDate, 'yyyy-MM-dd', new Date())}
          onChange={handleDateChange}
          minDate={addDays(new Date(), 1)}
          maxDate={addMonths(new Date(), 3)}
          fullWidth
          error={error}
          inputFormat="d-MM-yyyy"
          views={['month', 'day']}
        />
        <Box mb={10}>
          <Typography mt={5} whiteSpace="pre-wrap">
            <FormattedMessage id="page.availabilities.default.step.start_date.subtitle" />
          </Typography>
          {/* <Typography mb={2}>
          <FormattedMessage id="page.availabilities.default.step.start_date.description" />
        </Typography> */}
          {/* <Typography mb={10}>
          <FormattedMessage id="page.availabilities.default.step.start_date.subdescription" />
        </Typography> */}
        </Box>
        <LoadingButton
          sx={{ width: { xs: '100%', md: 'auto' } }}
          onClick={handleSave}
          loading={isLoading && !isPaused}
          variant="contained"
        >
          <FormattedMessage id="page.availabilities.default.step.start_date.button" />
        </LoadingButton>
      </Box>
      <UnsavedChangesDialog
        when={unsavedChanges}
        onConfirm={handleConfirmUnsavedChanges}
      />
    </>
  );
};

export default SelectStartDate;
