import React from 'react';

import { createRoute } from 'utils/router.utils';

import { Roles } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

import Overview from './Overview';
import TimesheetDetail from './TimesheetDetail';

/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import(
  './Timesheets' /* webpackChunkName: "timesheets" */
);

const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Timesheets,
  authenticated: true,
  children: [Overview, TimesheetDetail],
  roles: [Roles.TEACHER, Roles.ADMIN],
});
