import { AxiosInstance } from 'axios';

import availabilitiesApi from './availabilities.api';
import coursesApi from './courses.api';
import internalApi from './internal.api';
import lessonsApi from './lessons.api';
import ordersApi from './orders.api';
import teacherApi from './teacher.api';
import timesheetApi from './timesheet.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;
  public createUser = userApi.createUser;
  public createAdmin = userApi.createAdmin;
  public updateUser = userApi.updateUser;
  public getUsers = userApi.getUsers;
  public deleteUser = userApi.deleteUser;

  // TEACHER
  public getTeacher = teacherApi.getTeacher;
  public getTeachers = teacherApi.getTeachers;
  public updateTeacher = teacherApi.updateTeacher;
  public disableTeacher = teacherApi.disableTeacher;
  public getTeacherEvaluation = teacherApi.getTeacherEvaluation;
  public evaluateTeacher = teacherApi.evaluateTeacher;
  public createTeacher = teacherApi.createTeacher;

  // AVAILABILITIES
  public getDefaultAvailabilities = availabilitiesApi.getDefaultAvailabilities;
  public updateDefaultAvailabilities =
    availabilitiesApi.updateDefaultAvailabilities;
  public getAvailabilitiesByWeek = availabilitiesApi.getAvailabilitiesByWeek;
  public getDayAvailabilitiesBySkill =
    availabilitiesApi.getDayAvailabilitiesBySkill;
  public getTeacherAvailabilitiesByDay =
    availabilitiesApi.getTeacherAvailabilitiesByDay;
  public getTeacherAvailabilitiesByMonth =
    availabilitiesApi.getTeacherAvailabilitiesByMonth;
  public getMonthAvailabilitiesBySkill =
    availabilitiesApi.getMonthAvailabilitiesBySkill;
  public updateAvailabilitiesByWeek =
    availabilitiesApi.updateAvailabilitiesByWeek;

  // INTERNAL
  public requestPresignedUrl = internalApi.requestPresignedUrl;
  public matchTeacher = internalApi.matchTeacher;

  // COURSES
  public getCourse = coursesApi.getCourse;
  public getCourses = coursesApi.getCourses;
  public updateCourse = coursesApi.updateCourse;
  public getCoursesFromAuthenticatedUser =
    coursesApi.getCoursesFromAuthenticatedUser;
  public getAllCourses = coursesApi.getAllCourses;
  public getCourseCertificate = coursesApi.getCourseCertificate;
  public getCourseEvaluation = coursesApi.getCourseEvaluation;
  public updateCourseEvaluation = coursesApi.updateCourseEvaluation;

  // LESSONS
  public getLessons = lessonsApi.getLessons;
  public planLesson = lessonsApi.planLesson;
  public deleteLesson = lessonsApi.deleteLesson;
  public updateLesson = lessonsApi.updateLesson;
  public getLessonEvaluation = lessonsApi.getLessonEvaluation;
  public updateLessonEvaluation = lessonsApi.updateLessonEvaluation;

  //ORDERS
  public getOrders = ordersApi.getOrders;

  // TIMESHEETS
  public getTimesheetsByTeacher = timesheetApi.getTimesheetsByTeacher;
  public getAllTimesheets = timesheetApi.getAllTimesheets;
  public getTimesheet = timesheetApi.getTimesheet;
  public getTimesheetLessons = timesheetApi.getTimesheetLessons;
  public regenerateTimesheet = timesheetApi.regenerateTimesheet;
  public approveTimesheet = timesheetApi.approveTimesheet;
  public getTimesheetPdf = timesheetApi.getTimesheetPdf;
}

export default RestApiService;
