import { ComponentProps } from 'react';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Select } from 'components/@inputs';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<ComponentProps<typeof Select>, 'onChange' | 'value'>;

const FormSelect: React.FC<Props> = ({ rules, name, label, ...rest }) => {
  const { control } = useFormContext();
  const intl = useIntl();
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          label={label}
          fullWidth
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={
            !!error?.message
              ? intl.formatMessage({ id: error?.message })
              : undefined
          }
          {...rest}
        />
      )}
    />
  );
};

export default FormSelect;
