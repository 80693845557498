import { Box, Container } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { NavigationUnauthenticated } from 'components/@navigation';

interface Props {
  backgroundImage: string;
  noFollow?: boolean;
}

const LayoutUnauthenticated: React.FC<React.PropsWithChildren<Props>> = ({
  backgroundImage,
  children,
  noFollow = true,
}) => {
  return (
    <>
      {noFollow && (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          backgroundImage: {
            lg: `url(${backgroundImage})`,
          },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <NavigationUnauthenticated />
        <Container disableGutters sx={{ mt: { xs: 0, lg: 6 } }}>
          {children}
        </Container>
      </Box>
    </>
  );
};

export default LayoutUnauthenticated;
