import { FormHelperText, InputBaseProps, TextFieldProps } from '@mui/material';
import React from 'react';

import { InputStyled } from './DatepickerInput.style';

type Props = Pick<InputBaseProps, 'inputRef' | 'inputProps' | 'fullWidth'> & {
  InputProps?: TextFieldProps['InputProps'];
  error?: string;
};

const DatepickerInput: React.FC<Props> = ({
  inputRef,
  inputProps,
  InputProps,
  fullWidth,
  error,
}) => {
  return (
    <>
      <InputStyled
        fullWidth={fullWidth}
        inputRef={inputRef}
        inputProps={inputProps}
        error={!!error}
        sx={{
          fontSize: 16,
        }}
        size="small"
        endAdornment={InputProps?.endAdornment}
      />
      {error && (
        <FormHelperText sx={{ ml: 4, color: 'status.error.200' }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default DatepickerInput;
