import 'utils/polyfill.utils';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { runInDev, runInProd } from 'utils/env.utils';

import { initSentry } from 'services';

import App from './App';

runInProd(() => initSentry());

runInDev(() => {
  // Enable to start mocking!
  // const { worker } = require('./mocks/browser');
  // worker.start({ onUnhandledRequest: 'bypass' });
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
