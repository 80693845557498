import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import config from 'config';

interface IntlState {
  locale: string;
}

const initialState: IntlState = {
  locale: config.DEFAULT_LOCALE,
};

const intlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    LOCALE_UPDATE: (state, { payload }: PayloadAction<IntlState['locale']>) => {
      state.locale = payload;
    },
  },
});

export const { actions: intlActions } = intlSlice;

export const intlReducer = intlSlice.reducer;
