import { JsonApiArrayResponse, JsonApiResponse } from 'deserialize-json-api';

import { getTimezoneHeader } from 'utils/headers.utils';

import { Resources } from 'types/jsonapi.types';
import {
  GetLessonsParams,
  Lesson,
  LessonEvaluation,
  PlanRecurringLessonParams,
  PlanSingleLessonParams,
  UpdateLessonParams,
} from 'types/lesson.types';

import RestApiService from './';

export default {
  getLessons(
    this: RestApiService,
    courseId: string,
    { timezone, ...params }: GetLessonsParams,
  ) {
    return this.api.get<JsonApiArrayResponse<Lesson>>(
      `/courses/${courseId}/lessons`,
      {
        params,
        headers: getTimezoneHeader(timezone),
      },
    );
  },
  planLesson(
    this: RestApiService,
    courseId: string,
    params: PlanSingleLessonParams | PlanRecurringLessonParams,
  ) {
    return this.api.post(
      `/courses/${courseId}/lessons`,
      {
        data: { type: Resources.LESSONS, attributes: params },
      },
      {
        headers: getTimezoneHeader(),
      },
    );
  },
  deleteLesson(this: RestApiService, lessonId: string, reason?: string) {
    return this.api.delete(`/lessons/${lessonId}`, {
      data: { reason },
    });
  },
  updateLesson(
    this: RestApiService,
    lessonId: string,
    params: UpdateLessonParams,
  ) {
    return this.api.patch(
      `/lessons/${lessonId}`,
      {
        data: { type: Resources.LESSONS, attributes: params },
      },
      { headers: getTimezoneHeader() },
    );
  },
  getLessonEvaluation(this: RestApiService, lessonId: Lesson['id']) {
    return this.api.get<JsonApiResponse<LessonEvaluation>>(
      `/lessons/${lessonId}/evaluation`,
    );
  },
  updateLessonEvaluation(
    this: RestApiService,
    lessonId: Lesson['id'],
    params: LessonEvaluation,
  ) {
    return this.api.put(`/lessons/${lessonId}/evaluation`, {
      data: { type: Resources.EVALUATIONS, attributes: params },
    });
  },
};
