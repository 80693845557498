export enum PHEvents {
  START_PLANNING = 'start_planning',
  PLAN_FIRST_LESSON_KNOWLEDGE = 'plan_first_lesson_knowledge',
  PLAN_FIRST_LESSON_GOAL = 'plan_first_lesson_goal',
  PLAN_LESSON_DATE_SELECT = 'plan_lesson_date_select',
  PLAN_LESSON_OVERVIEW = 'plan_lesson_overview',
  PLAN_LESSON_CONFIRMATION = 'plan_lesson_confirmation',
  CANCEL_CLASS_START = 'cancel_class_start',
  CANCEL_CLASS_CONFIRM = 'cancel_class_confirm',
  CHANGE_CLASS_START = 'student_change_class_start',
  UPDATE_SPECIFIC_AVAILABILITIES = 'update_specific_availabilities',
  UPDATE_DEFAULT_AVAILABILITIES = 'update_default_availabilities',
  AVAILABILITIES_INFO = 'teacher_availabilities_info',
  AVAILABILITIES_SETTINGS = 'teacher_availability_settings',
  EVALUATIONS_CLASS = 'evaluations_class',
  EVALUATIONS_COURSE = 'evaluations_course',
  VIEW_EVALUATION_CLASS = 'view_evaluation_class',
  VIEW_EVALUATION_COURSE = 'view_evaluation_course',
  OPEN_TIMESHEETS = 'open_timesheets',
  OPEN_TIMESHEETS_DETAIL = 'open_timesheets_detail',
  UPDATE_PROFILE = 'update_profile',
}
