export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  BOOKED = 'BOOKED',
}

export type SelectedTimeslotByDay = Record<string, AvailabilityStatus>;

export type SelectedTimeslots = Record<string, SelectedTimeslotByDay>;

export type DefaultAvailabilityDay = {
  index: number;
  timeslots: string[];
};

export type Timeslot = {
  timeStart: string;
  timeEnd: string;
};

type Availabilities = {
  maxBookedSlots: number;
  teacherId: string;
  timezone?: string;
};

export type DefaultAvailabilities = Availabilities & {
  defaultAvailabilityTimeslots: DefaultAvailabilityTimeslot[];
  id: string;
  startDate?: string;
};

export type UpdateDefaultAvailabilitiesParams = Omit<
  DefaultAvailabilities,
  'teacherId' | 'defaultAvailabilityTimeslots' | 'id'
> & {
  days: DefaultAvailabilityDay[];
};

export type DefaultAvailabilityTimeslot = Timeslot & {
  status: AvailabilityStatus;
  index: number;
};

export type GetAvailabilitiesByWeekParams = {
  week: number;
  year: number;
};

export type SpecificAvailabilityTimeslot = Timeslot & {
  date: string;
  status: AvailabilityStatus;
};

export type SpecificAvailabilityDay = {
  date: string;
  timeslots: { status: AvailabilityStatus; timeStart: string }[];
};

export type UpdateSpecificAvailabilityDays = {
  date: string;
  timeslots: UpdateSpecificAvailabilityDay[];
};

export type UpdateSpecificAvailabilityDay = {
  timeStart: string;
  status: AvailabilityStatus;
};

export type SpecificAvailabilities = Availabilities & {
  timeslots: SpecificAvailabilityTimeslot[];
};

export type SpecificAvailabilitiesMeta = {
  availableTimeslots: number;
  bookedTimeslots: number;
};

export type GetAvailabilitiesByDayParams = {
  amount: number;
  day: number;
  month: number;
  year: number;
  lessonId?: string;
};

export type GetAvailabilitiesResponse = Pick<
  Timeslot,
  'timeStart' | 'timeEnd'
>[];

export type GetAvailabilitiesByMonthParams = {
  amount: number;
  month: number;
  year: number;
};

export type GetAvailabilitiesByMonthResponse = string[];

export type UpdateSpecificAvailabilitiesParams = {
  days: UpdateSpecificAvailabilityDays[];
};

export interface AvailabilitiesState {
  selectedTimeslots: SelectedTimeslots;
  maxBookedSlots: number;
  timezone: string;
  startDate: string;
  unsavedChanges: boolean;
}
