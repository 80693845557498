import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Icon, Link } from 'components/@common';

interface Props {
  links: { label: string; to: string }[];
  onLogout(): void;
}

const MobileMenu: FC<Props> = ({ links, onLogout }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose();
  }, [location]);

  if (!links.length) return null;

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <IconButton onClick={handleOpen} color="secondary">
        <Icon name="IcoMenu" />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <Stack
          direction="column"
          justifyContent="space-between"
          height="100%"
          width={320}
        >
          <Box>
            <Stack justifyContent="space-between" py={6} px={4}>
              <Icon name="LogoBlack" width={86} height={48} />
              <IconButton onClick={handleClose}>
                <Icon name="IcoClose" />
              </IconButton>
            </Stack>
            <Divider />
            <List>
              {links.map(({ label, to }) => (
                <ListItem key={label}>
                  <Link navigation to={to}>
                    <FormattedMessage id={`navigation.link.${label}`} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Divider />
            <Stack direction="column" py={5} pl={4} gap={6}>
              <Button
                size="small"
                fullWidth
                onClick={() => navigate(Routes.Profile)}
                startIcon={
                  <Avatar variant="filled">
                    <Icon name="IcoUser" />
                  </Avatar>
                }
              >
                <FormattedMessage id="profile.title" />
              </Button>
              <Button
                size="small"
                fullWidth
                onClick={onLogout}
                startIcon={
                  <Avatar variant="filled">
                    <Icon name="IcoLogout" />
                  </Avatar>
                }
              >
                <FormattedMessage id="profile.menu.logout" />
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
