import React from 'react';
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Autocomplete, AutocompleteProps } from 'components/@inputs';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<AutocompleteProps, 'onChange' | 'value'> & {
    disabled?: boolean;
  };

const FormAutocomplete: React.FC<Props> = ({
  name,
  options,
  label,
  required,
  getOptionLabel,
  noOptionsText,
  helperText,
  rules,
  disabled,
}) => {
  const intl = useIntl();
  const { control } = useFormContext();

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }

    if (error?.message) return intl.formatMessage({ id: error.message });
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, ref, value }, fieldState: { error } }) => (
        <Autocomplete
          label={label}
          value={{
            label:
              options.find((option) => option.value === value)?.label ?? '',
            value,
          }}
          ref={ref}
          noOptionsText={noOptionsText}
          getOptionLabel={getOptionLabel}
          required={required}
          onChange={(option) => onChange(option.value)}
          options={options}
          helperText={getHelperText(error)}
          error={!!error}
          disabled={disabled}
        />
      )}
    />
  );
};

export default FormAutocomplete;
