import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { isNil, omitBy } from 'lodash';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { TIMEZONE_OPTIONS } from 'constants/timezones';
import { useIso, useProfilePicture } from 'hooks';
import { useTeacher, useUpdateTeacher, useUser } from 'queries';
import { TeacherAttributes } from 'types/teacher.types';

import { Routes } from 'pages/routes.constants';

import { BottomBar } from 'components/@common';
import {
  Form,
  FormAutocomplete,
  FormSelect,
  FormTextField,
} from 'components/@form';
import { TeacherFields } from 'components/@profile';
import { LoadingState } from 'components/@states';

import { ChangePasswordDialog } from '../ChangePasswordDialog';

const defaultValues = {
  givenName: '',
  familyName: '',
  email: '',
  locale: '',
  about: '',
  nativeLanguage: '',
};

type TeacherData = Partial<TeacherAttributes>;

const TeacherProfileForm = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data: cognitoUser, isLoading } = useUser();
  const userId = cognitoUser?.getUsername() ?? '';

  const { data: teacher } = useTeacher(userId);
  const { updateTeacher, isLoading: isUpdating } = useUpdateTeacher();
  const {
    profilePicture,
    uploadProfilePicture,
    setProfilePicture,
    isLoading: isUploading,
  } = useProfilePicture(teacher?.profilePicture);

  const handleSubmit = async (data: TeacherData) => {
    const picturePath = await uploadProfilePicture();

    updateTeacher({
      id: userId,
      attributes: omitBy(
        { ...data, profilePicture: picturePath },
        (v) => typeof v === 'undefined',
      ),
    });
  };

  const { languages, locales } = useIso(teacher?.locale);

  if (isLoading || !teacher) return <LoadingState />;

  const values = omitBy(
    {
      givenName: teacher.givenName,
      familyName: teacher.familyName,
      email: teacher.email,
      locale: teacher.locale,
      about: teacher.about,
      nativeLanguage: teacher.nativeLanguage,
      timezone: teacher.timezone,
    },
    isNil,
  );

  const isUpdatingUser = isUpdating || isUploading;

  return (
    <>
      <Container>
        <Box mt={16} mb={30} width={{ lg: '50%' }}>
          <Typography mb={10} variant="h3">
            <FormattedMessage id="profile.title" />
          </Typography>
          <Form<TeacherData>
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            values={values}
            unsavedChanges
          >
            <Box mb={10}>
              <Stack gap={4} mb={4} direction="column">
                <Typography variant="h5">
                  <FormattedMessage id="profile.info.title" />
                </Typography>
                <FormTextField
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.given_name' })}
                  name="givenName"
                />
                <FormTextField
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.family_name' })}
                  name="familyName"
                />
                <FormTextField
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  disabled
                  label={intl.formatMessage({ id: 'label.email' })}
                  name="email"
                />
                <FormAutocomplete
                  required
                  rules={{
                    required: {
                      value: true,
                      message: 'field.error.required',
                    },
                  }}
                  label={intl.formatMessage({ id: 'label.mother_tongue' })}
                  name="nativeLanguage"
                  options={languages}
                />
              </Stack>
              <Box>
                <Button onClick={() => setOpen(true)} variant="outlined">
                  <FormattedMessage id="label.change_password" />
                </Button>
              </Box>
            </Box>
            <TeacherFields
              profilePicture={profilePicture}
              onProfilePictureChange={setProfilePicture}
            />
            <Stack gap={4} my={10} direction="column">
              <Typography variant="h5">
                <FormattedMessage id="profile.settings.title" />
              </Typography>
              <FormAutocomplete
                required
                disabled
                name="timezone"
                noOptionsText={intl.formatMessage({
                  id: 'timezone.select.empty',
                })}
                label={intl.formatMessage({ id: 'label.timezone' })}
                options={TIMEZONE_OPTIONS}
              />
              <FormSelect
                required
                rules={{
                  required: {
                    value: true,
                    message: 'field.error.required',
                  },
                }}
                label={intl.formatMessage({ id: 'label.main_language' })}
                name="locale"
                options={locales}
              />
            </Stack>
            <Box>
              <Typography variant="h5" mb={4}>
                <FormattedMessage id="label.default_availabilities" />
              </Typography>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    generatePath(
                      `${Routes.Availabilities}/${Routes.DefaultAvailabilities}`,
                    ),
                  )
                }
              >
                <FormattedMessage id="label.edit_default_availabilities" />
              </Button>
            </Box>
            <BottomBar>
              <LoadingButton
                loading={isUpdatingUser}
                type="submit"
                variant="contained"
              >
                <FormattedMessage id="label.save_changes" />
              </LoadingButton>
            </BottomBar>
          </Form>
        </Box>
      </Container>
      <ChangePasswordDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default TeacherProfileForm;
