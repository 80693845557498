import { Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import history from 'services/history';

import { Dialog } from 'components/@common';

interface Props {
  onClose?(): void;
  onConfirm(): void;
  when: boolean;
}

const UnsavedChangesDialog: React.FC<Props> = ({
  onClose,
  onConfirm,
  when,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    let unblock: () => void | undefined;
    if (when) {
      unblock = history.block((prompt) => {
        setCurrentPath(prompt.location.pathname);
        setShowPrompt(true);
      });
    }

    return () => {
      if (unblock) unblock();
    };
  }, [when]);

  const handleClose = async () => {
    if (onClose) {
      await Promise.resolve(onClose());
    }
    setShowPrompt(false);
  };

  const handleConfirm = async () => {
    await Promise.resolve(onConfirm());
    setShowPrompt(false);
    navigate(currentPath);
  };

  return (
    <Dialog
      open={showPrompt}
      onClose={() => setShowPrompt(false)}
      title={intl.formatMessage({ id: 'dialog.unsaved_changes.title' })}
    >
      <Stack direction="column" height="100%" justifyContent="space-between">
        <Typography>
          <FormattedMessage id="dialog.unsaved_changes.description" />
        </Typography>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent="flex-end"
          gap={4}
          mt={6}
        >
          <Button variant="outlined" onClick={handleClose}>
            <FormattedMessage id="label.cancel" />
          </Button>
          <Button variant="contained" color="error" onClick={handleConfirm}>
            <FormattedMessage id="label.continue" />
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default UnsavedChangesDialog;
