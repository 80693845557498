import { isSameWeek, parse } from 'date-fns';

import {
  AvailabilityStatus,
  SelectedTimeslots,
} from 'types/availabilities.types';

export const getAvailableAmount = (
  currentDate: Date,
  timeslots: SelectedTimeslots,
) => {
  return Object.entries(timeslots).reduce((acc, [key, curr]) => {
    const parsedDate = parse(key, 'yyyy-MM-dd', new Date());

    if (isSameWeek(currentDate, parsedDate)) {
      acc =
        acc +
        Object.values(curr).filter(
          (status) =>
            status === AvailabilityStatus.AVAILABLE ||
            status === AvailabilityStatus.BOOKED,
        ).length;
    }

    return acc;
  }, 0);
};
