import { Box, Chip, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  type: 'specific' | 'default';
  day: string;
  amountSelected?: { available: number; booked?: number };
  onClear?(): void;
  onRemoveSelected?(): void;
  onSetDefault?(): void;
  disableAction?: boolean;
}

const DayItem: React.FC<Props> = ({
  type,
  day,
  amountSelected,
  onRemoveSelected,
  onSetDefault,
  onClear,
  disableAction,
}) => {
  const intl = useIntl();

  return (
    <Box textAlign="center" key={day} width="100%">
      <Typography mb={2}>{day}</Typography>
      <Box height={32}>
        {(!!amountSelected?.available || !!amountSelected?.booked) && (
          <Tooltip
            followCursor
            disableHoverListener={type === 'default'}
            placement="top"
            title={intl.formatMessage(
              { id: 'availabilities.day.indicator.tooltip' },
              {
                planned: amountSelected.booked,
                available: amountSelected.available,
              },
            )}
          >
            <Chip
              label={intl.formatMessage(
                { id: 'label.class_hours.amount' },
                {
                  amount:
                    amountSelected.available + (amountSelected.booked ?? 0),
                },
              )}
              onDelete={type === 'specific' ? undefined : onClear}
            />
          </Tooltip>
        )}
      </Box>
      {type === 'specific' && !disableAction && (
        <>
          {!!amountSelected?.available ? (
            <Link
              component="button"
              onClick={onRemoveSelected}
              underline="always"
            >
              <FormattedMessage id="availabilities.action.remove_hours" />
            </Link>
          ) : (
            <Link component="button" underline="always" onClick={onSetDefault}>
              <FormattedMessage id="availabilities.action.set_default" />
            </Link>
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(DayItem);
