import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import { GetUsersParams, User } from 'types/user.types';

const getUsers = async (params: GetUsersParams) => {
  const { data } = await apiService.getUsers(params);

  return deserialize<User>(data);
};

export const useUsers = (
  params: GetUsersParams,
  config?: UseQueryOptions<DeserializedArrayResponse<User>>,
) => {
  const { data, isLoading, isFetching } = useQuery(
    QueryKeys.user.all(params),
    () => getUsers(params),
    { ...config, keepPreviousData: true },
  );

  return {
    ...getListData(data),
    isLoading,
    isFetching,
  };
};
