import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { addDays, format } from 'date-fns';

import { isTimeslotDisabled } from 'utils/timeslot.utils';

import { DEFAULT_TIMEZONE } from 'constants/timezones';
import {
  AvailabilitiesState,
  AvailabilityStatus,
  SelectedTimeslotByDay,
  SelectedTimeslots,
} from 'types/availabilities.types';

interface Args {
  name: string;
  initialState: AvailabilitiesState;
  extraReducers?: SliceCaseReducers<AvailabilitiesState>;
}

const createAvailabilitiesSlice = ({
  name,
  initialState,
  extraReducers,
}: Args) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      SET_UNSAVED_CHANGES: (state, { payload }: PayloadAction<boolean>) => {
        state.unsavedChanges = payload;
      },
      UPDATE_TIMESLOT: (
        state,
        {
          payload,
        }: PayloadAction<{
          day: string | number;
          timeslot: string;
        }>,
      ) => {
        const currentDay = state.selectedTimeslots[payload.day];
        if (!currentDay) {
          state.selectedTimeslots = {
            ...state.selectedTimeslots,
            [payload.day]: {
              [payload.timeslot]: AvailabilityStatus.AVAILABLE,
            },
          };
        } else {
          const status =
            state.selectedTimeslots[payload.day][payload.timeslot] ===
            AvailabilityStatus.AVAILABLE
              ? AvailabilityStatus.UNAVAILABLE
              : AvailabilityStatus.AVAILABLE;

          state.selectedTimeslots[payload.day] = {
            ...state.selectedTimeslots[payload.day],
            [payload.timeslot]: status,
          };
        }
      },
      SET_TIMESLOTS: (state, { payload }: PayloadAction<SelectedTimeslots>) => {
        state.selectedTimeslots = {
          ...state.selectedTimeslots,
          ...payload,
        };
      },
      CLEAR_TIMESLOTS: (state, { payload }: PayloadAction<string>) => {
        const newSelected = Object.keys(
          state.selectedTimeslots[payload],
        ).reduce((acc, curr) => {
          if (
            acc[curr] !== AvailabilityStatus.BOOKED &&
            !isTimeslotDisabled(payload, curr)
          ) {
            acc[curr] = AvailabilityStatus.UNAVAILABLE;
          }

          return acc;
        }, state.selectedTimeslots[payload]);

        state.selectedTimeslots[payload] = newSelected;
      },
      SET_AVAILABILITIES: (
        state,
        { payload }: PayloadAction<Partial<AvailabilitiesState>>,
      ) => ({
        ...state,
        ...payload,
      }),
      RESET_TO_DEFAULT_AVAILABILITIES: (
        state,
        {
          payload,
        }: PayloadAction<{ day: string; initial: SelectedTimeslotByDay }>,
      ) => {
        return {
          ...state,
          selectedTimeslots: {
            ...state.selectedTimeslots,
            [payload.day]: payload.initial,
          },
        };
      },
      RESET_AVAILABILITIES: () => initialState,
    },
    ...extraReducers,
  });
};

const initialState: AvailabilitiesState = {
  selectedTimeslots: {},
  maxBookedSlots: 0,
  timezone: DEFAULT_TIMEZONE,
  startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
  unsavedChanges: false,
};

const defaultAvailabilitiesSlice = createAvailabilitiesSlice({
  name: 'default-availabilities',
  initialState,
});

const specificAvailabilitiesSlice = createAvailabilitiesSlice({
  name: 'specific-availabilities',
  initialState,
  extraReducers: {
    RESET_TO_DEFAULT_AVAILABILITIES: (
      state,
      {
        payload,
      }: PayloadAction<{ day: string; initial: SelectedTimeslotByDay }>,
    ) => {
      return {
        ...state,
        selectedTimeslots: {
          ...state.selectedTimeslots,
          [payload.day]: payload.initial,
        },
      };
    },
  },
});

const defaultAvailabilitiesActions = defaultAvailabilitiesSlice.actions;
const specificAvailabilitiesActions = specificAvailabilitiesSlice.actions;

const defaultAvailabilitiesReducer = defaultAvailabilitiesSlice.reducer;
const specificAvailabilitiesReducer = specificAvailabilitiesSlice.reducer;

export {
  defaultAvailabilitiesActions,
  defaultAvailabilitiesReducer,
  initialState,
  specificAvailabilitiesActions,
  specificAvailabilitiesReducer,
};
