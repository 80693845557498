import { isTeacher } from 'utils/user.utils';

import { BROWSER_TIMEZONE } from 'constants/timezones';
import { useTeacher, useUser } from 'queries';

export const useUserTimezone = () => {
  const { data: user, userId } = useUser();

  const { timezone } = useTeacher(userId, {
    enabled: !!userId && isTeacher(user),
  });

  return isTeacher(user) ? timezone : BROWSER_TIMEZONE;
};
