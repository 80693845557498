import { Components } from '@mui/material';

export const MuiAlertTitle: Components['MuiAlertTitle'] = {
  styleOverrides: {
    root: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
};
