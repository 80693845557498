import {
  Avatar,
  FilledTextFieldProps,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/@common';

type Props = Pick<UseControllerProps, 'name'> &
  Omit<FilledTextFieldProps, 'variant' | 'name' | 'type'> & {
    showValidation?: boolean;
  };

const commonStyle: SxProps<Theme> = {
  width: 24,
  height: 24,
};

const validStyle: SxProps<Theme> = {
  ...commonStyle,
  backgroundColor: 'yellow.200',
};

const invalidStyle: SxProps<Theme> = {
  ...commonStyle,
  backgroundColor: 'neutral.100',
  border: '1px solid',
  borderColor: 'neutral.600',
};

const pattern = /^(?=.*[A-Z])(?=.*[a-zA-Z]).*$/;

const FormTextField: React.FC<Props> = ({
  name,
  label,
  showValidation = true,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={{
        required: true,
        pattern,
        minLength: 8,
      }}
      control={control}
      name={name}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => {
        return (
          <>
            <TextField
              label={label}
              fullWidth
              value={value}
              type="password"
              {...field}
              inputRef={ref}
              error={!!error}
              {...rest}
            />
            {showValidation && (
              <Stack mt={4} mb={6} columnGap={6}>
                <Stack columnGap={2} alignItems="center">
                  <Avatar sx={value.length >= 8 ? validStyle : invalidStyle}>
                    <Icon name="IcoCheck" width={15} />
                  </Avatar>
                  <Typography variant="caption">
                    <FormattedMessage id="validation.min_length" />
                  </Typography>
                </Stack>
                <Stack columnGap={2} alignItems="center">
                  <Avatar sx={pattern.test(value) ? validStyle : invalidStyle}>
                    <Icon name="IcoCheck" width={15} />
                  </Avatar>
                  <Typography variant="caption">
                    <FormattedMessage id="validation.capital" />
                  </Typography>
                </Stack>
              </Stack>
            )}
          </>
        );
      }}
    />
  );
};

export default FormTextField;
