import { Box, Stack } from '@mui/material';
import {
  addHours,
  addMonths,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  parse,
  set,
} from 'date-fns';
import _isEqual from 'lodash/isEqual';
import React from 'react';

import {
  AvailabilityStatus,
  SelectedTimeslotByDay,
  Timeslot as TimeslotType,
} from 'types/availabilities.types';

import { timeslots } from '../constants';
import { Timeslot } from '../Timeslot';

interface Props {
  onSelectTimeslot(timeslot: TimeslotType): void;
  selectedTimeslots: SelectedTimeslotByDay;
  day: string;
  disablePast?: boolean;
}

const TimeslotList: React.FC<Props> = ({
  onSelectTimeslot,
  selectedTimeslots,
  day,
  disablePast,
}) => {
  const isDisabled = (timeslot: TimeslotType) => {
    const date = parse(day, 'yyyy-MM-dd', new Date());
    const timeslotDate = parse(timeslot.timeStart, 'HH:mm', new Date());
    const hours = getHours(timeslotDate);
    const minutes = getMinutes(timeslotDate);
    const fullDate = set(date, { hours, minutes });

    const isDayBefore = isBefore(fullDate, addHours(new Date(), 24));
    const isThreeMonthsAfter = isAfter(fullDate, addMonths(new Date(), 3));

    return isDayBefore || isThreeMonthsAfter;
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      width={{ xs: '100%', lg: `${100 / 7}%` }}
      px="2px"
      height="100%"
    >
      {timeslots.map((timeslot, index) => (
        <Box py="2px" width="100%" key={`timeslot-${day}-${index}`}>
          <Timeslot
            disabled={disablePast && isDisabled(timeslot)}
            onSelect={onSelectTimeslot}
            timeslot={timeslot}
            status={
              selectedTimeslots?.[timeslot.timeStart] ??
              AvailabilityStatus.UNAVAILABLE
            }
          />
        </Box>
      ))}
    </Stack>
  );
};

export default React.memo(TimeslotList, (prev, next) => {
  return _isEqual(prev.selectedTimeslots, next.selectedTimeslots);
});
