import { FilledTextFieldProps, TextField } from '@mui/material';
import { useState } from 'react';
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<FilledTextFieldProps, 'variant' | 'name'>;

const FormTextField: React.FC<Props> = ({
  rules,
  name,
  label,
  helperText,
  ...rest
}) => {
  const { control } = useFormContext();
  const intl = useIntl();
  const [isFocused, setIsFocused] = useState(false);

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }

    if (error?.message) return intl.formatMessage({ id: error.message });
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { ref, name, value, onChange },
        fieldState: { error },
      }) => (
        <TextField
          label={label}
          fullWidth
          name={name}
          value={value}
          InputLabelProps={{ shrink: value || isFocused ? true : false }}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          inputRef={ref}
          error={!!error}
          helperText={getHelperText(error)}
          {...rest}
        />
      )}
    />
  );
};

export default FormTextField;
