import {
  FormControl,
  FormControlProps,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Input, Label } from './Select.style';

export type Option = {
  label: string;
  value: string | number;
};

interface Props extends Omit<FormControlProps, 'onChange'> {
  label: string;
  value: string | number;
  options: Option[];
  name?: string;
  onChange(event: SelectChangeEvent<unknown>): void;
  required?: boolean;
  translated?: boolean;
  helperText?: string;
}

const Select: React.FC<Props> = ({
  label,
  value,
  options,
  onChange,
  fullWidth,
  required,
  margin,
  translated,
  name,
  helperText,
  ...rest
}) => {
  return (
    <FormControl {...rest} margin={margin} fullWidth={fullWidth}>
      <Label required={required} id="select-label">
        {label}
      </Label>
      <MuiSelect
        name={name}
        variant="filled"
        required={required}
        labelId="select-label"
        value={value}
        label={label}
        onChange={onChange}
        input={<Input />}
        MenuProps={{
          sx: {
            maxHeight: 200,
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`select-option-${option.value}-${index}`}
            value={option.value}
          >
            {translated ? <FormattedMessage id={option.label} /> : option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {!!rest.error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
