import posthog from 'posthog-js';

import { getPrimaryRole } from 'utils/user.utils';

import { useUser } from 'queries';
import { PHEvents } from 'services';

interface TrackEventParams {
  event: keyof typeof PHEvents;
  properties?: Record<string, unknown>;
  withRole?: boolean;
}

export const useTrackEvent = () => {
  const { data: user } = useUser();

  const trackEvent = ({ event, properties, withRole }: TrackEventParams) => {
    let eventProperties = properties;

    if (eventProperties && withRole) {
      eventProperties.user_role = getPrimaryRole(user);
    }

    if (!eventProperties && withRole) {
      eventProperties = {
        user_role: getPrimaryRole(user),
      };
    }

    posthog.capture(PHEvents[event], eventProperties);
  };

  return trackEvent;
};
