import { Box, Stack, Typography } from '@mui/material';
import { getNames } from 'i18n-iso-countries';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getLocale, useAppSelector } from 'store';

import { FormAutocomplete, FormTextField } from 'components/@form';

const OrdererFields = () => {
  const intl = useIntl();
  const locale = useAppSelector(getLocale);

  const countries = Object.entries(getNames(locale)).map(([code, country]) => ({
    label: country,
    value: code,
  }));

  return (
    <Stack direction="column" gap={4}>
      <Typography variant="h5">
        <FormattedMessage id="label.billing" />
      </Typography>
      <FormTextField
        name="company"
        label={intl.formatMessage({ id: 'label.company' })}
      />
      <FormTextField
        name="billing.vatNumber"
        label={intl.formatMessage({ id: 'label.vat_number' })}
      />
      <Stack gap={4}>
        <FormTextField
          name="billing.street"
          label={intl.formatMessage({ id: 'label.street' })}
        />
        <Box width={172}>
          <FormTextField
            name="billing.number"
            label={intl.formatMessage({ id: 'label.house_number' })}
          />
        </Box>
      </Stack>
      <Stack gap={4}>
        <Box width={180}>
          <FormTextField
            name="billing.zipcode"
            label={intl.formatMessage({ id: 'label.zip_code' })}
          />
        </Box>
        <FormTextField
          name="billing.city"
          label={intl.formatMessage({ id: 'label.city' })}
        />
      </Stack>
      <FormAutocomplete
        options={countries}
        name="billing.country"
        label={intl.formatMessage({ id: 'label.country' })}
      />
      <FormTextField
        name="billing.phoneNumber"
        required
        rules={{
          required: {
            value: true,
            message: 'field.error.required',
          },
        }}
        label={intl.formatMessage({ id: 'label.phone' })}
      />
    </Stack>
  );
};

export default OrdererFields;
