import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import { GetTeachersParams, Teacher } from 'types/teacher.types';

const getTeachers = async (params: GetTeachersParams) => {
  const { data } = await apiService.getTeachers(params);

  return deserialize<Teacher>(data);
};

export const useTeachers = (
  params: GetTeachersParams,
  config?: UseQueryOptions<DeserializedArrayResponse<Teacher>>,
) => {
  const { data, isLoading, isFetching } = useQuery(
    QueryKeys.teacher.all(params),
    () => getTeachers(params),
    { keepPreviousData: true, ...config },
  );

  return {
    ...getListData(data),
    isLoading,
    isFetching,
  };
};
