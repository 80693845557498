import { JsonApiResponse } from 'deserialize-json-api';

import { getTimezoneHeader } from 'utils/headers.utils';

import {
  MatchLessonParams,
  MatchLessonsMeta,
  PresignedUrl,
  PresignedUrlParams,
} from 'types/internal.types';
import { Resources } from 'types/jsonapi.types';
import { Teacher } from 'types/teacher.types';

import RestApiService from './';

export default {
  requestPresignedUrl(this: RestApiService, params: PresignedUrlParams) {
    return this.api.post<PresignedUrl>('/request-presigned-url', params);
  },
  matchTeacher(this: RestApiService, attributes: MatchLessonParams) {
    return this.api.post<JsonApiResponse<Teacher, MatchLessonsMeta>>(
      '/match-teacher',
      { data: { type: Resources.LESSONS, attributes } },
      {
        headers: getTimezoneHeader(),
      },
    );
  },
};
