import { Components, Theme } from '@mui/material';

export const MuiCard: Components<Theme>['MuiCard'] = {
  defaultProps: {
    elevation: 0,
  },

  styleOverrides: {
    root: {
      borderRadius: 0,
    },
  },
};
