import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<CheckboxProps, 'onChange' | 'value'> & { label: React.ReactNode };

const FormCheckbox: React.FC<Props> = ({ rules, name, label, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          label={label}
          control={<Checkbox onChange={onChange} checked={value} {...rest} />}
        />
      )}
    />
  );
};

export default FormCheckbox;
