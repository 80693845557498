import { AxiosResponse } from 'axios';
import { JsonApiArrayResponse, JsonApiResponse } from 'deserialize-json-api';

import { Resources } from 'types/jsonapi.types';
import type {
  CreateUserParams,
  GetUsersParams,
  UpdateUserParams,
  User,
} from 'types/user.types';

import RestApiService from './';

export default {
  getUser(this: RestApiService, userId: User['id']) {
    return this.api.get<JsonApiResponse<User>>(`/users/${userId}`);
  },
  createUser(this: RestApiService, params: CreateUserParams) {
    return this.api.post<CreateUserParams, AxiosResponse<User>>(`/users`, {
      data: { type: Resources.USERS, attributes: params },
    });
  },
  createAdmin(this: RestApiService, params: CreateUserParams) {
    return this.api.post<CreateUserParams>(`/admins`, {
      data: { type: Resources.USERS, attributes: params },
    });
  },
  deleteUser(this: RestApiService, userId: User['id']) {
    return this.api.delete(`/users/${userId}`);
  },
  updateUser(
    this: RestApiService,
    userId: User['id'],
    params: UpdateUserParams,
  ) {
    return this.api.patch<UpdateUserParams, AxiosResponse<User>>(
      `/users/${userId}`,
      { data: { type: Resources.USERS, attributes: params } },
    );
  },
  getUsers(this: RestApiService, params: GetUsersParams) {
    return this.api.get<JsonApiArrayResponse<User>>(`/users`, { params });
  },
};
