import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { getCharactersLeft } from 'utils/input.utils';
import { isTeacher } from 'utils/user.utils';

import { useUser } from 'queries';

import { ImageUpload } from 'components/@common';
import { FormTextField, Validations } from 'components/@form';

interface Props {
  profilePicture?: File | string;
  onProfilePictureChange(file: File | undefined): void;
}
const MAX_LENGTH = 255;

const TeacherFields: React.FC<Props> = ({
  profilePicture,
  onProfilePictureChange,
}) => {
  const intl = useIntl();
  const { data: user } = useUser();
  const { watch } = useFormContext();
  if (!isTeacher(user)) return null;

  return (
    <Box mt={10}>
      <Typography mb={4} variant="h5">
        <FormattedMessage id="label.about" />
      </Typography>
      <FormTextField
        required
        multiline
        rules={{
          required: Validations?.required,
          maxLength: {
            value: MAX_LENGTH,
            message: intl.formatMessage(
              { id: 'field.error.max_length' },
              { amount: MAX_LENGTH },
            ),
          },
        }}
        helperText={intl.formatMessage(
          { id: 'field.characters_left' },
          {
            amount: getCharactersLeft(watch('about')?.length, MAX_LENGTH),
          },
        )}
        rows={4}
        label={intl.formatMessage({ id: 'label.about' })}
        name="about"
      />
      <Stack mt={10} mb={4} gap={2} alignItems="baseline">
        <Typography variant="h5">
          <FormattedMessage id="label.profile_picture" />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="label.optional" />
        </Typography>
      </Stack>
      <ImageUpload
        image={profilePicture}
        onChange={onProfilePictureChange}
        maxFileSize={5}
      />
    </Box>
  );
};

export default TeacherFields;
