import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  FormProvider,
  Resolver,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

import { UnsavedChangesDialog } from 'components/@dialog';

export type SubmitHandler<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  event: React.BaseSyntheticEvent,
  methods: UseFormReturn<TFieldValues>,
) => void;

interface Props<FormData extends FieldValues> {
  onSubmit: SubmitHandler<FormData>;
  resolver?: Resolver<FormData>;
  defaultValues?: DefaultValues<FormData>;
  values?: DeepPartial<FormData>;
  children:
    | React.ReactNode
    | ((methods: UseFormReturn<FormData>) => React.ReactNode);
  enableDevTools?: boolean;
  unsavedChanges?: boolean;
  onDiscard?(): void;
}

const Form = <FormData extends FieldValues>({
  defaultValues,
  onSubmit,
  children,
  values,
  enableDevTools,
  resolver,
  unsavedChanges,
  onDiscard,
}: Props<FormData>) => {
  const { reset, ...methods } = useForm<FormData>({
    resolver,
    defaultValues,
    mode: 'onChange',
  });
  const [fieldsSet, setFieldsSet] = useState(false);
  useEffect(() => {
    if (values && !fieldsSet) {
      reset(values);
      setFieldsSet(true);
    }
  }, [fieldsSet, reset, values]);

  return (
    <FormProvider reset={reset} {...methods}>
      <form
        noValidate
        onSubmit={methods.handleSubmit((data, event) =>
          event ? onSubmit(data, event, { ...methods, reset }) : undefined,
        )}
      >
        {children instanceof Function
          ? children({ ...methods, reset })
          : children}
      </form>
      {unsavedChanges && (
        <UnsavedChangesDialog
          when={
            methods.formState.isDirty && !methods.formState.isSubmitSuccessful
          }
          onConfirm={() => {
            reset();
            onDiscard?.();

            return true;
          }}
        />
      )}
      {enableDevTools && <DevTool control={methods.control} />}
    </FormProvider>
  );
};

export default Form;
