import {
  Controller,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';

import { RadioGroup } from 'components/@inputs';

type Props = Pick<UseControllerProps, 'rules' | 'name'> & {
  options: { label: string; value: string | number }[];
  row?: boolean;
  className?: string;
};

const FormRadioGroup: React.FC<Props> = ({
  rules,
  name,
  options,
  row,
  className,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          row={row}
          className={className}
          options={options}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

export default FormRadioGroup;
