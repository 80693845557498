import React from 'react';

import { createRoute } from 'utils/router.utils';

import { Routes } from 'pages/routes.constants';

import ChangeTeacher from './ChangeTeacher';
import CourseDetail from './CourseDetail';
import CoursePlanned from './CoursePlanned';
import EditLesson from './EditLesson';
import EditLessonOverview from './EditLessonOverview';
import EvaluateCourse from './EvaluateCourse';
import EvaluateLesson from './EvaluateLesson';
import Overview from './Overview';
import PlanCourse from './Plan';
/**
 * use lazy imports to get code splitting out-of-the-box
 * you can use "webpackChunkName" to name this chunk
 */
// tslint:disable:space-in-parens
const componentPromise = import('./Courses' /* webpackChunkName: "courses" */);

const Component = React.lazy(() => componentPromise);
// tslint:enable

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default createRoute(Component, {
  path: Routes.Courses,
  authenticated: true,
  children: [
    PlanCourse,
    Overview,
    CourseDetail,
    CoursePlanned,
    EditLesson,
    EditLessonOverview,
    EvaluateLesson,
    EvaluateCourse,
    ChangeTeacher,
  ],
});
