import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification, useTrackEvent } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { CourseEvaluation } from 'types/course.types';

interface Variables {
  courseId: string;
  params: CourseEvaluation;
}

const updateCourseEvaluation = async ({ courseId, params }: Variables) =>
  apiService.updateCourseEvaluation(courseId, params);

export const useUpdateCourseEvaluation = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const trackEvent = useTrackEvent();

  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(updateCourseEvaluation, {
    onSuccess: (_, { courseId }) => {
      trackEvent({ event: 'EVALUATIONS_COURSE', withRole: true });

      queryClient.invalidateQueries(QueryKeys.courses.evaluation(courseId));
      queryClient.invalidateQueries(QueryKeys.courses.byId(courseId));
      queryClient.invalidateQueries(QueryKeys.courses.all(), {
        refetchType: 'none',
      });

      showNotification({
        message: intl.formatMessage({ id: 'evaluate.submit.success' }),
        variant: 'success',
      });
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),
        variant: 'error',
      });
    },
  });

  return { updateCourseEvaluation: mutate, isLoading };
};
