import { Components, inputBaseClasses, Theme } from '@mui/material';

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
    InputProps: {
      disableUnderline: true,
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.neutral[100],
      borderColor: theme.palette.neutral[400],
      borderRadius: 0,
      color: theme.palette.neutral[800],
      [`&.${inputBaseClasses.focused}`]: {
        borderColor: theme.palette.neutral[800],
        borderWidth: 1,
      },
      '&:hover': {
        borderColor: theme.palette.neutral[800],
      },
    }),
  },
};
