import { useIntl } from 'react-intl';

export const useLanguage = () => {
  const intl = useIntl();
  const getLanguage = (code: string) => {
    if (code.toLowerCase() === 'all') {
      return intl.formatMessage({ id: 'label.all' });
    }
    if (code.toLowerCase() === 'pb') {
      return intl.formatMessage({ id: 'languages.pb' });
    }

    return intl.formatDisplayName(code, {
      type: 'language',
    });
  };

  return getLanguage;
};
