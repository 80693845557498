import { AppBar, Container, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

import { Icon, LanguageMenu } from 'components/@common';

import { LogoWrap } from './Navigation.style';

const NavigationUnauthenticated = () => {
  const navigate = useNavigate();

  return (
    <AppBar
      sx={(theme) => ({
        borderBottom: {
          xs: `1px solid ${theme.palette.neutral[400]}`,
          lg: 'none',
        },
      })}
      position="relative"
    >
      <Container>
        <Stack alignItems="center" justifyContent="space-between">
          <LogoWrap>
            <Icon
              name="LogoBlack"
              onClick={() => navigate(Routes.Root)}
              sx={{ cursor: 'pointer' }}
            />
          </LogoWrap>
          <LanguageMenu />
        </Stack>
      </Container>
    </AppBar>
  );
};

export default NavigationUnauthenticated;
