import './posthog';
import './date-fns';
import './sentry';

import config from 'config';

import RestApiService from './api';
import AuthApiService from './api/auth';
import { createManager } from './api/axios';

// You can export here services, ES module exports ensure these will all be a single object
export const apiService = new RestApiService({
  api: createManager({ baseURL: config.API_BASE }),
});

export const authApiService = new AuthApiService({
  api: createManager({ baseURL: config.API_BASE_AUTH }),
});

export * from './amplify';
export * from './date-fns';
export * from './datepicker';
export * from './notifications';
export * from './posthog';
export * from './sentry';
