import { useQuery } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { parseTimeslotStatuses } from 'utils/availability.utils';

import { useTeacher } from 'queries';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { DefaultAvailabilities } from 'types/availabilities.types';
import { Teacher } from 'types/teacher.types';

import { useAvailabilities } from 'components/@availabilities';

const getDefaultAvailabilities = async (id: Teacher['id']) => {
  const response = await apiService.getDefaultAvailabilities(id);

  return deserialize<DefaultAvailabilities>(response.data);
};

export const useDefaultAvailabilities = (
  teacherId: Teacher['id'],
  config?: UseCommonQueryOptions<DeserializedResponse<DefaultAvailabilities>>,
) => {
  const {
    data: teacher,
    isError: isErrorTeacher,
    isLoading: isLoadingTeacher,
  } = useTeacher(teacherId);
  const { setAvailabilities } = useAvailabilities('default');
  const {
    data,
    isLoading: isLoadingDefault,
    isSuccess,
    status,
    isError: isErrorDefault,
    isFetching,
    error,
  } = useQuery(
    QueryKeys.availabilities.default(teacherId),
    () => getDefaultAvailabilities(teacherId),
    {
      enabled: !!teacher,
      staleTime: 0,
      retry: (_, error) => error.response?.status !== 404,
      onSuccess: ({ data }) => {
        setAvailabilities({
          timezone: teacher?.timezone ? teacher.timezone : undefined,
          maxBookedSlots: data.maxBookedSlots,
          selectedTimeslots: data.defaultAvailabilityTimeslots
            ? parseTimeslotStatuses(data.defaultAvailabilityTimeslots)
            : {},
        });
      },
      ...config,
    },
  );

  return {
    data: data?.data,
    error,
    isLoading: isLoadingTeacher || isLoadingDefault,
    isSuccess,
    isError: isErrorDefault || isErrorTeacher,
    status,
    isFetching,
  };
};
