import { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  defaultProps: {
    maxWidth: 'lg',
    disableGutters: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      paddingLeft: 0,
      paddingRight: 0,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
    }),
    disableGutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    maxWidthLg: ({ theme }) => ({
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1152,
      },
    }),
  },
};
