import * as Sentry from '@sentry/react';
import { FC } from 'react';

import { ErrorLayout } from 'components/@common';

const RouteBoundary: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag('boundary', 'pages')}
    fallback={
      <ErrorLayout
        title="page.general.error.title"
        description="page.general.error.description"
      />
    }
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default RouteBoundary;
