import { Button, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';

import { testId } from 'utils/test-id-generator.utils';

import { locales } from 'services/i18n';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { intlActions } from 'store/intl/intl.slice';

import { Icon } from '../Icon';

const LanguageMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector((state) => state.intl.locale);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetLanguage = (lang: string) => {
    dispatch(intlActions.LOCALE_UPDATE(lang));

    handleClose();
  };

  return (
    <div>
      <Button
        id="language-menu-button"
        variant="outlined"
        color="inherit"
        startIcon={
          <Icon
            name="IcoFlag"
            sx={(theme) => ({
              fill: {
                xs: theme.palette.neutral[800],
                lg: theme.palette.neutral[100],
              },
            })}
          />
        }
        endIcon={
          <Icon
            name="IcoChevronDown"
            width={10}
            height={5}
            sx={(theme) => ({
              fill: {
                xs: theme.palette.neutral[800],
                lg: theme.palette.neutral[100],
              },
            })}
          />
        }
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        sx={{
          color: { xs: 'neutral.800', lg: 'neutral.100' },
          borderColor: { xs: 'neutral.800', lg: 'neutral.100' },
          backgroundColor: { xs: 'neutral.100', lg: 'transparent' },
        }}
        {...testId('language-menu-button')}
      >
        {locale.toUpperCase()}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            width: 96,
          },
        }}
      >
        {locales.map((lang) => (
          <MenuItem
            key={lang}
            onClick={() => handleSetLanguage(lang)}
            {...testId('language-menu', lang)}
          >
            {lang.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageMenu;
