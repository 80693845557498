import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { CreateUserParams } from 'types/user.types';

const createUser = (data: CreateUserParams) => apiService.createUser(data);

export const useCreateUser = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.user.all());
    },
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return { createUser: mutate, isLoading };
};
