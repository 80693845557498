import { Checkbox, FormControlLabel, Theme, Tooltip } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { testId } from 'utils/test-id-generator.utils';

import {
  AvailabilityStatus,
  Timeslot as TimeslotType,
} from 'types/availabilities.types';

import { Icon, IconNames } from 'components/@common';

interface Props {
  timeslot: TimeslotType;
  onSelect(timeslot: TimeslotType): void;
  status: AvailabilityStatus;
  disabled?: boolean;
}

const selectedStatuses = [
  AvailabilityStatus.AVAILABLE,
  AvailabilityStatus.BOOKED,
];

const Timeslot: React.FC<Props> = ({
  timeslot,
  onSelect,
  status,
  disabled,
}) => {
  const intl = useIntl();
  const handleSelect = () => onSelect(timeslot);

  const checkedIcon: IconNames =
    status === AvailabilityStatus.AVAILABLE
      ? 'IcoCircleCheckActive'
      : 'IcoCalendarCheck';

  const selected = selectedStatuses.includes(status);

  const getBackgroundColor = (theme: Theme) => {
    switch (status) {
      case AvailabilityStatus.AVAILABLE:
        return theme.palette.yellow[200];
      case AvailabilityStatus.BOOKED:
        return theme.palette.yellow[100];
      case AvailabilityStatus.UNAVAILABLE:
        return undefined;
    }
  };

  return (
    <Tooltip
      disableHoverListener={status !== AvailabilityStatus.BOOKED}
      title={intl.formatMessage({ id: 'label.planned_lesson' })}
    >
      <FormControlLabel
        sx={(theme: Theme) => ({
          backgroundColor: getBackgroundColor(theme),
          width: '100%',
          marginRight: 0,
          marginLeft: 0,
          height: { xs: 56, md: 40 },
        })}
        {...testId(timeslot.timeStart)}
        label={`${timeslot.timeStart} - ${timeslot.timeEnd}`}
        control={
          <Checkbox
            onChange={handleSelect}
            checked={selected}
            disabled={status === AvailabilityStatus.BOOKED || disabled}
            icon={<Icon name="IcoCircleCheckDefault" width={20} height={20} />}
            checkedIcon={<Icon name={checkedIcon} width={20} height={20} />}
          />
        }
      />
    </Tooltip>
  );
};

export default React.memo(Timeslot);
