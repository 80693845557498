import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { UploadFileParams } from 'types/internal.types';

const uploadFile = async ({ url, fields, file }: UploadFileParams) => {
  const body = new FormData();

  Object.entries(fields).forEach(([key, value]) => {
    body.append(key, value);
  });

  body.append('file', file);

  const data = await axios.post(url, body);

  return data;
};

export const useUploadFile = () => {
  const { mutateAsync, isLoading } = useMutation(uploadFile);

  return {
    uploadFile: mutateAsync,
    isLoading,
  };
};
