import { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLocale } from 'store/intl/intl.selectors';

import { intl } from './config';

const IntlSelectorProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const locale = useSelector(getLocale);

  return (
    <IntlProvider locale={locale} messages={intl[locale].messages}>
      {children}
    </IntlProvider>
  );
};

export default IntlSelectorProvider;
