import { Stack } from '@mui/material';
import React, { Fragment, useCallback, useLayoutEffect, useRef } from 'react';

import { SelectedTimeslots, Timeslot } from 'types/availabilities.types';

import { LoadingState } from 'components/@states';

import { TimeslotHeaderDesktop } from '../TimeslotHeader';
import { TimeslotList } from '../TimeslotList';
import { CommonProps } from './types';

interface Props extends CommonProps {
  days: string[];
  selectedTimeslots: SelectedTimeslots;
  onClear?(index: number): void;
  onSetDefault?(day: string, index: number): void;
  onRemoveSelected?(day: string): void;
}

const Desktop: React.FC<Props> = ({
  type,
  days,
  selectedTimeslots,
  onSelectTimeslot,
  onClear,
  onRemoveSelected,
  onSetDefault,
  isLoading,
  disablePast,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scrollRef && !isLoading) {
      scrollRef.current?.scrollTo({
        top: 400, // 400 stands for height of a single timeslot * 9, which is the position of the timeslot of 09:00
        behavior: 'smooth',
      });
    }
  }, [isLoading]);

  const handleSelectTimeslot = useCallback(
    (day: string | number, timeslot: Timeslot) => {
      onSelectTimeslot(day, timeslot);
    },
    [onSelectTimeslot],
  );

  const isSpecific = type === 'specific';

  return (
    <Stack width="100%" direction="column" pb={3}>
      <TimeslotHeaderDesktop
        type={type}
        days={days}
        availabilities={selectedTimeslots}
        onClear={onClear}
        onRemoveSelected={onRemoveSelected}
        onSetDefault={onSetDefault}
      />
      <Stack ref={scrollRef} pb={30} flex={1} width="100%" alignItems="center">
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            {days.map((day, index) => (
              <Fragment key={`timeslot-list-${day}`}>
                <TimeslotList
                  disablePast={disablePast}
                  day={isSpecific ? day : String(index)}
                  selectedTimeslots={
                    selectedTimeslots[isSpecific ? day : index]
                  }
                  onSelectTimeslot={(timeslot) =>
                    handleSelectTimeslot(isSpecific ? day : index, timeslot)
                  }
                />
              </Fragment>
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Desktop;
