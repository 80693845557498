import { AxiosInstance } from 'axios';

interface Options {
  api: AxiosInstance;
}

class AuthApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }
}

export default AuthApiService;
