import { alpha, Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: 'transparent',
      color: theme.palette.neutral[800],
      '&& svg': {
        width: 16,
        height: 16,
      },

      '&:disabled': {
        '&& svg': {
          fill: alpha(theme.palette.neutral[800], 0.26),
        },
      },
    }),
    colorPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[800],
      borderRadius: '50%',
      width: 32,
      height: 32,

      '&& svg': {
        fill: theme.palette.neutral[100],
      },

      '&:hover': {
        backgroundColor: theme.palette.neutral[700],
      },
    }),
    colorSecondary: ({ theme }) => ({
      border: `1px solid ${theme.palette.neutral[800]}`,
      borderRadius: '50%',
      width: 32,
      height: 32,

      '&:hover': {
        backgroundColor: alpha(theme.palette.neutral[800], 0.04),
      },
    }),
  },
};
