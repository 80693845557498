import { styled } from '@mui/material';
import { Suspense, SVGProps } from 'react';

import IconLookup from 'components/@icons';

export type IconNames = keyof typeof IconLookup;

interface Props extends Omit<SVGProps<SVGSVGElement>, 'ref'> {
  name: IconNames;
}

const Icon = ({ name, ...rest }: Props) => {
  const SvgIcon = IconLookup[name];

  return <Suspense>{SvgIcon ? <SvgIcon {...rest} /> : null}</Suspense>;
};

export default styled(Icon)({});
