import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { useSentryRoutes } from 'services';

import { routes } from './routes';

const Pages = () => {
  const intl = useIntl();
  const element = useSentryRoutes(routes);

  return (
    <>
      <Helmet title={intl.formatMessage({ id: 'seo.title' })}>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'seo.description' })}
        />
      </Helmet>
      <Suspense fallback={null}>{element}</Suspense>
    </>
  );
};

export default Pages;
