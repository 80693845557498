export const timeslots = [
  { timeStart: '00:30', timeEnd: '01:20' },
  { timeStart: '01:20', timeEnd: '02:10' },
  { timeStart: '02:10', timeEnd: '03:00' },
  { timeStart: '03:00', timeEnd: '03:50' },
  { timeStart: '03:50', timeEnd: '04:40' },
  { timeStart: '04:40', timeEnd: '05:30' },
  { timeStart: '05:30', timeEnd: '06:20' },
  { timeStart: '06:20', timeEnd: '07:10' },
  { timeStart: '07:10', timeEnd: '08:00' },
  { timeStart: '08:00', timeEnd: '08:50' },
  { timeStart: '08:50', timeEnd: '09:40' },
  { timeStart: '09:40', timeEnd: '10:30' },
  { timeStart: '10:30', timeEnd: '11:20' },
  { timeStart: '11:20', timeEnd: '12:10' },
  { timeStart: '12:10', timeEnd: '13:00' },
  { timeStart: '13:00', timeEnd: '13:50' },
  { timeStart: '13:50', timeEnd: '14:40' },
  { timeStart: '14:40', timeEnd: '15:30' },
  { timeStart: '15:30', timeEnd: '16:20' },
  { timeStart: '16:20', timeEnd: '17:10' },
  { timeStart: '17:10', timeEnd: '18:00' },
  { timeStart: '18:00', timeEnd: '18:50' },
  { timeStart: '18:50', timeEnd: '19:40' },
  { timeStart: '19:40', timeEnd: '20:30' },
  { timeStart: '20:30', timeEnd: '21:20' },
  { timeStart: '21:20', timeEnd: '22:10' },
  { timeStart: '22:10', timeEnd: '23:00' },
  { timeStart: '23:00', timeEnd: '23:50' },
];
