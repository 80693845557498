import { alertClasses, Components, Theme } from '@mui/material';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 0,
      fontSize: 14,
      lineHeight: '23.8px',
      color: theme.palette.neutral[800],
      padding: '14px 16px 14px 18px',
      display: 'inline-flex',
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.yellow[200],
    }),
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.status.error[100],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.status.error[200],
      },
    }),
    standardWarning: ({ theme }) => ({
      backgroundColor: theme.palette.status.warning[100],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.status.warning[200],
      },
    }),
    standardSuccess: ({ theme }) => ({
      backgroundColor: theme.palette.status.success[100],
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.status.success[200],
      },
    }),
    message: {
      padding: 0,
    },
    icon: {
      padding: 0,
    },
  },
};
