import { BaseParams } from './jsonapi.types';

export enum Roles {
  TEACHER = 'fuma-0001-platform-teachers',
  ADMIN = 'fuma-0001-platform-admin',
  STUDENT = 'fuma-0001-platform-student',
  BESTELLER = 'fuma-0001-platform-besteller',
}

export enum UserStatus {
  ACTIVE = 'active',
  ARCHIVED = 'inactive',
}

export type User = {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  about?: string;
  active: boolean;
  archived: boolean;
  isAdmin: boolean;
  locale: string;
  language: string;
  timezone: string;
  createdDate: string;
  createdByUser?: User;
  updatedByUser?: User;
  company?: string;
  billing?: {
    vatNumber: string;
    number: string;
    street: string;
    city: string;
    country: string;
    zipcode: string;
    phoneNumber: string;
  };
};

// Or you could also pick here
export type CreateUserParams = Partial<{
  givenName: string;
  familyName: string;
  email: string;
  language: string;
  locale: string;
}>;

export type UpdateUserParams = Partial<{
  givenName: string;
  familyName: string;
  email: string;
  profilePicture: string;
  active: boolean;
  locale: string;
  company: string;
  language: string;
  timezone: string;
  billing?: Partial<{
    vatNumber: string;
    number: string;
    street: string;
    city: string;
    country: string;
    zipcode: string;
    phoneNumber: string;
  }>;
}>;

export type GetUsersParams = BaseParams<{ status?: string; role?: string }>;

export type GetUsersMeta = {
  count: number;
};
