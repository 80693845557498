import {
  Alert,
  Avatar,
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatBytes } from 'utils/file.utils';

import { Icon } from '../Icon';
import { Image, ImageWrap } from './ImageUpload.style';

interface Props {
  image?: File | string;
  onChange(file: File | undefined): void;
  maxFileSize?: number;
}

const allowedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/svg+xml',
  'image/webp',
];

const ImageUpload: React.FC<Props> = ({ image, onChange, maxFileSize }) => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setError(undefined);
      const target = event.target;
      if (target) {
        const file = target.files?.[0] || null;

        const isAllowedFileType = file && allowedFileTypes.includes(file.type);

        if (!isAllowedFileType) {
          setError(formatMessage({ id: 'input.upload.type_not_allowed' }));
        }

        if (file && allowedFileTypes.includes(file.type)) {
          const fileSize = formatBytes(file.size, true);

          if (maxFileSize && fileSize > maxFileSize) {
            setError(
              formatMessage(
                { id: 'input.upload.max_file_size' },
                {
                  fileSize: maxFileSize,
                },
              ),
            );
            return;
          }

          onChange(file);
        }
      }
    },
    [formatMessage, maxFileSize, onChange],
  );

  const handleUploadImage = () => inputRef.current?.click();

  const getPreview = () => {
    if (image) {
      if (typeof image !== 'string') {
        return window.URL.createObjectURL(image);
      }

      return `https://${image}`;
    }
  };

  const isUrl = typeof image === 'string';

  const handleRemoveImage = () => onChange(undefined);

  return (
    <Box>
      {error && (
        <Alert sx={{ mb: 4 }} severity="error">
          {error}
        </Alert>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="neutral.200"
        gap={4}
        py={7}
        px={4}
      >
        {getPreview() ? (
          <Stack alignItems="center" gap={4}>
            <Box>
              <ImageWrap>
                <Image src={getPreview()} />
              </ImageWrap>
            </Box>
            {!isUrl && (
              <Box>
                <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                  {image?.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {formatBytes(image?.size)}
                </Typography>
              </Box>
            )}
          </Stack>
        ) : (
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="input.upload.no_picture" />
          </Typography>
        )}
        {image ? (
          <>
            <Button
              size="small"
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
              onClick={handleRemoveImage}
              endIcon={
                <Avatar variant="outlined">
                  <Icon name="IcoTrash" />
                </Avatar>
              }
            >
              <FormattedMessage id="label.remove" />
            </Button>
            <IconButton
              onClick={handleRemoveImage}
              sx={{ display: { lg: 'none' } }}
            >
              <Avatar variant="outlined">
                <Icon name="IcoTrash" />
              </Avatar>
            </IconButton>
          </>
        ) : (
          <>
            <input
              ref={inputRef}
              accept="image/*"
              type="file"
              onChange={handleImageChange}
              hidden
            />
            <Button
              size="small"
              sx={(theme) => ({
                fontSize: { xs: 12, lg: theme.typography.body1.fontSize },
              })}
              variant="outlined"
              onClick={handleUploadImage}
            >
              <FormattedMessage id="input.upload.choose_picture" />
            </Button>
          </>
        )}
      </Stack>
      {maxFileSize && (
        <FormHelperText sx={{ ml: 4, mt: 3 }}>
          <FormattedMessage
            id="input.upload.max_file_size"
            values={{ fileSize: maxFileSize }}
          />
        </FormHelperText>
      )}
    </Box>
  );
};

export default ImageUpload;
