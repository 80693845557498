import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { Teacher } from 'types/teacher.types';
import { Timesheet, TimesheetPdf } from 'types/timesheet.types';

const getTimesheetPdf = async (
  teacherId: Teacher['id'],
  timesheetId: Timesheet['id'],
) => {
  const { data } = await apiService.getTimesheetPdf(teacherId, timesheetId);

  return deserialize(data);
};

export const useTimesheetPdf = (
  teacherId: Teacher['id'],
  timesheetId: Timesheet['id'],
  config?: UseQueryOptions<DeserializedResponse<TimesheetPdf>>,
) => {
  const { data, isLoading, isFetching } = useQuery(
    QueryKeys.timesheets.pdf(teacherId, timesheetId),
    () => getTimesheetPdf(teacherId, timesheetId),
    config,
  );

  return {
    data: data?.data,
    meta: data?.meta,
    isLoading,
    isFetching,
  };
};
