import { addHours, addMonths, isAfter, isBefore, parse } from 'date-fns';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';

import { LessonTimeslot } from 'types/internal.types';

export const parseTimeslot = (start: string, end: string) => {
  const startWithoutTz = start.split(/([+-])/)[0];
  const endWithoutTz = end.split(/([+-])/)[0];

  return `${startWithoutTz} - ${endWithoutTz}`;
};

export const parseSimpleTimeslot = (
  start: string,
  end: string,
  timeZone?: string,
) => {
  if (!!start.length && !!end.length) {
    const timeStart = utcToZonedTime(
      toDate(start),
      timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
    const timeEnd = utcToZonedTime(
      toDate(end),
      timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    const startWithoutTz = format(timeStart, 'HH:mm', { timeZone });
    const endWithoutTz = format(timeEnd, 'HH:mm', { timeZone });

    return `${startWithoutTz} - ${endWithoutTz}`;
  }

  return '-';
};

export const sortTimeslots = (timeslots: LessonTimeslot[]) =>
  // make a copy of the array because it is immutable
  [...timeslots].sort((a, b) => {
    const aStart = new Date(a.timeStart);
    const bStart = new Date(b.timeStart);

    return aStart.getTime() - bStart.getTime();
  });

export const isTimeslotDisabled = (day: string, timeslot: string) => {
  const today = new Date();
  const parsedDay = parse(day, 'yyyy-MM-dd', today);
  const parsedTimeslot = parse(timeslot, 'HH:mm', parsedDay);

  const isDaysBefore = isBefore(parsedTimeslot, addHours(today, 24));
  const isThreeMonthsAfter = isAfter(parsedTimeslot, addMonths(today, 3));

  return isDaysBefore || isThreeMonthsAfter;
};
