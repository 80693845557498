import {
  alpha,
  chipClasses,
  FormControlLabel,
  formControlLabelClasses,
  FormControlLabelProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  rowWidth?: string | number;
  row?: boolean;
}

export const FormControlLabelStyled = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />,
  { shouldForwardProp: (prop) => prop !== 'rowWidth' && prop !== 'row' },
)(({ theme, checked, row, rowWidth }) => ({
  backgroundColor: checked
    ? theme.palette.yellow[200]
    : theme.palette.neutral[100],
  border: '1px solid',
  borderColor: checked ? 'transparent' : theme.palette.neutral[400],
  outlineOffset: -1,
  margin: row ? undefined : theme.spacing(0, 0, 4),
  height: 56,

  ...(row && {
    marginLeft: 0,
    marginRight: 0,
    width: `calc(${rowWidth} - 8px)`,
    ':first-of-type': {
      marginRight: theme.spacing(4),
    },

    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
      marginLeft: '0 !important',

      ':first-of-type': {
        marginRight: '0 !important',
        marginBottom: 8,
      },
    },
  }),

  '&:hover': {
    borderColor: theme.palette.neutral[800],
  },

  [`&.${formControlLabelClasses.disabled}`]: {
    color: alpha('#000000', 0.26),
    backgroundColor: `${alpha(
      checked ? theme.palette.yellow[200] : theme.palette.neutral[100],
      0.4,
    )} !important`,

    [`& .${chipClasses.root}`]: {
      opacity: 0.26,
    },
  },
}));
