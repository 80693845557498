import { JsonApiResponse } from 'deserialize-json-api';

import { getTimezoneHeader } from 'utils/headers.utils';

import {
  DefaultAvailabilities,
  GetAvailabilitiesByDayParams,
  GetAvailabilitiesByMonthParams,
  GetAvailabilitiesByMonthResponse,
  GetAvailabilitiesByWeekParams,
  GetAvailabilitiesResponse,
  SpecificAvailabilities,
  UpdateDefaultAvailabilitiesParams,
  UpdateSpecificAvailabilitiesParams,
} from 'types/availabilities.types';
import { Resources } from 'types/jsonapi.types';
import { Teacher } from 'types/teacher.types';

import RestApiService from './';

export default {
  getDefaultAvailabilities(this: RestApiService, id: Teacher['id']) {
    return this.api.get<JsonApiResponse<DefaultAvailabilities>>(
      `/teachers/${id}/default_availabilities`,
    );
  },
  updateDefaultAvailabilities(
    this: RestApiService,
    id: Teacher['id'],
    data: UpdateDefaultAvailabilitiesParams,
  ) {
    return this.api.put(`/teachers/${id}/default_availabilities`, {
      data: { type: Resources.DEFAULT_AVAILABILITIES, attributes: data },
    });
  },
  getAvailabilitiesByWeek(
    this: RestApiService,
    id: Teacher['id'],
    params: GetAvailabilitiesByWeekParams,
  ) {
    return this.api.get<JsonApiResponse<SpecificAvailabilities>>(
      `/teachers/${id}/availabilities/week`,
      {
        params,
      },
    );
  },
  getDayAvailabilitiesBySkill(
    this: RestApiService,
    skill: string,
    { ...params }: GetAvailabilitiesByDayParams,
  ) {
    return this.api.get<{ data: GetAvailabilitiesResponse }>(
      `/teachers/skills/${skill}/availabilities/day`,
      {
        params,
        headers: getTimezoneHeader(),
      },
    );
  },
  getMonthAvailabilitiesBySkill(
    this: RestApiService,
    skill: string,
    params: GetAvailabilitiesByMonthParams,
  ) {
    return this.api.get<GetAvailabilitiesByMonthResponse>(
      `/teachers/skills/${skill}/availabilities/month`,
      {
        params,
        headers: getTimezoneHeader(),
      },
    );
  },
  getTeacherAvailabilitiesByDay(
    this: RestApiService,
    id: string,
    params: GetAvailabilitiesByDayParams,
  ) {
    return this.api.get<{ data: GetAvailabilitiesResponse }>(
      `/teachers/${id}/availabilities/day`,
      {
        params,
        headers: getTimezoneHeader(),
      },
    );
  },
  getTeacherAvailabilitiesByMonth(
    this: RestApiService,
    id: string,
    params: GetAvailabilitiesByMonthParams,
  ) {
    return this.api.get<GetAvailabilitiesByMonthResponse>(
      `/teachers/${id}/availabilities/month`,
      {
        params,
        headers: getTimezoneHeader(),
      },
    );
  },
  updateAvailabilitiesByWeek(
    this: RestApiService,
    id: Teacher['id'],
    data: UpdateSpecificAvailabilitiesParams,
  ) {
    return this.api.put(`/teachers/${id}/availabilities/week`, {
      data: { type: Resources.AVAILABILITIES, attributes: data },
    });
  },
};
