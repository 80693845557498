import { capitalize } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Locale, setDay, setMonth } from 'date-fns';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { getLocale, useAppSelector } from 'store';

const DatepickerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = useAppSelector(getLocale);
  const intl = useIntl();
  const adapterLocale: Locale = {
    code: locale,
    options: {
      weekStartsOn: 1,
    },
    localize: {
      day: (args) => {
        const day = setDay(new Date(), args);
        return intl.formatDate(day, {
          weekday: 'narrow',
        });
      },
      month: (args) => {
        const month = setMonth(new Date(), args);
        return capitalize(
          intl.formatDate(month, {
            month: 'long',
          }),
        );
      },
      // just returning these because type of Locale requires these properties
      dayPeriod: (args) => args,
      ordinalNumber: (args) => args,
      era: (args) => args,
      quarter: (args) => args,
    },
    formatLong: {
      date: (args) => args,
      time: (args) => args,
      dateTime: (args) => args,
    },
  };

  return (
    <LocalizationProvider
      adapterLocale={adapterLocale}
      dateAdapter={AdapterDateFns}
    >
      {children}
    </LocalizationProvider>
  );
};

export default DatepickerProvider;
