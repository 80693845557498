import {
  Radio,
  RadioGroup as MuiRadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { FormControlLabelStyled } from './RadioGroup.styled';

type Option = {
  label: string;
  value: string | number;
  indicator?: React.ReactNode;
};
interface Props {
  options: Option[];
  onChange(value: string): void;
  value: string | number;
  row?: boolean;
  rowWidth?: string | number;
  disabled?: boolean;
  className?: string;
  indicator?: React.ReactNode;
}

const RadioLabel: React.FC<Omit<Option, 'value'>> = ({ label, indicator }) => {
  if (indicator)
    return (
      <Stack width="100%" pr={4} justifyContent="space-between">
        <Typography>{label}</Typography>
        {indicator}
      </Stack>
    );

  return <Typography>{label}</Typography>;
};

const RadioGroup: React.FC<Props> = ({
  options,
  onChange,
  value,
  disabled,
  row,
  rowWidth = '50%',
  className,
}) => {
  return (
    <MuiRadioGroup
      row={row}
      className={className}
      onChange={(_, v) => onChange(v)}
      defaultValue={value}
    >
      {options.map((option) => (
        <FormControlLabelStyled
          key={option.value}
          row={row}
          rowWidth={rowWidth}
          disabled={disabled}
          checked={option.value === value}
          value={option.value}
          control={<Radio />}
          disableTypography
          label={
            <RadioLabel label={option.label} indicator={option.indicator} />
          }
        />
      ))}
    </MuiRadioGroup>
  );
};

export default RadioGroup;
