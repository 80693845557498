import { Components, Theme } from '@mui/material';

export const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  defaultProps: {
    color: 'primary',
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.neutral[800],
        '& svg': {
          fill: theme.palette.neutral[100],
        },
      }),
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        background: 'none',
        border: `1px solid ${theme.palette.neutral[800]}`,
        color: theme.palette.neutral[800],
      }),
    },
  ],
  styleOverrides: {
    root: {
      width: 32,
      height: 32,
    },
  },
};
