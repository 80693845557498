import { Auth } from '@aws-amplify/auth';
import { AuthErrorMessage } from '@aws-amplify/auth/lib-esm/types';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { authConfig } from 'services/amplify';
import { useAppDispatch } from 'store/hooks';
import { userActions } from 'store/user/user.slice';
import { CognitoUser } from 'types/amplify.types';

import { Routes } from 'pages/routes.constants';

type Variables = {
  username: string;
  password: string;
  remember: boolean;
};

const login = async ({ username, password, remember }: Variables) => {
  if (!remember) {
    Auth.configure({
      Auth: {
        ...authConfig,
        cookieStorage: {
          ...authConfig.cookieStorage,
          expires: 1,
        },
      },
    });
  }

  const data: CognitoUser = await Auth.signIn(username, password);

  return data;
};

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mutate, isLoading, error } = useMutation<
    CognitoUser,
    AuthErrorMessage,
    Variables,
    unknown
  >(login, {
    onSuccess: (data) => {
      if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(userActions.SET_USER(data));
        navigate(Routes.NewPassword);
      }
    },
  });

  return { login: mutate, isLoading, error };
};
