import { Components, inputBaseClasses, Theme } from '@mui/material';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${inputBaseClasses.error}`]: {
        border: `1px solid ${theme.palette.status.error[200]}`,
      },
    }),
  },
};
