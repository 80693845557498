// TODO: we need to disable this utility when we are running in production
// A check with a flag?
// if (isDev())?
export function testId(name: string, uniqueIdentifier?: string) {
  if (
    uniqueIdentifier !== null &&
    uniqueIdentifier !== undefined &&
    uniqueIdentifier !== ''
  ) {
    return {
      'data-testid': `${name}-${uniqueIdentifier}`,
    };
  }

  return {
    'data-testid': `${name}`,
  };
}
