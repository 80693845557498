import { ButtonGroup } from '@mui/material';
import React from 'react';

import { Icon } from 'components/@common';

import { ButtonStyled } from './ArrowButton.style';

interface Props {
  onClick(event: React.MouseEvent<HTMLElement>): void;
  onPrevious(): void;
  onNext(): void;
  disabled?: boolean;
  children: React.ReactNode;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  fullWidth?: boolean;
}

const ArrowButton: React.FC<Props> = ({
  onClick,
  onPrevious,
  disabled,
  onNext,
  children,
  endIcon,
  startIcon,
  fullWidth,
}) => {
  return (
    <ButtonGroup fullWidth={fullWidth} disabled={disabled}>
      <ButtonStyled sx={{ width: 50 }} onClick={onPrevious}>
        <Icon name="IcoArrowLeft" fontSize={16} />
      </ButtonStyled>
      <ButtonStyled
        disableRipple
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
      >
        {children}
      </ButtonStyled>
      <ButtonStyled sx={{ width: 50 }} onClick={onNext}>
        <Icon name="IcoArrowRight" fontSize={16} />
      </ButtonStyled>
    </ButtonGroup>
  );
};

export default ArrowButton;
