import { Theme } from '@mui/material';

export const MuiMonthPicker = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      width: '100%',
      paddingBottom: '26px',
      margin: 0,

      '& .PrivatePickersMonth-root': {
        lineHeight: 'unset',
        '&.Mui-selected': {
          backgroundColor: theme.palette.neutral[800],
          borderRadius: 30,
          color: theme.palette.neutral[100],

          '&:hover': {
            backgroundColor: theme.palette.neutral[800],
          },
        },
        '&:disabled': {
          color: theme.palette.neutral[500],
        },
      },
    }),
  },
};
