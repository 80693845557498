import { Components, Theme } from '@mui/material';

export const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    underline: 'always',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral[800],
      textDecorationColor: theme.palette.neutral[800],
      textUnderlineOffset: 3,
      lineHeight: '175%',

      '&:hover': {
        color: theme.palette.neutral[700],
      },
    }),
  },
};
