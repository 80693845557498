import { Theme } from '@mui/material';
import { pickersDayClasses } from '@mui/x-date-pickers';

export const MuiPickersDay = {
  defaultProps: {
    disableHighlightToday: true,
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      width: 36,
      height: 36,
      margin: '0 8px',
      fontSize: 14,
      [`&.${pickersDayClasses.selected}`]: {
        backgroundColor: theme.palette.neutral[800],
        transition: 'none',

        '&:focus': {
          backgroundColor: theme.palette.neutral[800],
        },

        '&:disabled': {
          color: theme.palette.neutral[100],
        },

        '&:hover': {
          backgroundColor: theme.palette.neutral[800],
        },
      },

      '&:hover': {
        backgroundColor: theme.palette.neutral[300],
      },
    }),
  },
};
