import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { deserialize, DeserializedArrayResponse } from 'deserialize-json-api';

import { QueryKeys } from 'queries/QueryKeys';
import { getListData } from 'queries/utils';
import { apiService } from 'services';
import { GetAllTimesheetsParams, Timesheet } from 'types/timesheet.types';

const getAllTimesheets = async (params: GetAllTimesheetsParams) => {
  const { data } = await apiService.getAllTimesheets(params);

  return deserialize<Timesheet>(data);
};

export const useAllTimesheets = (
  params: GetAllTimesheetsParams,
  config?: UseQueryOptions<DeserializedArrayResponse<Timesheet>>,
) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    QueryKeys.timesheets.all(params),
    () => getAllTimesheets(params),
    { keepPreviousData: true, ...config },
  );

  return {
    ...getListData(data),
    isLoading,
    isFetching,
    isError,
  };
};
