import { useMutation } from '@tanstack/react-query';
import { deserialize } from 'deserialize-json-api';
import { useIntl } from 'react-intl';

import { useNotification } from 'hooks';
import { apiService } from 'services';
import { MatchLessonParams } from 'types/internal.types';

const matchTeacher = async (variables: MatchLessonParams) => {
  const { data } = await apiService.matchTeacher(variables);

  return deserialize(data);
};

export const useMatchTeacher = () => {
  const intl = useIntl();
  const { showNotification } = useNotification();
  const { data, mutate, isLoading, error } = useMutation(matchTeacher, {
    onError: () => {
      showNotification({
        message: intl.formatMessage({
          id: 'notifications.error.something_went_wrong',
        }),

        variant: 'error',
      });
    },
  });

  return {
    data: data?.data,
    meta: {
      lessons: data?.meta?.lessons ?? [],
      conflicts: data?.meta?.conflicts ?? [],
    },
    matchTeacher: mutate,
    isLoading,
    error,
  };
};
