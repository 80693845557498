import { useState } from 'react';

import { useDebouncedState } from './useDebouncedState';
import { usePagination } from './usePagination';

type Options = {
  value?: string;
  debounce?: number;
  currentPage?: number;
};

const defaultOptions: Options = { debounce: 300, value: '' };

export const useSearchState = ({
  value,
  debounce,
}: Options = defaultOptions) => {
  const [searchValue, setSearchValue] = useState<string>(value ?? '');
  const debouncedSearchValue = useDebouncedState(searchValue, debounce);
  const { handleResetPage } = usePagination();

  const handleSearchChange = (value: string) => {
    handleResetPage();
    setSearchValue(value);
  };

  const handleClearSearch = () => setSearchValue('');

  return {
    searchValue,
    debouncedSearchValue: debouncedSearchValue.length
      ? debouncedSearchValue
      : undefined,
    handleSearchChange,
    handleClearSearch,
  };
};
