// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import languages from '@cospired/i18n-iso-languages';
import countries from 'i18n-iso-countries';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import config from 'config';

import MESSAGES_EN from './translations/en.json';
import MESSAGES_FR from './translations/fr.json';
import MESSAGES_NL from './translations/nl.json';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

languages.registerLocale(require('@cospired/i18n-iso-languages/langs/en.json'));
languages.registerLocale(require('@cospired/i18n-iso-languages/langs/fr.json'));
languages.registerLocale(require('@cospired/i18n-iso-languages/langs/nl.json'));

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ['nl', 'fr', 'en'];

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  nl: createIntl({ locale: 'nl', messages: MESSAGES_NL }, intlCache),
  en: createIntl({ locale: 'en', messages: MESSAGES_EN }, intlCache),
  fr: createIntl({ locale: 'fr', messages: MESSAGES_FR }, intlCache),
};

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, intl, locales };
