import { Typography, TypographyProps } from '@mui/material';

import { Line } from './UnderlinedText.style';

interface Props extends TypographyProps {
  children: React.ReactNode;
}

const UnderlinedText = ({ children, ...rest }: Props) => {
  return (
    <Typography
      position="relative"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      {children}
      <Line width="100%" name="ImgScribbleLine" />
    </Typography>
  );
};

export default UnderlinedText;
